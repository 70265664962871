import gql from 'graphql-tag';
export default gql`
query getUserlistByCreatedAt($siteId: String! $nextToken: String $limit: Int){
  getUserlistByCreatedAt(siteId: $siteId nextToken: $nextToken limit: $limit){
    items{
    id
    siteId
    uid
    name
    mob
    email
    customerSince
    whatsAppNumber
    city
    country
    language
    customerType
    ProductType
    sendingStatus
    enabled
    url
    BusinessName
    tempData{
      templateName
      templateId
      sentAt
    }
  }
  nextToken
}
}`