import gql from 'graphql-tag';
export default gql`
mutation createTemplete(
  $siteId: String!
	$uid: String!
	$displayName: String
	$subject: String
	$image: S3ObjectInput
	$content: String
  )
  {
    createTemplete(input: {
      siteId: $siteId
      uid: $uid
      displayName: $displayName
      subject: $subject
      image: $image
      content: $content
    }){
      id
    siteId
    displayName
    subject
    }
  }
  # {
  #   "partionKey": "email",
  #   "templeteName": "offer",
  #   "subject": "padva offer",
  #   "content": "happy padva"
  # } ` 