import gql from 'graphql-tag';
export default gql`
query getTempUserlist($siteId: String!){
  getTempUserlist(siteId: $siteId){
    sortType
    siteId
    mob
    name
  }
}`
// {
//     "siteId": "8668378093"
//   }