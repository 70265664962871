import gql from 'graphql-tag';
export default gql`
mutation createUserlist(
  $siteId: String!
 $name: String
 $mob: String
 $email: String
 $customerSince: String
 $whatsAppNumber: String
 $city: String
 $country: String
 $language: String
 $customerType: String
 $ProductType: String
 $sendingStatus: STATUS
 $enabled: Boolean
 $url: String
 $BusinessName: String
){
 createUserlist(input:{
   siteId: $siteId
   name: $name
   mob: $mob
   email: $email
   customerSince: $customerSince
   whatsAppNumber: $whatsAppNumber
   city: $city
   country: $country
   language: $language
   customerType: $customerType
   ProductType: $ProductType
   sendingStatus: $sendingStatus
   enabled: $enabled
   url: $url
   BusinessName: $BusinessName
 }){
   id
   siteId
   name
   mob
   email
   customerSince
   whatsAppNumber
   city
   country
   language
   customerType
   ProductType
   createdAt
   sendingStatus
   enabled
   url
   BusinessName
 }
}
# {
#   "siteId": "0001",
#   "name": "ajay",
#   "mob": "7350099005",
#   "email": "ajaybaglane7@gmail.com",
#   "customerSince": "12/03/2020",
#   "whatsAppNumber": "7350099005",
#   "city": "beed",
#   "country": "ind",
#   "language": "marathi",
#   "customerType": "selles",
#   "customerType": "it",
#   "ProductType": "comp" 
# }`