import gql from 'graphql-tag';
export default gql`
query getWhatsAppSendingStatus($siteId: String!){
  getWhatsAppSendingStatus(siteId : $siteId){
    sortType
    siteId
    status
  }
}`
//  {
//    "siteId": "51df410a-ae65-4676-abfc-4d878b0cbd97"
//  }