import React, { Component } from 'react'
import Wrapper from '../Wrapper'
import { Link } from 'react-router-dom';
import { graphql, compose, withApollo, Query } from 'react-apollo';
import { Button, Row, Col, Form, Input, message,Select,DatePicker,Breadcrumb } from 'antd'
import createUserlist from '../mutation/createUserlist'
import moment from 'moment'

const FormItem = Form.Item
const Option = Select.Option;
function callback(key) {
    console.log(key);
}

const company = [
    { value: 'PROPRIETOR', label: 'PROPRIETOR' },
    { value: 'PARTNERSHIP', label: 'PARTNERSHIP' },
    { value: 'COMPANY', label: 'COMPANY' },
    { value: 'HUF', label: 'HUF' },
    { value:'GOVERNMENT_ENTITY', label:'GOVERNMENT ENTITY' },
]

const status = [
    { value: true, label: "ACTIVE" },
    { value: false, label: 'INACTIVE' },
  
]

class createCustomer extends Component {
    constructor(props){
        super(props)
        this.state={
            date:'',
            status: true,
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let date = moment(values.Customer).format('DD/MM/YYYY')
                // console.log("val", values,date);
            
                // return
                try {
                    var sortType = localStorage.getItem('siteId')       
                            this.props.client.mutate({
                                mutation: createUserlist,
                                variables: {
                                    siteId: sortType,
                                    name: values.name,
                                    mob: values.mob,
                                    email: values.email,
                                    customerSince: date,
                                    whatsAppNumber: values.whatsappNumber,
                                    city: values.City,
                                    country: values.Country,
                                    language: values.Language,
                                    customerType: values.customerType,
                                    ProductType: values.productType,
                                    sendingStatus: "NEW",
                                    enabled: values.status,
                                    url: values.url,
                                    BusinessName: values.BusinessName,
                                }
                            }).then(({ data }) => {
                                // console.log("CreateUserlist",data)
                                
                                if(data.createUserlist){
                                    message.success(<span style={{ fontSize: '20px' }}>User Created Successfully</span>);
                                    this.props.history.push('/')
                                }
                                // if (a.length == this.state.dataSource.length) {
                                //     // console.log("arr", a, a.length, this.state.dataSource, this.state.dataSource.length)
                                //     message.success(<span style={{ fontSize: '20px' }}>User Created Successfully</span>);
                                //     this.setState({
                                //         longLoading: false
                                //     })
                                // }
                                // if (data.createUserlist != null) {
                                    
                                //     this.setState({
                                //         loading: false,
                                //         // longLoading: false
                                //     })
                                // }
                            })
                                .catch(err => {
                                    console.log(err)
                                    this.setState({
                                        loading: false,
                                        longLoading: false
                                    })
                                });
                        }
                        catch (err) {
                            console.log(err)
                            this.setState({
                                loading: false,
                                longLoading: false
                            })
                        }

            }
            else {

            }
        })
    }

    onChange=(date, dateString)=>{
        let a=Math.floor(new Date(dateString) / 1000);
        console.log("a,a",a)
        this.setState({
            date:a
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Wrapper header={"Create Customer"}>

            <div>
            <Row>
                        <Col xs={{ span: 64 }} sm={{ span: 24 }} md={{ span: 23 }} lg={{ span: 23 }} xl={{ span: 21 }}>
                            <Breadcrumb style={{ marginBottom: "20px" }}>
                                <Link to="/">
                                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                                </Link>
                                <Link to="/customer">
                                    <Breadcrumb.Item>User List</Breadcrumb.Item>
                                </Link>
                                <Breadcrumb.Item>Create Customer</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>

                <Form onSubmit={this.handleSubmit}>
                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="Name">
                                {getFieldDecorator(`name`, {
                                    rules: [{
                                        required: true,
                                        message: "Please enter Name",
                                    },
                                    ]
                                })(
                                    <Input placeholder="Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="Business Name"
                            >
                                {getFieldDecorator(`BusinessName`, {
                                    rules: [{
                                        // type: 'email',
                                        required: true,
                                        message: "Please enter Business Name",
                                    },

                                    ]
                                })(
                                    <Input placeholder="Email" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                       

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="Mobile Number"
                            >
                                {getFieldDecorator(`mob`, {
                                    rules: [{
                                        required: true,
                                        message: "Please enter Mobile Number",
                                    },
                                    {
                                        validator: (rule, value, cb) => {
                                            if (value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 10)) {
                                                cb('Please enter 10 digit number only')
                                            }
                                            cb()
                                        }
                                    }
                                    ]
                                })(
                                    <Input placeholder="Mobile Number" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                    </Row>

                   
                    <Row gutter={16}>
                     <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="WhatsApp Number"
                            >
                                {getFieldDecorator(`whatsappNumber`, {
                                    rules: [{
                                        required: true,
                                        message: "Please enter WhatsApp Number",
                                    },
                                    {
                                        validator: (rule, value, cb) => {
                                            if (value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 10)) {
                                                cb('Please enter 10 digit number only')
                                            }
                                            cb()
                                        }
                                    }
                                    ]
                                })(
                                    <Input placeholder="WhatsApp Number" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="Email"
                            >
                                {getFieldDecorator(`email`, {
                                    rules: [{
                                        type: 'email',
                                        required: false,
                                        message: "Please enter E-mail!",
                                    },

                                    ]
                                })(
                                    <Input placeholder="Email" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                       
                       
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="Customer since"
                            >
                                {getFieldDecorator(`Customer`, {
                                    rules: [{
                                        required: false,
                                        message: "Please enter Customer Since",
                                    },

                                    ]
                                })(
                                    // <Input  style={{ width: '100%' }} />
                                    <DatePicker placeholder="Customer Since"  onChange={this.onChange} />
                                )}
                            </FormItem>
                        </Col>

                       
                    </Row>

                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="City">
                                {getFieldDecorator(`City`, {
                                    rules: [{
                                        required: false,
                                        message: "Please enter City",
                                    },
                                    ]
                                })(
                                    <Input placeholder="City" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="Country"
                            >
                                {getFieldDecorator(`Country`, {
                                    rules: [{
                                        required: false,
                                        message: "Please enter Country",
                                    },

                                    ]
                                })(
                                    <Input placeholder="Country" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                         <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="Language">
                                {getFieldDecorator(`Language`, {
                                    rules: [{
                                        required: false,
                                        message: "Please enter Language",
                                    },
                                    ]
                                })(
                                    <Input placeholder="Language" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        </Row>

                        <Row gutter={16}>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="Customer Type">
                                {getFieldDecorator(`customerType`, {
                                    rules: [{
                                        required: false,
                                        message: "Please enter Customer Type",
                                    },
                                    ]
                                })(
                                    <Input placeholder="Customer Type" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>

                        {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="Customer Type">
                                {getFieldDecorator(`CustomerType`, {
                                    rules: [{
                                        required: true,
                                        message: "Please enter Customer Type",
                                    },
                                    ]
                                })(
                                    <Select placeholder="company type">
                                            {company.map((c) => {
                                                return (
                                                    <Option key={c.value} value={c.value}>{c.label}</Option>
                                                )
                                            })}
                                        </Select>
                                )}
                            </FormItem>
                        </Col> */}

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="Product Type">
                                {getFieldDecorator(`productType`, {
                                    rules: [{
                                        required: false,
                                        message: "Please enter Product Type",
                                    },
                                    ]
                                })(
                                    <Input placeholder="Product Type" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="Product Type"
                            >
                                {getFieldDecorator(`productType`, {
                                    rules: [{
                                        required: true,
                                        message: "Please enter Product Type",
                                    },
                                    ]
                                })(
                                    <Select placeholder="company type">
                                            {company.map((c) => {
                                                return (
                                                    <Option key={c.value} value={c.value}>{c.label}</Option>
                                                )
                                            })}
                                        </Select>
                                )}
                            </FormItem>
                        </Col> */}

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="url">
                                {getFieldDecorator(`url`, {
                                    rules: [{
                                        required: false,
                                        message: "Please enter url",
                                    },
                                    ]
                                })(
                                    <Input placeholder="url" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="Status"
                            >
                                {getFieldDecorator(`status`, 
                                {
                                    initialValue: true,
                                    rules: [{
                                        required: true,
                                        message: "Please select Status",
                                    },
                                    ]
                                })(
                                    <Select placeholder="status">
                                            {status.map((c) => {
                                                return (
                                                    <Option key={c.value} value={c.value}>{c.label}</Option>
                                                )
                                            })}
                                        </Select>
                                )}
                            </FormItem>
                        </Col>
                        
                    </Row>

                    <Row gutter={16}>
                       
                        
                    </Row>

                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem>
                              
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>

                                 <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%",marginLeft:"10px" }} 
                                 onClick={()=>{this.props.history.push('/')}}
                                 > 
                                 Cancel 
                                 </Button>

                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </div>
            </Wrapper>
        )
    }
}
const WrappedTimeRelatedForm = Form.create({ name: 'time_related_controls' })(createCustomer);

export default withApollo(WrappedTimeRelatedForm);
