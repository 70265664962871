import gql from 'graphql-tag';
export default gql`
mutation sendWhatsAppCampaignToUser($input: [WhatsAppCampaignInput!]){
  sendWhatsAppCampaignToUser(input: $input)
}
`
// {
//        "input": [{
//          "mob": "9970704731",
//          "name": "Datta",
//          "text": "sdv"
//        },{
//          "mob": "9665938860",
//          "name": "utgc",
//          "text": "ngc"
//        }]
// }
    
    