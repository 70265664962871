import gql from 'graphql-tag';
export default gql`
query getTempleteByCreatedAt($siteId: String!){
  getTempleteByCreatedAt(siteId: $siteId){
      items{
        id
        siteId
        uid
        displayName
        subject
        content
        createdAt
        image{
          bucket
          region
          key
        }
      }
      nextToken
    }
  }`
  