import React, { Component } from 'react';
import {Button, Col, Form, Input, Row, Select,Upload, message,Icon} from 'antd'
import { Query, graphql, compose, withApollo } from 'react-apollo';
import FroalaEditor from './froalaEditor'
import Wrapper from '../Wrapper'
import UploadList from './uploadFile'
import sendEmailCampaignToUser from '../mutation/sendEmailCampaignToUser'
import getTempleteByCreatedAt from '../query/getTempleteByCreatedAt'
import createTemplete from '../mutation/createTemplete'
import getWhatsAppTemplet from '../query/getWhatsAppTemplet'
import getUserlistByCreatedAt from '../query/getUserlistByCreatedAt'
import updateTemplete from '../mutation/updateTemplete'
import deleteTemplete from '../mutation/deleteTemplate'
import slugify from "slugify";
// import getFroalaSignature from '../query/getFroalaSignature'

const FormItem = Form.Item;
const { TextArea } = Input;
const bucket ='btoc-ecom-prod'
const s3_Region = 'ap-south-1'

class CustomerEmail extends Component{
    constructor(props){
        super(props)
        this.state={
            img1:[],
            removedimage1:false,
            img:{},
            removedimage:false,
            modelNew: '',
            showComp:false,
            subject:'',
            siteId: '',
            uid: '',
            id:'',
            createdAt: '',
            tempName: '',
            customerData:[],
            loading:false,
            enquiryData:[],
            tempInput:'',
            saveTemp:false,
            tempOptions:[],
            emailData:[],
            disabledInput:false,
            tempData1:[],
            error:false,
            keys:[],
            enqKey:[],
            check:false,
            selectVal:"",
            sdata:[],
            inputArr:[],
            key:null,
            dataSource:[],
            customerwhatsappEmail:'',
            saveButton:false,
            updateButoon:false,
            updateimg:false
        }
    }

   
    handleModelChange = modelNew => {
        this.setState({ modelNew: modelNew });
        // this.props.updateState(this.state.modelNew);
    };


    componentDidMount(){
      
        var sortType = localStorage.getItem('siteId')  
        // this.getTemplates()
        let data = this.props.location.state.data;

        if(data != null){
        // console.log("Props ...",data)
        
        data.length!=0 && data.map((i,j)=>{
            // if(val==i.value){
                // console.log("if",i)

                let img1 = i.image != null ? [{
                    key: i.image.key,
                    uid: i.image.key,
                    bucket: i.image.bucket,
                    region: i.image.region,
                    status: 'done',
                    url: `https://${i.image.bucket}.s3.${i.image.region}.amazonaws.com/${i.image.key}`
                }]:[]
                // console.log("img1",img1)
      

                this.setState({
                    img1:img1,
                    subject:i.subject,
                    modelNew:i.content,
                    id:i.id,
                    partionKey:i.partionKey,           
                    siteId: i.siteId,
                    uid: i.uid,
                    tempName: i.value,
                    createdAt: i.createdAt,
                    updateButoon:true,
                    saveButton:false,
                    updateimg:true
                })
            // }
            })
        }else{
            this.setState({
                subject:'',
                modelNew:'',
                id:'',
                partionKey:'',
                saveButton:true,
                updateButoon:false,
                updateimg:false,
                img1:[]

            })
        }
    }

    getTemplates=()=>{
         var sortType = localStorage.getItem('siteId')       
                this.props.client.query({
                    query: getTempleteByCreatedAt,
                    fetchPolicy: 'no-cache',
                    variables: { siteId: `wTemplte::${sortType}`
                    },
                })
                    .then(({ data }) => {
                    //   console.log(".......",data)
                      this.saveTemplateList(data.getTempleteByCreatedAt.items)
                    })
                    .catch(err => {
                        console.log(`Error ${JSON.stringify(err)}`,err)
                    })
    }

    saveTemplateList=(data)=>{
        let arr=[]
        if(data!=null){
            data.map((i,j)=>{
                let obj={
                    value:i.displayName,
                    label:i.displayName,
                    content:i.content,
                    subject:i.subject,
                    id:i.id,
                    siteId: i.siteId,
                    uid: i.uid,
                    // partionKey:i.partionKey,
                    image:i.image,
                    createdAt: i.createdAt
                }
                arr.push(obj)
            })
        }
        // console.log("data...........",arr)
        this.setState({
            tempData1:arr
        })

    }

    handleClick = ()=>{
        this.setState({showComp:true})
    }

    enterLoading = ()=>{
        this.setState({loading:true})
    }


    // handleEmail = () =>{
    //     console.log("in email",this.state.sdata)
    //     this.props.form.validateFields((err,values)=>{
          
    //         let userData= this.state.sdata
    //         // let enqData = this.state.enquiryData
    //             // return false
    //         var input=null
    //             var arrr=[]
    //         if(!err && userData.length != 0){
    //             for(let i=0 ;i<=userData.length-1; i++){
    //                 // this.enterLoading()
    //                 // this.props.client.mutate({
    //                 //     mutation: sendEmailCampaignToUser,
    //                 //     variables:{
    //                 //         email:userData[i].email,
    //                 //         subject:this.state.subject,
    //                 //         htmlText:`<p>Hello ${userData[i].fName},</p>`,
    //                 //     }
    //                 // }).then(({data})=>{
    //                 //     console.log("mail send",data)
    //                 //     // if(data.sendCampaignEmailToUser){
    //                 //     //     message.success("Mail has been sent successfully",this.message);
    //                 //     //     this.setState({loading:false})
    //                 //     // }
    //                 // }).catch(res=>{
    //                 //     console.log('Error',res)
    //                 // })
    //                 input={
    //                     mail:userData[i].Email,
    //                     subject:this.state.subject,
    //                     htmlText:`<p>Hello ${userData[i].Name},</p>${this.state.modelNew}`,
    //                 }
    //                 arrr.push(input);

    //             }
    //             // console.log("input",arrr)
    //             this.enterLoading()
    //                         this.props.client.mutate({
    //                             mutation: sendEmailCampaignToUser,
    //                             variables: {
    //                               input: arrr
    //                                 }
                               
    //                         }).then((data) => {
    //                             message.success('Mail has been sent successfully');
    //                             // console.log("success",data)
    //                             this.setState({
    //                                 loading:false
    //                             })
                               
    //                             })
                
    //                .catch(res=>{
    //                     console.log('Error',res)
    //                 })
    //         }else{
    //             console.log("else")
    //         }
    //     })
    // }
   

    handleCustomerData = (key,data)=>{
        this.setState({keys:key,sdata:data})
        // console.log("selectered data",data,key)
    }

    handleEnquiryData = (key,data) =>{
        this.setState({enqKey:key,enquiryData:data})
    }

    previous = () =>{
        this.setState({showComp:false})
    }

    handleChange = (e)=>{
        // console.log("e......",e.target.value)
        this.setState({subject:e.target.value})
    }

    handleSave = ()=>{
        this.setState({saveTemp:true})
    }

    handleSubmit = (e) => {
        let img1={}
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            // console.log("....",values);
            // return
            if (!err) {

                console.log('Received values of form: ', this.state.img.originFileObj);
                // return
                // this.enterLoading()
                console.log(Object.keys(this.state.img).length)
                let Img = []
                if (Object.keys(this.state.img).length != 0 ) {
                    let slugIname = slugify(this.state.img.originFileObj.name, {
                        replacement: '-',
                        lower: true,
                      });

                    img1 = {
                      bucket: bucket,
                      region: s3_Region,
                      localUri: this.state.img.originFileObj,
                      key: `comcenter/whatsApp/${slugIname}`,
                      mimeType: this.state.img.originFileObj.type
                    };

                    // Img.push(img1);

                //   console.log('img',img1,)
                  }
                  else{
                      img1 = undefined
                //   console.log('img......',img1,)

                  }
                  console.log('img......',img1)
                //   return
      

                try {
                    var sortType = `wTemplte::${localStorage.getItem('siteId')}`      
                    // var uid =  values.templateName
                    let uid = slugify(values.templateName, {
                        replacement: '-',
                        lower: true,
                      });
                    // console.log("SortType",sortType,uid);
                    // return
                    this.props.client.mutate({
                        mutation: createTemplete,
                        variables: {
                            // partionKey:sortType,
                            siteId: sortType,
                            uid: uid,
	                        displayName:values.templateName,
	                        subject:this.state.subject,
                            content:this.state.modelNew,
                            image: img1
                        }
                    }).then(({ data }) => {
                        console.log("rep ::",data.createTemplete)
                        if (data.createTemplete != null) {

                            message.success(<span style={{fontSize:'20px'}}>Templete Created Successfully</span>);
                            this.props.history.push('/whatsappTempList');
                            // this.props.Form.resetFields();
                            this.setState({
                                loading: false,
                            },()=>{this.getTemplates()})
                        }else{
                            message.error(<span style={{fontSize:'20px'}}>Templete Name Already Exists</span>);
                            this.setState({
                                loading: false,
                            })
                        }
                    })
                .catch(err => {
                console.log(err)
                message.error(<span style={{fontSize:'20px'}}>Templete Name Already Exists</span>);
                 this.setState({
                  loading: false,
                 })
            });
                }
                catch (err) {
                console.log(err)
                    this.setState({
                        loading: false,
                    })
                }
            }

            else{
                console.log(err)
            }
        })
    }


    

    // selectOption = (val)=>{
    //     // console.log("val.....",val)
       

    //     this.setState({
    //         saveTemplateB:val
    //     })

    //     if(val=='none'){
    //         this.setState({
    //             subject:'',
    //             modelNew:'',
    //             id:'',
    //             partionKey:'',
    //             saveButton:true,
    //             updateButoon:false,
    //             updateimg:false,
    //             img1:[]

    //         })
    //     }
    //     else{
    //         this.state.tempData1.length!=0 && this.state.tempData1.map((i,j)=>{
    //                     if(val==i.value){
    //                         // console.log("if",i)

    //                         let img1 = i.image != null ? [{
    //                             key: i.image.key,
    //                             uid: i.image.key,
    //                             bucket: i.image.bucket,
    //                             region: i.image.region,
    //                             status: 'done',
    //                             url: `https://${i.image.bucket}.s3.${i.image.region}.amazonaws.com/${i.image.key}`
    //                         }]:[]
    //                         // console.log("img1",img1)
                  

    //                         this.setState({
    //                             img1:img1,
    //                             subject:i.subject,
    //                             modelNew:i.content,
    //                             id:i.id,
    //                             partionKey:i.partionKey,           
    //                         siteId: i.siteId,
    //                         uid: i.uid,
    //                             createdAt: i.createdAt,
    //                             updateButoon:true,
    //                             saveButton:false,
    //                             updateimg:true
    //                         })
    //                     }
    //         })
    //     }
       
    // }
    contentChange=(e)=>{
        // console.log("e.target.value......",e.target.value)
        this.setState({
            modelNew:e.target.value
        })
    }

    handleRemoveImg=({file})=>{
        // console.log('remove')
        this.setState({
          img:{},
          removedimage:true,
          loading:false
        })
      }
    
      handleimage = ({ file }) => {
        this.setState((prevState, props) => {
            if(Object.keys(prevState.img).length === 0 && prevState.removedimage === false){
                return{
                    // image: file,
                    img:file,
                    removedimage: false
                }
            }
            else{
                return{
                    // image: {},
                    removedimage: false
                }
            }
        });
    }

    handleRemoveImg1=({fileList})=>{
        // console.log('remove')
        this.setState({
           img1:[],
          removedimage1:true
        })
      }

      handleimage1 = ({fileList }) => {

        // console.log("fileList:",fileList.length)
        if(fileList.length >0){

          this.setState({
            img1:fileList,
            removedimage1:false
          })
        }
        
    }


    enterLoading=()=>{
        this.setState({
            loading:true
        })
    }

    handleupdate=()=>{
        // console.log("In Update..",this.state.tempName)
        // return
        this.enterLoading()
        let img={}

        if(this.state.img1.length>0 && !this.state.img1[0].hasOwnProperty('key')){
            img = {
              bucket: bucket,
              region: s3_Region,
              localUri: this.state.img1[0].originFileObj,
              key: `comcenter/whatsApp/${this.state.img1[0].originFileObj.name}`,
              mimeType: "image/png"
            }
            // console.log('if',img)
           }
           else{
               if(this.state.img1.length!=0){
            img = {
              bucket: bucket,
              region: s3_Region,
              key:this.state.img1[0].key
            };
            // console.log('else',img)
        }
        else{
            img =null
            // console.log('else else',img)

        }

           }
        //    console.log("UpdateValues::",this.state.siteId,this.state.uid,this.state.saveTemplateB,this.state.createdAt,this.state.id,this.state.modelNew,img)
// return
        try {
            this.props.client.mutate({
                mutation: updateTemplete,
                variables: {
                    siteId: this.state.siteId,
                    uid: this.state.uid,
                    displayName: this.state.tempName,
                    createdAt: this.state.createdAt,
                    id:this.state.id,
                    // partionKey:this.state.partionKey,
                    templeteName:this.state.tempName,
                    subject:this.state.subject,
                    content:this.state.modelNew,
                    image:img
                }
            }).then(({ data }) => {
                // console.log(data)
                if (data.updateTemplete != null) {
                    message.success(<span style={{fontSize:'20px'}}>Template Updated Successfully </span>);
                    // this.props.form.resetFields();
                    this.props.history.push('/whatsappTempList');

                    this.setState({
                        loading: false,
                    },()=>{this.getTemplates()})
                }
            })
                .catch(err => {
                    console.log(err)
                    this.setState({
                        loading: false,
                    })
                });
        }
        catch (err) {
            console.log(err)
            this.setState({
                loading: false,
            })
        }
    }

    
    render(){
    //   console.log("props data......kkk",this.state.img1)
      const {img1}=this.state;
      const uploadButton = (
        <div>
          <Icon type={this.state.loading ? 'loading' : 'plus'} />
          <div className="ant-upload-text">Upload</div>

        </div>
      );

      const uploadButton1 = (
        <div>
          <Icon type={this.state.loading ? 'loading' : 'plus'} />
          <div className="ant-upload-text">Upload</div>

        </div>
      );

      var config = {
        key:process.env.froalaKey,
        height:240,
        imageUploadToS3:this.state.key,
        fileUploadToS3:this.state.key,
        toolbarButtons: {
            'moreText': {
              'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
            },
            'moreParagraph': {
              'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
            },
            'moreRich': {
              'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
            },
            'moreMisc': {
              'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help']
            }
          }
    };
    // var key = this.state.froal
        const { getFieldDecorator, getFieldValue } = this.props.form;
            return(
                <Form onSubmit={this.handleSubmit}>
                    <Wrapper header={"Whatsapp Template"}>
                    {/* <Row>
                        <Col span={8} >
                            <FormItem label="Template Name">
                            <Select 
                            placeholder="Select Template"
                            style={{ width: '70%' }}
                            onChange={this.selectOption}
                            >
                                 {this.state.tempData1.length!=0 &&this.state.tempData1.map((c)=>{
                                return(
                                    <Select.Option key={c.value} value={c.value}>{c.label}</Select.Option>
                                )
                            })}
                            <Select.Option value='none' key='opt2'>New</Select.Option>
                            </Select>
                            </FormItem>
                        </Col>

                       

                        
                    </Row> */}
                  <Row>
                    {/* {this.state.saveTemplateB=='none' &&  */}
                     <Col span={8}>
                        <FormItem label="Enter Template Name">
                        {getFieldDecorator('templateName', {
                          validateTrigger: ['onChange', 'onBlur'],
                          initialValue:this.state.tempName,
                           rules: [{
                               required: true,
                               message: "Please input Template Name ", 
                           }],
                        })(
                            <Input  placeholder="Template Name" onChange={(e)=>{this.setState({tempName: e.target.value})}}  style={{ width: '100%' }} />
                         )} 
                        </FormItem>
                    </Col>
                        {/* } */}
                    </Row>
                   {this.state.saveButton== true && <Row>
                    <Form.Item label="Image">
          {getFieldDecorator('img',{
            rules: [
              {
                required: false,
                message: 'Please Upload Image!',
              },
            ],
          })
          (<Upload
          // name="avatar"
          listType="picture-card"
          // className="avatar-uploader"
          // showUploadList={false}
          // beforeUpload={beforeUpload}
          onChange={this.handleimage}
          onRemove={this.handleRemoveImg}

            >
        {Object.keys(this.state.img).length!=0 ? null : uploadButton}
        </Upload>)}
        </Form.Item>
                    </Row>
                   }


         {this.state.saveTemplateB!='none' && this.state.updateimg &&   <Row>
        <Form.Item label="Image">
          {getFieldDecorator('img',{
            initialValue:this.state.img1,
            rules: [
              {
                required: false,
                message: 'Please Upload Image',
              },
            ],
          })
          (<Upload
          listType="picture-card"
          fileList={img1}
          onChange={this.handleimage1}
          onRemove={this.handleRemoveImg1}

            >
        {this.state.img1.length >= 1 ? null : uploadButton1}
        </Upload>)}
        </Form.Item>

        </Row>

          }
                   
                    
                   
                    <Row>
                    <Col span={20}>
                   {/* { this.state.key != null ? */}
                        <FormItem label="Content:">
                        {getFieldDecorator('description', {
                          validateTrigger: ['onChange', 'onBlur'],
                          initialValue:this.state.modelNew,
                          //  rules: [{
                          //     // whitespace:true
                          //      required: true,
                          //      message: "Please input Page Content ",
                          //  }],
                        })(
                            
                        //   <FroalaEditor
                        //      heightMin= "400px"
                        //     //  heightMax= "600px"
                        //     config={config}
                        //     onModelChange={this.handleModelChange}
                        //     model={this.state.modelNew}
                        //   />
                        <TextArea style={{width:'70%'}} rows={20} placeholder="Content" onChange={this.contentChange} />
                         
                        )}
                      </FormItem>
                   {/* : ""} */}
                      </Col>
                      </Row>
                    <Row>
                    
                      
                       { this.state.saveButton ?
                       <>
                       <Col span={4}>
                            <Button htmlType={'submit'} loading={this.state.loading} style={{ marginLeft:"8px", marginTop:"40px", background:"#389e0d", color:"#fff"}}  onClick={this.handleSave}>Save Template</Button>
                        </Col>
                        <Col span={8}>
                        <Button  loading={this.state.loading} style={{ marginLeft:"8px", marginTop:"40px", background:"#ff4d4f", color:"#fff"}}  
                        onClick={()=>{this.props.history.push('/whatsappTempList')}}
                        >
                            Cancel
                        </Button>
                       </Col>
                       </>
                        :
                        <>
                        {this.state.updateButoon ?
                        <Col span={4}>
                        <Button  loading={this.state.loading} style={{ marginLeft:"8px", marginTop:"40px", background:"#389e0d", color:"#fff"}}  onClick={this.handleupdate}>Update Template</Button>
                    </Col>:''
                      
                        }
                        
                        <Col span={8}>
                        <Button  loading={this.state.loading} style={{ marginLeft:"8px", marginTop:"40px", background:"#ff4d4f", color:"#fff"}}  
                        onClick={()=>{this.props.history.push('/whatsappTempList')}}
                        >
                            Cancel
                        </Button>
                       </Col>
                       
                        </>
                       }
                    </Row>
                    </Wrapper>
                </Form>
            )
        
    }
}
const wrapCustomerEmail = Form.create()(CustomerEmail)
const wrapEmail = compose(
    graphql(sendEmailCampaignToUser,{
        name:'sendEmailCampaignToUser'
    }))(wrapCustomerEmail)
export default withApollo(wrapEmail)