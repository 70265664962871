import React, { Component } from 'react'
import Wrapper from '../Wrapper'
import { Link } from 'react-router-dom';
import { graphql, compose, withApollo, Query } from 'react-apollo';
import { Button, Row, Col, Form, Input, message,Select,DatePicker,Breadcrumb } from 'antd'
import moment from 'moment';
import updateUser from '../mutation/updateUser'
const FormItem = Form.Item
const Option = Select.Option;
function callback(key) {
    console.log(key);
}

const company = [
    { value: 'PROPRIETOR', label: 'PROPRIETOR' },
    { value: 'PARTNERSHIP', label: 'PARTNERSHIP' },
    { value: 'COMPANY', label: 'COMPANY' },
    { value: 'HUF', label: 'HUF' },
    { value:'GOVERNMENT_ENTITY', label:'GOVERNMENT ENTITY' },
]

class editCustomer extends Component {
    constructor(props){
        super(props)
        this.state={
            newlink:this.props.location.state.newlink,
            data:this.props.location.state.data,
            loading:false,
            date:this.props.location.state.data.customerSince?this.props.location.state.data.customerSince:''
        }
    }

    enterLoading=()=>{
        this.setState({
            loading:true
        })
    }

    componentDidMount(){

        let data = this.props.location.state.data;
        let arr = []

        // arr = data.tempData;

        // console.log("CDM Data:: ",data);
        
        // data.tempData.map((p)=>{
        //     do{
        //         arr.push(p)
        //     }while()
        // })

    }

    messageClose = ()=>{
        if(this.state.newlink=='first'){
            this.props.history.push('/')
        }
        if(this.state.newlink=='second'){
            this.props.history.push({
                pathname: '/customeremail',
                state: { newlinkTable:true }
              })
        }
        if(this.state.newlink=='third'){
            this.props.history.push({
                pathname: '/customerwhatsapp',
                state: { newlinkTable:true }
              })
        }
       
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (!err) {
                let temp = [] , obj={}
                // console.log('Received values of form: ', values,this.state.data);
                if(this.state.data.tempData != null && this.state.data.tempData.length > 0){
                     this.state.data.tempData.map((j)=>{
                        // console.log("JJJJ",j);
                        obj={
                            sentAt: j.sentAt,
                            templateId: j.templateId,
                            templateName: j.templateName, 
                        }
                        // delete j.__typename
                        temp.push(obj);
                    })
                    
                    // console.log("Temp...:",temp);
                }
                // return
                this.enterLoading()
                try {
                    this.props.client.mutate({
                        mutation: updateUser,
                        variables: {
                            id:this.props.location.state.data.id,
                            siteId:this.props.location.state.data.siteId,
                            uid: this.state.data.uid,
                            name:values.name,
                            mob:values.mob,
                            email:values.email,
                            customerSince:this.state.date,
                            whatsAppNumber:values.whatsAppNumber,
                            city:values.city,
                            country:values.country,
                            language:values.language,
                            customerType:values.customerType,
                            ProductType:values.ProductType,
                            tempData: temp.length > 0 ? temp : null,
                            url: values.url,
                            BusinessName: values.buisinessName,
                            enabled: values.enabled == "Enable" ? true : false
                        }
                    }).then(({ data }) => {
                        console.log(data)
                        if (data.updateUser != null) {
                            message.success(<span style={{fontSize:'20px'}}>User Updated Successfully </span>, this.messageClose);

                            this.setState({
                                loading: false,
                            })
                        }
                    })
                        .catch(err => {
                            console.log(err)
                            this.setState({
                                loading: false,
                            })
                        });
                }
                catch (err) {
                    console.log(err)
                    this.setState({
                        loading: false,
                    })
                }
            }

            else{
                console.log(err)

            }
        })
        

    }


    onChange=(date, dateString)=>{
        console.log(dateString)
    let c=moment(dateString, "DD-MM-YYYY")
        let a=Math.floor(new Date(dateString) / 1000);
    let ll=moment.unix(a).format("DD/MM/YYYY")
        // console.log("a,a",ll)
        this.setState({
            date:ll
        })
    }

    onSelectStatus=(e)=>{
        // console.log(">>>>",e)
    }


    render() {
        let d=moment.unix(this.state.date).format("DD-MM-YYYY")
        // console.log("newlink",this.props.location.state.data.enabled);
        // console.log("newData :: ",this.state.data.enabled);
        const { getFieldDecorator } = this.props.form;
        return (
            <Wrapper header={"Edit Customer"}>

            <div>
            {/* <Row>
                        <Col xs={{ span: 64 }} sm={{ span: 24 }} md={{ span: 23 }} lg={{ span: 23 }} xl={{ span: 21 }}>
                            <Breadcrumb style={{ marginBottom: "20px" }}>
                                <Link to="/">
                                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                                </Link>
                                <Link to="/customer">
                                    <Breadcrumb.Item>Customer List</Breadcrumb.Item>
                                </Link>
                                <Breadcrumb.Item>Edit Customer</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row> */}

                <Form onSubmit={this.handleSubmit}>
                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="Name">
                                {getFieldDecorator(`name`, {
                                    initialValue:this.state.data && this.state.data.Name?this.state.data.Name :'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Name",
                                    },
                                    ]
                                })(
                                    <Input placeholder="Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="Email"
                            >
                                {getFieldDecorator(`email`, {
                                    initialValue:this.state.data && this.state.data.Email?this.state.data.Email:'',

                                    rules: [{
                                        type: 'email',
                                        required: true,
                                        message: "Please enter E-mail!",
                                    },

                                    ]
                                })(
                                    <Input placeholder="Email" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="Mobile Number"
                            >
                                {getFieldDecorator(`mob`, {
                                    initialValue:this.state.data && this.state.data.Mobile?this.state.data.Mobile:'',

                                    rules: [{
                                        required: true,
                                        message: "Please enter Mobile Number",
                                    },
                                    {
                                        validator: (rule, value, cb) => {
                                            if (value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 10)) {
                                                cb('Please enter 10 digit number only')
                                            }
                                            cb()
                                        }
                                    }
                                    ]
                                })(
                                    <Input placeholder="Mobile Number" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                    </Row>

                   
                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="Customer Type">
                                {getFieldDecorator(`customerType`, {
                                    initialValue:this.state.data && this.state.data.customerType?this.state.data.customerType:'',

                                    rules: [{
                                        required: true,
                                        message: "Please enter Customer Type",
                                    },
                                    ]
                                })(
                                    // <Select placeholder="company type">
                                    //         {company.map((c) => {
                                    //             return (
                                    //                 <Option key={c.value} value={c.value}>{c.label}</Option>
                                    //             )
                                    //         })}
                                    //     </Select>
                                    <Input placeholder="Customer type" style={{ width: '100%' }} />

                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="Customer since"
                            >
                                {getFieldDecorator(`customerSince`, {
                                    initialValue:this.state.data && this.state.data.customerSince? moment(this.state.data.customerSince, "DD-MM-YYYY"):'',
                                    // initialValue:moment(d, "DD-MM-YYYY"),
                                    rules: [{
                                        required: false,
                                        message: "Please enter Customer Since",
                                    },

                                    ]
                                })(
                                    // <Input  style={{ width: '100%' }} />
                                    <DatePicker placeholder="Customer Since"  onChange={this.onChange} />
                                )}
                            </FormItem>
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="WhatsApp Number"
                            >
                                {getFieldDecorator(`whatsAppNumber`, {
                                    initialValue:this.state.data && this.state.data.whatsAppNumber?this.state.data.whatsAppNumber:'',

                                    rules: [{
                                        required: true,
                                        message: "Please enter WhatsApp Number",
                                    },
                                    {
                                        validator: (rule, value, cb) => {
                                            if (value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 10)) {
                                                cb('Please enter 10 digit number only')
                                            }
                                            cb()
                                        }
                                    }
                                    ]
                                })(
                                    <Input placeholder="WhatsApp Number" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="City">
                                {getFieldDecorator(`city`, {
                                    initialValue:this.state.data && this.state.data.city?this.state.data.city:'',

                                    rules: [{
                                        required: false,
                                        message: "Please enter City",
                                    },
                                    ]
                                })(
                                    <Input placeholder="City" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="Country"
                            >
                                {getFieldDecorator(`country`, {
                                    initialValue:this.state.data && this.state.data.Country?this.state.data.Country:'',

                                    rules: [{
                                        required: false,
                                        message: "Please enter Country",
                                    },

                                    ]
                                })(
                                    <Input placeholder="Country" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="Product Type"
                            >
                                {getFieldDecorator(`ProductType`, {
                                    initialValue:this.state.data && this.state.data.ProductType?this.state.data.ProductType:'',

                                    rules: [{
                                        required: false,
                                        message: "Please enter Product Type",
                                    },
                                    ]
                                })(
                                    <Input placeholder="Product type" style={{ width: '100%' }} />

                                    // <Select placeholder="Product type">
                                    //         {company.map((c) => {
                                    //             return (
                                    //                 <Option key={c.value} value={c.value}>{c.label}</Option>
                                    //             )
                                    //         })}
                                    //     </Select>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="Language">
                                {getFieldDecorator(`language`, {
                                    initialValue:this.state.data && this.state.data.language?this.state.data.language:'',

                                    rules: [{
                                        required: false,
                                        message: "Please enter Language",
                                    },
                                    ]
                                })(
                                    <Input placeholder="Language" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="Buisiness Name">
                                {getFieldDecorator(`buisinessName`, {
                                    initialValue:this.state.data && this.state.data.BusinessName?this.state.data.BusinessName:'',

                                    rules: [{
                                        required: false,
                                        message: "Please enter Buisiness Name",
                                    },
                                    ]
                                })(
                                    <Input placeholder="Buisiness Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="Url">
                                {getFieldDecorator(`url`, {
                                    initialValue:this.state.data && this.state.data.Url?this.state.data.Url:'',

                                    rules: [{
                                        required: false,
                                        message: "Please enter Url",
                                    },
                                    ]
                                })(
                                    <Input placeholder="Url" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        
                    </Row>
                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="Status ">
                            {getFieldDecorator(`enabled`, {
                                    initialValue: this.state.data &&  this.state.data.enabled == false ?"Disable" :"Enable",

                                    rules: [{
                                        required: false,
                                        // message: "Please enter Url",
                                    },
                                    ]
                                })(
                            
                            <Select 
                            placeholder="Select Template"
                            style={{ width: '70%' }}
                            onChange={(e)=>this.onSelectStatus(e)}
                            >
                               <Option value="true">Enable</Option>
                               <Option value="false">Disable</Option>
                            </Select>
                              )}
                            </FormItem>
                        </Col>

                       

                        
                    </Row>

                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem>
                                <Button loading={this.state.loading} type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                {this.state.newlink=='first' &&
                                 <Link to={{
                                    pathname: '/',
                                }}>
                                 <Button  type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%",marginLeft:"10px" }}  >Cancel</Button>
                                </Link>
                                }
                                {this.state.newlink=='second' &&
                                 <Link to={{
                                    pathname: '/customeremail',
                                    state: {
                                        newlinkTable:true
                                    }
                                }}>
                                 <Button  type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%",marginLeft:"10px" }}  >Cancel</Button>
                                </Link>
                                }
                                {this.state.newlink=='third' &&
                                 <Link to={{
                                    pathname: '/customerwhatsapp',
                                    state: {
                                        newlinkTable:true
                                    }
                                }}>
                                 <Button  type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%",marginLeft:"10px" }}  >Cancel</Button>
                                </Link>
                                }
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </div>
            </Wrapper>
        )
    }
}
const WrappedTimeRelatedForm = Form.create({ name: 'time_related_controls' })(editCustomer);

export default withApollo(WrappedTimeRelatedForm);
