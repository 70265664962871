import gql from 'graphql-tag';
export default gql`
query getEmailTempletes($inputParam: String!){
  getEmailTempletes(inputParam: $inputParam){
    id
    partionKey
    templeteName
    subject
    content
  }
}`