import gql from "graphql-tag";

export default gql`
  mutation deleteTemplete($siteId: String!, $uid: String!) {
    deleteTemplete(siteId: $siteId, uid: $uid) {
        id
        siteId
        uid
        displayName
    }
  }
`;