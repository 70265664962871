import gql from 'graphql-tag';
export default gql`
query getSiteSetting($sortType: String!){
  getSiteSetting(sortType: $sortType){
    sortType
    siteId
    whatsAppNumber
    token
    accountId
    productId
    createdAt
  }
}`
//  {
//    "sortType": "8668378093"
//  }