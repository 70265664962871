import React, { Component } from 'react'
import Wrapper from '../Wrapper'
import { graphql, compose, withApollo, Query } from 'react-apollo';
import { Button, Row, Col, Form, Input, message } from 'antd'
const FormItem = Form.Item

function callback(key) {
    console.log(key);
}


class EmailC extends Component {

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log("val", values)
            }
            else {

            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>

                <Form onSubmit={this.handleSubmit}>
                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label="Name">
                                {getFieldDecorator(`name`, {
                                    rules: [{
                                        required: true,
                                        message: "Please enter Name",
                                    },
                                    ]
                                })(
                                    <Input placeholder="Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="Email"
                            >
                                {getFieldDecorator(`email`, {
                                    rules: [{
                                        type: 'email',
                                        required: true,
                                        message: "Please enter E-mail!",
                                    },

                                    ]
                                })(
                                    <Input placeholder="Email" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem
                                label="Mobile Number"
                            >
                                {getFieldDecorator(`mob`, {
                                    rules: [{
                                        required: true,
                                        message: "Please enter Mobile Number",
                                    },
                                    {
                                        validator: (rule, value, cb) => {
                                            if (value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 10)) {
                                                cb('Please enter 10 digit number only')
                                            }
                                            cb()
                                        }
                                    }
                                    ]
                                })(
                                    <Input placeholder="Mobile Number" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                 <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%",marginLeft:"10px" }}  >Cancel</Button>

                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}
const WrappedTimeRelatedForm = Form.create({ name: 'time_related_controls' })(EmailC);

export default withApollo(WrappedTimeRelatedForm);
