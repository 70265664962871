import gql from 'graphql-tag';
export default gql`
mutation createTempUserlist(
  $siteId: String!
	$mob: String!
	$imageLink: String
	$name: String!
	$text: String!
	$url: String!
	$token: String!
){
  createTempUserlist(input: {
    siteId: $siteId
    mob: $mob
    imageLink: $imageLink
    name: $name
    text: $text
    url: $url
    token: $token
  }){
    sortType
    siteId
    mob
    imageLink
    name
    text
    url
    token
  }
}`
// {
//     "siteId": "8668378093",
//       "mob": "7350099005",
//     "imageLink": "sdkjbvkjsd",
//     "name": "ajay",
//     "text": "zdc",
//     "url": "sdv",
//     "token": "svd"
// }