import gql from 'graphql-tag';
export default gql`
mutation createEmailTemplet(
    $partionKey: String!
      $templeteName: String!
      $subject: String
      $content: String
      $image: S3ObjectInput
  )
  {
    createEmailTemplet(input: {
    partionKey: $partionKey
      templeteName: $templeteName
      subject: $subject
      content: $content
      image:$image
    }){
      id
      partionKey
      templeteName
      subject
      content
      image {
        bucket
        region
        key
      }
  
    }
  }
  # {
  #   "partionKey": "email",
  #   "templeteName": "offer",
  #   "subject": "padva offer",
  #   "content": "happy padva"
  # } ` 