import gql from 'graphql-tag';
export default gql`
mutation sendEmailCampaignToUser($input: [EmailCampaignInput!]){
  sendEmailCampaignToUser(input: $input)
}`
//  {
//    "input": [
//      {
//        "mail": "khan.sohel005@gmail.com",
//        "subject": "Test Subject",
//        "htmlText": "hello"
//      },
//     {
//      "mail": "jiv4389@gmail.com",
//      "subject": "Test Subject",
//      "htmlText": "hello"
//      }
//    ]
//  }`