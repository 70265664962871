import React, { Component } from 'react'
import Wrapper from '../Wrapper'
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment'
import axios from 'axios';
import https from 'https'
import Highlighter from 'react-highlight-words';
import { graphql, compose, withApollo, Query } from 'react-apollo';
import { Table, Button, Card, Row, Col, Form, Icon, Upload, Popconfirm,Input, Switch,Select, Spin, Modal, message } from 'antd'
import PapaParse from 'papaparse';
import createUserlist from '../mutation/createUserlist'
import getUserlistByCreatedAt from '../query/getUserlistByCreatedAt'
import getTempUserlist from '../query/getTempUserlist'
import getSiteSetting from '../query/getSiteSetting'
import getWhatsAppSendingStatus from '../query/getWhatsAppSendingStatus'

import updateUser from '../mutation/updateUser'
let j = 0;
// importScripts("https://www.gstatic.com/firebasejs/4.12.0/firebase-app.js");
// importScripts("https://www.gstatic.com/firebasejs/4.12.0/firebase-messaging.js");
const FormItem = Form.Item
const { Option } = Select;

class UploadFile extends Component {

    constructor(props) {
        super(props)
        this.state = {
          showemailcap:true,
            dataSource: [],
            loading: false,
            visible: false,
            ViewBuyerData: {},
            uploadresult: [],
            uploadedFileName: '',
            fileExtensionError: false,
            csvFileError: false,
            uploading: false,
            emptyCSV: '',
            download1: false,
            listname:'',
            showUserList:false,
            userListData:[],
            selectData:'',
            shownextButton:true,
            showwhatsApp: false,
            showEmail: false,
            tableData:[],
            spinLoading:true,
            settingData:{},
            // pagination:"10",
            selectedRowKeys: this.props.keys, // Check here to configure the default column
            pendingData:[],
            userSelected:"",
            loaderP:true,
            selectedRows:[],
            todayDate:'',
            month: '',
            enab: undefined,
            selectOpen: false,
            selectedValue: '',
            // pagination: {
            //   current: 1,
            //   defaultPageSize: 10,
            //   showSizeChanger: true,
            //   pageSizeOptions: ['10', '20', '50','100']
            // },
            pagination: {},
            custType: [],
            prodType: [],
            filterNo: 0,

            cTypeFilter: '',
            pTypeFilter: '',
            filterBy: '',
            statusFilter: false,
            templateDetails: [],
            templateModal: false,
            nextToken: '',
        }
    }

    // handleDelete = (d) => {
    //     console.log(d)
    // }

    cUserList=(data)=>{
      let arr=[], custData = [] , prodData = []

      custData = this.state.custType ;
      prodData = this.state.prodType ;

        if(data!=null){
          // console.log("a",data)
          // console.log("a",j)
          data.map((i)=>{
          // console.log("a",i)
            let obj={
              key:j+1,
              uid: i.uid,
              Name: i.name != null ? i.name : '',
              Mobile: i.mob != null ? i.mob : '',
              Email: i.email != null ? i.email : '',
              customerSince: i.customerSince != null ? i.customerSince : '',
              whatsAppNumber: i.whatsAppNumber != null ? i.whatsAppNumber : '',
              city: i.city != null ? i.city : '',
              country: i.country != null ? i.country : '',
              language: i.language != null ? i.language : '',
              customerType: i.customerType != null ? i.customerType : '',
              ProductType: i.ProductType != null ? i.ProductType : '',
              Status: i.sendingStatus != null ? i.sendingStatus : '',
              tempData: i.tempData != null ? i.tempData : null,
              tempName: i.tempData ? i.tempData[0].templateName : '',
              lastSent: i.tempData ? i.tempData[0].sentAt : null,
              siteId: i.siteId !=null ? i.siteId : '',
              enabled: i.enabled ,
              BusinessName: i.BusinessName != null ? i.BusinessName : '',
              Url: i.url != null ? i.url :'',

            }
            arr.push(obj)
            if(!custData.includes(i.customerType)){
              // console.log("In If")
              if(i.customerType != null){
              custData.push(i.customerType)
              }
            }
            if(!prodData.includes(i.ProductType)){
              // console.log("In If")
              if(i.ProductType != null){
              prodData.push(i.ProductType)
              }
            }
            j++;
          })
        }
        // console.log("custType::",custData);
        // console.log("prodType::",prodData);
        // console.log("arr",arr)
        this.setState({
          userListData: this.state.userListData.concat(arr),
          tableData: this.state.tableData.concat(arr),
          spinLoading:false,
          loading: false
        })
    }

    cUserList1=(data)=>{
      let arr=[], custData = [] , prodData = []

      custData = this.state.custType ;
      prodData = this.state.prodType ;

        if(data!=null){
          // console.log("a",data)
          data.map((i,j)=>{
          // console.log("a",i)
            let obj={
                        key:j+1,
                        uid: i.uid,
                        Name: i.name != null ? i.name : '',
                        Mobile: i.mob != null ? i.mob : '',
                        Email: i.email != null ? i.email : '',
                        customerSince: i.customerSince != null ? i.customerSince : '',
                        whatsAppNumber: i.whatsAppNumber != null ? i.whatsAppNumber : '',
                        city: i.city != null ? i.city : '',
                        country: i.country != null ? i.country : '',
                        language: i.language != null ? i.language : '',
                        customerType: i.customerType != null ? i.customerType : '',
                        ProductType: i.ProductType != null ? i.ProductType : '',
                        Status: i.sendingStatus != null ? i.sendingStatus : '',
                        tempData: i.tempData != null ? i.tempData : null,
                        tempName: i.tempData ? i.tempData[0].templateName : '',
                        lastSent: i.tempData ? i.tempData[0].sentAt : null,
                        siteId: i.siteId !=null ? i.siteId : '',
                        enabled: i.enabled ,
                        BusinessName: i.BusinessName != null ? i.BusinessName : '',
                        Url: i.url != null ? i.url :'',

            }
            arr.push(obj)
            if(!custData.includes(i.customerType)){
              // console.log("In If")
              if(i.customerType != null){
              custData.push(i.customerType)
              }
            }
            if(!prodData.includes(i.ProductType)){
              // console.log("In If")
              if(i.ProductType != null){
              prodData.push(i.ProductType)
              }
            }
          })
        }
        // console.log("custType::",custData);
        // console.log("prodType::",prodData);
        // console.log("arr",arr)
        this.setState({
          userListData: this.state.userListData.concat(arr),
          dataSource: this.state.dataSource.concat(arr),
          tableData: this.state.tableData.concat(arr),
          spinLoading:false
        })
    }

    componentDidMount = () => {
      this.enterLoading()
      var sortType = localStorage.getItem('userName')
      var siteId = localStorage.getItem('siteId')

      var today = new Date();
      // console.log("Todays Obj::",today)
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = dd + '/' + mm + '/' + yyyy;

      this.setState({
        todayDate: today,
        month: mm
      })
      // console.log("Todays date::",today)
      this.getuser('',50)

      this.props.client.query({
          query: getSiteSetting,
          fetchPolicy: 'network-only',
          variables: { sortType: siteId
          }
      })
          .then(({ data }) => {
            // console.log("setting data::",data)
            this.setState({
              settingData: data
            })
          })
          .catch(err => {
              console.log(`Error ${JSON.stringify(err)}`)
          })

        //   this.props.client.query({
        //     query: getTempUserlist,
        //     fetchPolicy: 'network-only',
        //     variables: { siteId: siteId
        //     }
        // })
        //     .then(({ data }) => {
        //       // console.log("Temp User Data::",data)
        //       this.setState({
        //         pendingData: data.getTempUserlist,
        //         loaderP: data.getTempUserlist.lenght == 0 ? false : true
        //       })
        //     })
        //     .catch(err => {
        //         console.log(`Error ${JSON.stringify(err)}`)
        //     })

      // this.getStatus(siteId)
      
      
  }


  getuser=(nt,limits)=>{
    var sortType = localStorage.getItem('siteId')
    let i = 0
    // this.enterLoading()
    this.setState({
      loading:true
    })
  
    this.props.client.query({
      query: getUserlistByCreatedAt,
      fetchPolicy: 'no-cache',
      variables: { siteId: sortType,nextToken: nt,limit: limits
      },
  })
      .then(({ data }) => {
        // console.log(".......",data)
          this.cUserList(data.getUserlistByCreatedAt.items);
          let nextToken1 = data.getUserlistByCreatedAt.nextToken
          this.setState({
            nextToken: nextToken1,
            // dataSource: this.state.dataSource.concat(data.getUserlistByCreatedAt.items),
            // loading:false,
          })
        //  console.log("Token In State::",nextToken1);
         if(nextToken1 != null && nextToken1 != undefined && nextToken1 != ''){
          this.getuser(nextToken1,50)
         }
      })
      .catch(err => {
          // console.log(`Error ${JSON.stringify(err)}`)
      })
  
  }

  getStatus=(siteId)=>{
    this.props.client.query({
      query: getWhatsAppSendingStatus,
      fetchPolicy: 'network-only',
      variables: { siteId: siteId
      }
  })
      .then(({ data }) => {
        // console.log("Temp User Statau::",data)
        this.setState({
          currentStatus: data.getWhatsAppSendingStatus,
        })
      })
      .catch(err => {
          console.log(`Error ${JSON.stringify(err)}`)
      })
  }

 
      getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters
        }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search here`}
              value={selectedKeys[0]}
              onChange={e =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
          if (dataIndex == 'upn') {
            return record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          } else {
            let a = dataIndex.split('.')[1].toString();
            return record.masterVariationDump[a]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        },
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        )
      });
    
      handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0], onSearch: true,search:true });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({
          searchText: '',
          // nextToken: '',
          productList: [],
          loading: false,
          hasMore: true,
          search:false
        });
      };
    
      enterLoading(){
          this.setState({
            loading:true
          })
      }

 
  updatestate=()=>{
    this.setState({
      pendingData: null
    })
  }
  restApi = () => {
    var siteId = localStorage.getItem('siteId')
    // console.log("rest call", siteId);
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });

      axios
        .get(`https://restapi.mysnaptrade.com/startProcess/${siteId}`)
        .then(function (response) {
          // console.log("Success:: ", response.data);
          message.success("Retry Sending Whatsapp Started Successfully");
          this.updatestate()
        })
        .catch(function (error) {
          console.log("Error:: ", error);
          message.error("Please Check Maytapi Connection");
        });
  };

  start = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };

  // sendWhatsapp=()=>{

  //   this.props.handleEmail
  //   // this.start
  // }

  onSelectChange = (selectedRowKeys,data) => {
    // console.log('selectedRowKeys changed: ', selectedRowKeys,data);
    this.setState({ selectedRowKeys });
        this.props.handleCustomerData(selectedRowKeys,data)
      this.props.handleSettingData(this.state.settingData)
  };

  onChange= (e)=>{
    // console.log("In Change Filter",e);

  this.setState({statusFilter: e},()=>this.onChangeAll())
  
  }

  onChange1= (e)=>{
    // console.log("In Change Filter",e);
    
    this.setState({filterBy : e},()=>this.onChangeAll())

  }

  onChange2= (e)=>{
    
    // console.log("In Change Filter",e,this.state.enab);

    // console.log("In Change Filter Data :::",this.state.tableData, this.state.userListData);
    // return
    this.setState({cTypeFilter : e},()=>this.onChangeAll())
    
  }

  onChange3= (e)=>{
    // console.log("In Change Filter",e);

    this.setState({pTypeFilter : e},()=>this.onChangeAll())
    
  }


  onChangeAll=()=>{

    let filter1 ,filter2 , filter3 , filter4, data = [], tempArr = [],tempArr1 =[],tempArr2 = []
    
    const today = this.state.todayDate;

    filter1 = this.state.statusFilter;
    filter2 =  this.state.filterBy;
    filter3 = this.state.cTypeFilter;
    filter4 = this.state.pTypeFilter;

    data = this.state.userListData;

    // console.log("changeAll::",filter1,filter2,filter3,filter4);

     if(filter1 != '' && filter1 != undefined){
      //  console.log("In filter 1 :::")
      let arr = []

        data.map((p)=>{
        // console.log("In Map",p.enabled)
          if(p.enabled == true &&  filter1 == "active" ){
            // console.log("In If ::",p.enabled === filter1)
            tempArr.push(p)
          }
          if(p.enabled == false &&  filter1 != "inactive"){

            tempArr.push(p)
            // console.log("In Else")
          }
          
        })

        // console.log(".....1",tempArr);
        this.setState({tableData: tempArr})
     }
     if(filter1 == '' || filter1 == undefined){

      // console.log("In All filter 1 :::")
     
      this.setState({tableData: data})
     
    }


     if(filter2 != '' && filter2 != undefined){
      // console.log("In filter 2 :::")
      if(filter2 != 7){
      let arr = []

      // let temp = tempArr.length > 0 ? tempArr : data
      let temp = filter1 != undefined && filter1 != '' ? tempArr : data;
      
      temp.map((p)=>{
        // console.log("...p",p)

        if( p.tempData != null ){
          // console.log("...p",p)
          p.tempData.map((q)=>{
            
          if(this.props.tempId == q.templateId){
            // console.log("QQQ",q,this.props.tempId)
          var dateString = moment.unix(p.lastSent).format("DD/MM/YYYY");
          let dateString1 = dateString.split("/")
          var monthSent =  dateString1[1];
          //  console.log("In IF ... ",monthSent,dateString1);
           if( (new Date(today).getTime() > new Date(dateString).getTime())){
            // console.log("In 3rd If IF...",p); 
            let diff = this.state.month - monthSent;
            // console.log("Diff;;;",diff)
            if(diff >= filter2 ){
              tempArr1.push(p)
            }
           }
           }
          })
        }
        
      })
      // console.log(".....2",tempArr1);

      this.setState({tableData: tempArr1})
    }
    if(filter2 == 7){
   
      let tempvar ;

      let temp = filter1 != undefined && filter1 != '' ? tempArr : data;

        temp.map((p)=>{
          
          // console.log("...in If filt == 8",)
          
   
            if(p.tempData != null ){
              // p.tempData.map((q)=>{
              // })
              tempvar = p.tempData.find(q => q.templateId == this.props.tempId);
                // console.log("...q",tempvar)  
    
                if(tempvar == undefined){
                  // console.log("...",p,this.props.tempId)
                  
                tempArr1.push(p)
                }
              
            }else{
              // console.log("...else ::::::") 
              tempArr1.push(p)
            }
           
          
          
        })
      
        this.setState({tableData: tempArr1})
    }
  }
  
    if(filter3 != '' && filter3 != undefined){

      // console.log("In filter 3 :::")
      // let temp = filter2 != undefined && tempArr1.length > 0 ? tempArr : data

      let temp = filter2 != undefined && filter2 != '' ? tempArr1 : filter1 != undefined && filter1 != '' ? tempArr : data

      let arr = []
       temp.map((p)=>{

         if(p.customerType == filter3){
           tempArr2.push(p)
         }
       })
      //  console.log(".....3",tempArr2);
       this.setState({tableData: tempArr2})
    }

    if(filter4 != '' && filter4 != undefined){

      // console.log("In filter 4 :::")

      // let temp = tempArr.length > 0 ? tempArr : data

      let temp = filter3 !=undefined && filter3 != '' ? tempArr2 : filter2 != undefined && filter2 != '' ? tempArr1 : filter1 != undefined && filter1 != '' ? tempArr : data

      let arr = []
      temp.map((p)=>{

         if(p.ProductType == filter4){
           arr.push(p)
         }
       })
      //  console.log(".....4",arr);
       this.setState({tableData: arr})
    }

  }


 

  handleUpdate = (values) => {
      
    // console.log('Received values of form: ', values);
    let temp = [] , obj={}
      // console.log('Received values of form: ', values,this.state.data);
      if(values.tempData != null){
        values.tempData.map((j)=>{
              // console.log("JJJJ",j);
              obj={
                  sentAt: j.sentAt,
                  templateId: j.templateId,
                  templateName: j.templateName, 
              }
              // delete j.__typename
              temp.push(obj);
          })
          
          // console.log("Temp...:",temp);
      }
    // return
    // this.enterLoading()
    try {
        this.props.client.mutate({
            mutation: updateUser,
            variables: {
                id:values.id,
                siteId:values.siteId,
                uid: values.uid,
                name:values.name,
                mob:values.mob,
                email:values.email,
                customerSince:values.date,
                whatsAppNumber:values.whatsAppNumber,
                city:values.city,
                country:values.country,
                language:values.language,
                customerType:values.customerType,
                ProductType:values.ProductType,
                tempData: values.tempData,
                url: values.url,
                BusinessName: values.BusinessName,
                enabled: !values.enabled,
                tempData: values.temp
            }
        }).then(({ data }) => {
            // console.log(data)
            if (data.updateUser != null) {
                message.success(<span style={{fontSize:'20px'}}>User Updated Successfully </span>, this.messageClose);

                this.setState({
                    loading: false,
                },()=>{this.getuser()}
)
            }
        })
            .catch(err => {
                // console.log(err)
                this.setState({
                    loading: false,
                })
            });
    }
    catch (err) {
        // console.log(err)
        this.setState({
            loading: false,
        })
    }

}

onDetails=(data)=>{

  // console.log("::::",data);
  let arr = [], obj={}

  data.tempData && data.tempData.map((p)=>{
    arr.push(p)
    // console.log("....",p);
  })

  this.setState({
      templateDetails : arr,
      templateModal: true,
  })

}

onSearch=(e)=>{
      
  let n = e.target.value;
  // console.log("In Search",n)
  // console.log("In Search",this.state.prodData)
  this.setState({
    prodloading: true
  })
   // let index = data.findIndex((item) => (item.key == n) ||  (item.qty == n) ||  (item.price == n) ||  (item.note == n))
   // const found = data.find(element => element.name == n);
  // return
   let arr = [];
   this.state.userListData.map((i, j) => {
    //  console.log(":::::::::::",i)
     if (
               i.BusinessName && i.BusinessName.toLowerCase().includes(n.toLowerCase()) 
           ||  i.Email && i.Email.toLowerCase().includes(n.toLowerCase())
           ||  i.Mobile && i.Mobile.toLowerCase().includes(n.toLowerCase()) 
           ||  i.Name && i.Name.toLowerCase().includes(n.toLowerCase()) 
           ||  i.ProductType && i.ProductType.toLowerCase().includes(n.toLowerCase()) 
           ||  i.Status && i.Status.toLowerCase().includes(n.toLowerCase()) 
           ||  i.Url && i.Url.toLowerCase().includes(n.toLowerCase()) 
           ||  i.city && i.city.toLowerCase().includes(n.toLowerCase()) 
           ||  i.country && i.country.toLowerCase().includes(n.toLowerCase()) 
           ||  i.customerType && i.customerType.toLowerCase().includes(n.toLowerCase()) 
           ||  i.language && i.language.toLowerCase().includes(n.toLowerCase()) 
           ||  i.whatsAppNumber && i.whatsAppNumber.toLowerCase().includes(n.toLowerCase())  
         ) {
       arr.push(i);
     }
   });
   
   // console.log("Search Index:: ",index)
  // console.log("Search Name:: ",arr)
  // return
   this.setState({
     tableData: arr,
     prodloading: false
   })
   if(n == ''){
    // console.log("Search No Result:: ")
     
     this.setState({
       tableData: this.state.userListData,
       prodloading: false
     })
   }
 }

 getUserData=(params = {}) =>{
  
  var sortType = localStorage.getItem('siteId')
  // console.log("nextToken",this.state.nextToken);

  this.props.client.query({
    query: getUserlistByCreatedAt,
    fetchPolicy: 'no-cache',
    variables: { siteId: sortType,nextToken: this.state.nextToken,limit: 50
    },
})
    .then(({ data }) => {
      // console.log(".......Pag Fetch",data.getUserlistByCreatedAt.items.length + this.state.dataSource.length);
      // console.log(".......Pag Fetch",data);
      const pagination = { ...this.state.pagination };
      
      pagination.total=data.getUserlistByCreatedAt.items.length + this.state.dataSource.length;

        this.cUserList1(data.getUserlistByCreatedAt.items);
        this.setState({
          nextToken: data.getUserlistByCreatedAt.nextToken,
          pagination
        })
    })
    .catch(err => {
        // console.log(`Error ${JSON.stringify(err)}`)
    })

}

handleTableChange = (pagination, filters, sorter) => {
  // skip = skip + limit 
  const pager = { ...this.state.pagination };
  pager.current = pagination.current;
  pager.total=this.state.dataSource.length
  
//  console.log('pager',pager.current,pager,pager.total)
//  this.setState({
//    data: dataStatusNew
//  })
let temp = pager.total%10;
// console.log(">>>>",pager.current,pager.total/10);

  if(pager.current == (pager.total/10)){

    // console.log("In If",pager.current,pager.total/10)
    // skips = skips + limits
    this.getUserData({
      results: pagination.pageSize,
      page: pagination.current,
    });
  }
};

    render() {
      // console.log("pending:::",this.state.pendingData.length)
        const { loading, selectedRowKeys } = this.state;
        const { getFieldDecorator } = this.props.form;
        const rowSelection = {
          selectedRowKeys,
          onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        // console.log("In Modal ::",this.state.templateModal);

        const columns = [
          // {
          //   title: 'Sr.No',
          //   dataIndex: 'key',
          //   key: 'key',
          //   width:70
          // },
          {
            // title: 'Last Massage SentAt',
            title: ()=>{
              return(
                <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Last Message Date</h3>
              )},
            dataIndex: 'lastSent',
            render: (date) =>{
              var dateString = moment.unix(date).format("DD/MM/YYYY hh:mm a");
              return(
                <span>
                   {date != null && <div style={{fontSize:"11px",fontWeight:"normal"}}>{dateString}</div>} 
                   {date == null && <div>-</div>}
                </span>
              )
            }
            
          },{
            // title: 'Last Sent Template',
            title: ()=>{
              return(
                <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Last Message Template</h3>
              )},
            dataIndex: 'tempName',
            render: (attachedCat,record) => {
              var words = attachedCat.split('-');
              for (var i = 0; i < words.length; i++) {
                var word = words[i];
                words[i] = word.charAt(0).toUpperCase() + word.slice(1);
              }
            return(
              <span style={{fontSize:"11px",fontWeight:"normal",cursor: "pointer"}} onClick={()=>{this.onDetails(record)}} > {words.join(' ')}</span>
            )
          
        }
          },
            {
                // title: 'Name',
                title: ()=>{
                  return(
                    <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Name</h3>
                  )},
                dataIndex: 'Name',
                key: 'name',
                render:(data)=>{
                  // console.log("Data..",data)
                  return(
                    <h3  style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
                  )
                },
                ...this.getColumnSearchProps('Name'),
                // width: 250

            },
            {
              // title: 'Business Name',
              title: ()=>{
                return(
                  <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Business Name</h3>
                )},
              dataIndex: 'BusinessName',
              render:(data)=>{
                // console.log("Data..",data)
                return(
                  <h3  style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
                )
              },
              key: 'BusinessName',
              ...this.getColumnSearchProps('BusinessName')
            },
            {
                // title: 'Mobile',
                title: ()=>{
                  return(
                    <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Mobile</h3>
                  )},
                dataIndex: 'Mobile',
                render:(data)=>{
                  // console.log("Data..",data)
                  return(
                    <h3  style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
                  )
                },
                key: 'mob',
                // ...this.getColumnSearchProps('Mobile'),
            },
            
            {
                // title: 'Email',
                title: ()=>{
                  return(
                    <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Mobile</h3>
                  )},
                dataIndex: 'Email',
                render:(data)=>{
                  // console.log("Data..",data)
                  return(
                    <h3  style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
                  )
                },
                key: 'email',
                width: 200
                // ...this.getColumnSearchProps('Email'),
            },
            {
              // title: 'Customer Since',
              title: ()=>{
                return(
                  <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Customer Since</h3>
                )},
              dataIndex: 'customerSince',
              render:(data)=>{
                // console.log("Data..",data)
                return(
                  <h3  style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
                )
              },
              key: 'customerSince',
              // ...this.getColumnSearchProps('customerSince'),

          },
          {
              // title: 'Whats App Number',
              title: ()=>{
                return(
                  <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Whats App Number</h3>
                )},
              dataIndex: 'whatsAppNumber',
              render:(data)=>{
                // console.log("Data..",data)
                return(
                  <h3  style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
                )
              },
              key: 'whatsAppNumber',
              // ...this.getColumnSearchProps('whatsAppNumber'),
          },{
              // title: 'City',
              title: ()=>{
                return(
                  <h3  style={{fontSize:"13px",fontWeight:"normal"}}>City</h3>
                )},
              dataIndex: 'city',
              render:(data)=>{
                // console.log("Data..",data)
                return(
                  <h3  style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
                )
              },
              key: 'city',
              ...this.getColumnSearchProps('city')
          },{
              // title: 'Country',
              title: ()=>{
                return(
                  <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Country</h3>
                )},
              dataIndex: 'country',
              render:(data)=>{
                // console.log("Data..",data)
                return(
                  <h3  style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
                )
              },
              key: 'country',
              ...this.getColumnSearchProps('country'),
          },{
              // title: 'Language',
              title: ()=>{
                return(
                  <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Language</h3>
                )},
              dataIndex: 'language',
              render:(data)=>{
                // console.log("Data..",data)
                return(
                  <h3  style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
                )
              },
              key: 'language',
              ...this.getColumnSearchProps('language')
          },{
            // title: 'Customer Type',
            title: ()=>{
              return(
                <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Customer Type</h3>
              )},
            dataIndex: 'customerType',
            render:(data)=>{
              // console.log("Data..",data)
              return(
                <h3  style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
              )
            },
            key: 'customerType',
            ...this.getColumnSearchProps('customerType')
        },
          {
              // title: 'Product Type',
              title: ()=>{
                return(
                  <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Product Type</h3>
                )},
              dataIndex: 'ProductType',
              render:(data)=>{
                // console.log("Data..",data)
                return(
                  <h3  style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
                )
              },
              key: 'ProductType',
              ...this.getColumnSearchProps('ProductType'),
          },
          {
            // title: 'Url',
            title: ()=>{
              return(
                <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Url</h3>
              )},
            dataIndex: 'Url',
            render:(data)=>{
              // console.log("Data..",data)
              return(
                <h3  style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
              )
            },
            key: 'Url',
            // render:(data)=>{
            //   // console.log("Data..",data)
            //   return(
            //     <h3 style={{fontSize:"11px"}}>{data ? data : "-"}</h3>
            //   )
            // },
            // ...this.getColumnSearchProps('Url')
        },
        {
          // title: 'Status',
          title: ()=>{
            return(
              <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Status</h3>
            )},
          // dataIndex: 'operation',
          // width: '15%',
          width: "3%",
          render: (text, record) => {
            // console.log("::",record)
           let fd = record.enabled
             return(
            <Switch size="small"
            style={{ marginRight: '10px'}}
              
              onChange={() =>
                this.handleUpdate(record)
              }
              checked={fd}
            />
          )
          }
        },

            {
                // title: 'Action',
                title: ()=>{
                  return(
                    <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Action</h3>
                  )},
                key: 'action',
                // width: 150,
                width: "3%",
                render: (data) => {
                    return (
                        <div>
                            <span>
                            </span>
                            {/* <span>
                              {this.props.renderComp=='email' && 
                            <Link to={{
                                    pathname: '/edit_customer',
                                    state: {
                                        data: data,
                                        newlink:'second'
                                    }
                                }}>
                                    <Button icon="edit" type="primary" style={{ marginLeft: "5px", ursor: "pointer", }} />
                                </Link>
                              }
                               {this.props.renderComp=='app' && 
                               
                            <Link to={{
                                    pathname: '/edit_customer',
                                    state: {
                                        data: data,
                                        newlink:'third'
                                    }
                                }}>
                                    <Button icon="edit" type="primary" style={{ marginLeft: "5px", ursor: "pointer", }} />
                                </Link>
                              }
                            </span> */}
                            <span>
                                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(data)}>
                                    <Button icon="delete" type="danger" size="small" style={{ marginLeft: "5px", ursor: "pointer", }} />
                                </Popconfirm>
                            </span>
                        </div>
                    )
                }

            }
        ];

        const viewTemplates = [
          {
            // title: 'Last Massage SentAt',
            title: ()=>{
              return(
                <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Last Message Date</h3>
              )},
            // dataIndex: 'lastSent',
            render: (record) =>{
              // console.log("Date >>>:::",data);
              // var dateString = moment.unix(date).format("DD/MM/YYYY hh:mm a");
              return(
                <span style={{fontSize:"11px",fontWeight:"normal",cursor: "pointer"}}>{record.sentAt ? record.sentAt : "-"}</span>
                // <span>
                //    {date != null && <div style={{fontSize:"11px",fontWeight:"normal"}}>{dateString}</div>} 
                //    {date == null && <div>-</div>}
                // </span>
              )
            }
            
          },{
            // title: 'Last Sent Template',
            title: ()=>{
              return(
                <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Last Message Template</h3>
              )},
            // dataIndex: 'tempName',
            render: (record) => {
              // console.log("Name >>>:::",record);
              // var words = attachedCat.split('-');
              // for (var i = 0; i < words.length; i++) {
              //   var word = words[i];
              //   words[i] = word.charAt(0).toUpperCase() + word.slice(1);
              // }
            return(
              <span style={{fontSize:"11px",fontWeight:"normal",cursor: "pointer"}}>{record.templateName}</span>
            )
          
        }
          },
        ];

        return (
            <div>
                {this.props.showComp ? 
              <div>
                 {/* <Row gutter={16}> */}
                 <Row gutter={[16, 16]}>
                    <Col span={5} >
                    <Button style={{background:"#389e0d", color:"#fff"}} onClick={this.props.previous}>Previous</Button>
                     <Button type="primary" onClick={this.start} disabled={!hasSelected} loading={loading} style={{ marginLeft: 8 }}>
                        All Clear
                      </Button>
                      <span style={{ marginLeft: 8 }}>
                        {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                      </span>
                      <span style={{ marginLeft: 16 }}>
                    
                   </span>
                    </Col>
                    <Col span={12} > 
                    
                    {/* {this.state.pendingData.length !=0 ? 
                    <h3>Pending:{this.state.pendingData.length}</h3> 
                      : ""
                   } */}

                      {/* <Input placeholder="Search" allowClear style={{width:'50%',borderRadius:10}} 
                      onChange={(e)=>this.onSearch(e)}
                      /> */}
                    </Col>
                   <Col span={3} > 
                   {/* {this.state.pendingData.length !=0 ? 
                   <Button type="primary" onClick={this.restApi}  loading={loading} >
                        Retry
                      </Button>
                      : ""
                   } */}

                      </Col>
                      {/* <Col span={3} > 
                      <h4>Filter By: </h4>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Select"
                        optionFilterProp="children"
                        // onChange={onChange}
                        // onFocus={onFocus}
                        // onBlur={onBlur}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          if(p.enabled == false){
            //  console.log("In IF ... ",p);
             arr.push(p)
          }
        })
        this.setState({
          tableData: arr,
          enab: false,
          selectOpen: true,
          selectedValue: undefined
        },()=>this.onChange1())
      }
     
                        }
                      >
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                        <Option value="tom">Tom</Option>
                      </Select>
                      </Col> */}
              <Col span={4} > 
              {this.state.showwhatsApp == false ? 
                
                <Button style={{float:"left",background:"#f44336", color:"#fff",marginLeft: 8}} loading={this.props.loading}  
                // disabled={this.state.pendingData.length==0 ? false : true  } 
                onClick={()=>{this.setState({selectedRowKeys:[]},this.props.handleEmail)}}>{this.props.customerwhatsappEmail=='email'?"Send Email":" Send Whats App"}</Button>
            :
           ''
           }
           </Col>
        </Row>
        
          <Row>
            <Input placeholder="General Search" allowClear style={{width:'14%',borderRadius:10,marginTop:"0.5%"}} 
                      onChange={(e)=>this.onSearch(e)}
            /> 
          </Row>

           <Form>
        
        <Row>
        <Col span={4} > 
        {/* <div style={{display:"flex", margin:"2%"}}> */}
        <FormItem label="Status:">
                                {getFieldDecorator(`filterStatus`, {
                                    // initialValue: "All",

                                    rules: [{
                                        required: false,
                                        message: "Please select ",
                                    },
                                    ]
                                })(
        
                      // {/* <h4 style={{marginTop:"1%"}}>Filter By Status: </h4> */}
                      <Select
                        showSearch
                        style={{ width: "80%",marginLeft:"2%" }}
                        placeholder="Select"
                        optionFilterProp="children"
                        onChange={(e)=>this.onChange(e)}
                        allowClear={true}
                        // defaultValue={"All"}
                        // onFocus={onFocus}
                        // onBlur={onBlur}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <Option value=''>All</Option> */}
                        <Option value="active">Active Users</Option>
                        <Option value="inactive">Inactive Users</Option>

                        {/* <Option value="3">Customer who have received message 2 months before</Option>
                        <Option value="4">Customer who have received message 3 months before</Option>
                        <Option value="5">Customer who have received message 4 months before</Option>
                        <Option value="6">Customer who have received message 5 months before</Option>
                        <Option value="7">Customer who have received message 6 months before</Option>
                        <Option value="8">Customer who have not received same message before</Option> */}

                      </Select>
                                )}
                                </FormItem>
                      {/* </div> */}
                      </Col>
                      <Col span={10} > 
                       {/* <div style={{display:"flex", margin:"2%"}}> */}
                       <FormItem label="Filter By :">
                                {getFieldDecorator(`filter`, {
                                    // initialValue:this.state.data && this.state.data.city?this.state.data.city:'',

                                    rules: [{
                                        required: false,
                                        message: "Please select ",
                                    },
                                    ]
                                })(
                      // <h4 >Filter By : </h4>
                      <Select
                        showSearch
                        style={{ width: "80%",marginLeft:"2%",marginTop:"-1%" }}
                        placeholder="Select"
                        optionFilterProp="children"
                        onChange={(e)=>this.onChange1(e)}
                        allowClear={true}
                        // value={this.state.selectedValue}
                        // defaultValue={""}

                        // defaultOpen={this.state.selectOpen}
                        // onFocus={onFocus}
                        // onBlur={onBlur}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <Option value="jack">All</Option> */}
                        {/* <Option value="1">Active Users</Option> */}
                        {/* <Option value="2">Inactive Users</Option> */}
                        <Option value="2">Customer who have received message 2 months before</Option>
                        <Option value="3">Customer who have received message 3 months before</Option>
                        <Option value="4">Customer who have received message 4 months before</Option>
                        <Option value="5">Customer who have received message 5 months before</Option>
                        <Option value="6">Customer who have received message 6 months before</Option>
                        <Option value="7">Customer who have not received same message before</Option>
                      </Select>
                                )}
                                </FormItem>
                      {/* </div> */}
                      </Col>
                      <Col span={4} > 
        {/* <div style={{display:"flex", margin:"2%"}}> */}
        <FormItem label="Customer Type:">
                                {getFieldDecorator(`filterCust`, {
                                    // initialValue: "All",

                                    rules: [{
                                        required: false,
                                        message: "Please enter Customer Type",
                                    },
                                    ]
                                })(
        
                      // {/* <h4 style={{marginTop:"1%"}}>Filter By Status: </h4> */}
                      <Select
                        showSearch
                        style={{ width: "80%",marginLeft:"2%" }}
                        placeholder="Select"
                        optionFilterProp="children"
                        onChange={(e)=>this.onChange2(e)}
                        // onFocus={()=>this.onfocus2()}
                        allowClear={true}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.custType.map((c) => {
                            return (
                              <Option key={c} value={c}>
                                {c}
                              </Option>
                            );
                          })}
                        {/* <Option value="0">All</Option>
                        <Option value="1">Active Users</Option>
                        <Option value="2">Inactive Users</Option> */}

                      </Select>
                                )}
                                </FormItem>
                      {/* </div> */}
                      </Col>
                      <Col span={4} > 
        {/* <div style={{display:"flex", margin:"2%"}}> */}
        <FormItem label="Product Type:">
                                {getFieldDecorator(`filterProd`, {
                                    // initialValue: "All",

                                    rules: [{
                                        required: false,
                                        message: "Please enter Product Type",
                                    },
                                    ]
                                })(
        
                      // {/* <h4 style={{marginTop:"1%"}}>Filter By Status: </h4> */}
                      <Select
                        showSearch
                        style={{ width: "80%",marginLeft:"2%" }}
                        placeholder="Select"
                        optionFilterProp="children"
                        onChange={(e)=>this.onChange3(e)}
                        // onFocus={()=>this.onfocus3()}
                        allowClear={true}
                        // defaultValue={"All"}
                        // onFocus={onFocus}
                        // onBlur={onBlur}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.prodType.map((c) => {
                            return (
                              <Option key={c} value={c}>
                                {c}
                              </Option>
                            );
                          })}

                      </Select>
                                )}
                                </FormItem>
                      {/* </div> */}
                      </Col>
          
        </Row>


               </Form>

               <Modal
                    title="Comments"
                    centered
                      visible={this.state.templateModal}
                      onOk={()=>{this.setState({templateModal: false,templateDetails:[]})}}
                      onCancel={()=>{this.setState({templateModal: false,templateDetails:[]})}}
                      footer={null}
                      width={800}
                    >
                      {/* {console.log("In Modal1",this.state.templateModal)} */}
                      <Table 
                      columns={viewTemplates} bordered={true}
                      // rowClassName={(record, index) => index % 2 === 0 ? "table-row-light" : "table-row-dark"}
                    //  expandable={{expandedRowRender: record => <Row><Col><p style={{ margin: 0 }}>Name</p><p style={{ margin: 0,fontWeight: 'bold' }}>{record.name}</p></Col><Col offset={5}><p style={{ margin: 0, }}>Code</p><p style={{ margin: 0,fontWeight: 'bold' }}>{record.code}</p></Col><Col offset={5}><p style={{ margin: 0, }}>Phone</p><p style={{ margin: 0,fontWeight: 'bold' }}>{record.phone}</p></Col></Row>,rowExpandable: record => record.name !== 'Not Expandable',}} 
                    dataSource={this.state.templateDetails} 
                    pagination={false}
                    style={{margin:'2%'}} 
                    //  className='product' 
                    />

                </Modal>

             {/* {this.state.loaderP == false ?  */}
              {/* <Table dataSource={this.state.dataSource} 
              rowSelection={rowSelection}
              columns={columns}
              scroll={{ x: 2000 }}
              rowKey="key"  
              pagination={{ pageSize: 50 }}
               /> */}
                {!this.state.loading ?
               <Table 
               rowSelection={rowSelection} 
               columns={columns} dataSource={this.state.tableData} scroll={{ x: 2000 }} 
              //  pagination={{ pageSize: 100 }}
              // pagination={true}
              pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30','50','100']}}
              // pagination={this.state.pagination}
              // onChange={this.handleTableChange}
              // pagination={this.state.pagination} 
               />
           : <Spin style={{ height: 'max', width: '800px' }} /> } 
              </div>:
            
            <Wrapper header={"File List"}>
                <div>
                {this.state.showUserList &&
                    <Row>
                       <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                        </Col>
                        {this.state.showwhatsApp == true ? 
                        <>
                       <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <Link to={{
                                    pathname: '/customerwhatsapp',
                                    state: {
                                        data: this.state.tableData,
                                        customerwhatsappEmail:'whatsapp',
                                    }
                                }}>

                                <Button disabled={this.state.shownextButton?true:false} type="default" style={{marginLeft:'18px',marginTop:'40px', background:"#389e0d", color:"#fff" }} >
                                    Next
                                </Button>
                                </Link>
                    </Col>
                    </>
                    :
                    <>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <Link to={{
                                    pathname: '/customeremail',
                                    state: {
                                        data: this.state.tableData,
                                        customerwhatsappEmail:"email",
                                    }
                                }}>

                                <Button disabled={this.state.shownextButton?true:false} type="default" style={{marginLeft:'18px',marginTop:'40px', background:"#389e0d", color:"#fff" }} >
                                    Next
                                </Button>
                                </Link>
                    </Col>
                    </>
                  }
                    </Row>
                    }
                    {this.state.showemailcap&&
                    <>
                    {!this.state.spinLoading?
                    <Row>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:23}} lg={{span:23}}>
                        {/* <Link to="/customerwhatsapp"> */}
                        <Link to={{
                                    pathname: '/customerwhatsapp',
                                    state: {
                                        data: this.state.tableData,
                                        customerwhatsappEmail:'whatsapp',
                                    }
                                }}>

                        <Button type="default" 
                        style={{ marginRight: '1em', padding: '5px', marginBottom: 16, background:"#389e0d", color:"#fff" }}>
                        WhatsApp Campaign 
                        </Button>
                        </Link>
                    </Col> 
                    
                    <Col xs={{span:24}} sm={{span:24}} md={{span:1}} lg={{span:1}}>
                            {/* <Link to="/customerwhatsapp"> */}
                            <Link to={{
                                    pathname: '/customeremail',
                                    state: {
                                        data: this.state.tableData,
                                        customerwhatsappEmail:'email',
                                    }
                                }}>
                                <Button type="default"  style={{ marginBottom: 16, float: "right", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                                    Create Email Campaign
                                </Button>
                            </Link>
                    </Col>

                    </Row>:
                    <Spin style={{ height: 'max', width: '800px' }}/>
                    }
                    </>
                    }
                    
                </div>
                 
            </Wrapper>
           
                }
            </div>
        )
    }
}
// export default uploadFile
const WrappedTimeRelatedForm = Form.create({ name: 'time_related_controls' })(UploadFile);

export default withApollo(WrappedTimeRelatedForm)

