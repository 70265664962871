
export default {
    GRAPHQL_ENDPOINT:"https://ro3ud2dwszdhjhuflgb2w42sey.appsync-api.ap-south-1.amazonaws.com/graphql",
    API_KEY:"da2-ngmxz2akubbxjpse5j4esvnjfy",
    AUTHENTICATION_TYPE:"API_KEY",
    BUCKET:"btoc-ecom-prod",
    REGION:"ap-south-1",
    accessKeyId:"AKIA4AK2UPGZETSP4BZR",
    secretAccessKey:"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",
    userPoolId:"ap-south-1_I8mlGqoKw",
    userPoolWebClientId:"k5j6f51hbpfc67hivu03c195g",
    }



// devel
    // export default {
    //     GRAPHQL_ENDPOINT:"https://dzqi3p2cinesrhbp2ndcrisoxy.appsync-api.us-east-2.amazonaws.com/graphql",
    //     API_KEY:"da2-i455gzhbcnbwdhcsuoqnqjuypi",
    //     AUTHENTICATION_TYPE:"API_KEY",
    //     BUCKET:"btoc-ecom-prod",
    //     REGION:"us-east-2",
    //     accessKeyId:"AKIAXJJLX32ZYH3YTHLK",
    //     secretAccessKey:"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",
    //     userPoolId:"us-east-2_7bbEqvtWH",
    //     userPoolWebClientId:"60kdqht9k8sel4n5uc0uqvbvjc",
    //     }
    