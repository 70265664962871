import gql from 'graphql-tag';
export default gql`
mutation updateUser(
    $siteId: String!
  $uid: String!
  $name: String
  $mob: String
  $email: String
  $customerSince: String
  $whatsAppNumber: String
  $city: String
  $country: String
  $language: String
  $customerType: String
  $ProductType: String
  $sendingStatus: STATUS
  $enabled: Boolean
  $tempData: [TempDataInput]
  $url: String
  $BusinessName: String
  ){
    updateUser(input:{
      siteId:$siteId
      uid: $uid
      name: $name
      mob: $mob
      email: $email
      customerSince: $customerSince
      whatsAppNumber: $whatsAppNumber
      city: $city
      country: $country
      language: $language
      customerType: $customerType
      ProductType: $ProductType
      sendingStatus: $sendingStatus
      enabled: $enabled
      tempData: $tempData
      url: $url
      BusinessName: $BusinessName
    }){
      id
      uid
      siteId
      name
      mob
      email
      customerSince
      whatsAppNumber
      city
      country
      language
      customerType
      ProductType
      sendingStatus
      tempData{
        templateName
        templateId
        sentAt
      }
      url
      BusinessName
      createdAt
    }
  }
`  