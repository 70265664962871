import gql from "graphql-tag";

export default gql`
mutation deleteUser($siteId: String! $uid: String){
  deleteUser(siteId: $siteId uid: $uid){
    id
    siteId
    name
    uid
  }
}
`;