import React, { Component } from 'react'
import Wrapper from '../Wrapper'
import { graphql, compose, withApollo, Query } from 'react-apollo';
import { Table, Button, Breadcrumb, Row, Col, Tabs, Form, Input, Select, Divider, Icon, Upload, Popconfirm, Switch, Spin, Modal, message } from 'antd'
import Email from './EmailC'
import createSiteSetting from '../mutation/createSiteSetting'
import getSiteSetting from '../query/getSiteSetting'

import TextMessage from './textMessage'

const FormItem = Form.Item
const Option = Select.Option;
const { TabPane } = Tabs;
function callback(key) {
    console.log(key);
}


class setting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            settingData:{}
        }
    }




    componentDidMount = () => {
    var sortType = localStorage.getItem('siteId')
    this.props.client.query({
        query: getSiteSetting,
        fetchPolicy: 'network-only',
        variables: { sortType: sortType
        }
    })
        .then(({ data }) => {
        //   console.log("setting data::",data)
          this.setState({
            settingData: data.getSiteSetting
          })
        })
        .catch(err => {
            console.log(`Error ${JSON.stringify(err)}`)
        })

    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                var sortType = localStorage.getItem('siteId')
                console.log("val", values)
                // this.enterLoading()
                    this.props.client.mutate({
                        mutation: createSiteSetting,
                        variables:{
                        sortType: sortType,
                        siteId: "setting",
                        whatsAppNumber: values.mob,
                        token: values.Token,
                        accountId: values.aid,
                        productId: values.pid
                        }
                    }).then(({data})=>{
                        console.log("setting saved",data)
                            message.success("Setting saved successfully");
                        // if(data.sendCampaignEmailToUser){
                        //     this.setState({loading:false})
                        // }
                    }).catch(res=>{
                        console.log('Error',res)
                    })
            }
            else {
console.log("in error")
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Wrapper header={"Setting"}>
                <div>

                    <Tabs onChange={callback} type="card">
                        <TabPane tab="Whatsapp" key="1" >
                            <Form onSubmit={this.handleSubmit}>
                                <Row gutter={16}>
                                   
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                                        <FormItem
                                            label="Whatsapp Number"
                                        >
                                            {getFieldDecorator(`mob`, {
                                                initialValue:this.state.settingData!=null && this.state.settingData.whatsAppNumber!=null?this.state.settingData.whatsAppNumber:'',
                                                rules: [{
                                                    required: true,
                                                    message: "Please enter Mobile Number",
                                                },
                                                {
                                                    validator: (rule, value, cb) => {
                                                        if (value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 10)) {
                                                            cb('Please enter 10 digit number only')
                                                        }
                                                        cb()
                                                    }
                                                }
                                                ]
                                            })(
                                                <Input placeholder="Mobile Number" style={{ width: '100%' }} />
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                                        <FormItem
                                            label="Product ID"
                                        >
                                            {getFieldDecorator(`pid`, {
                                                initialValue:this.state.settingData!=null && this.state.settingData.productId!=null?this.state.settingData.productId:'',
                                                rules: [{
                                                    required: true,
                                                    message: "Please enter Product ID",
                                                }
                                                ]
                                            })(
                                                <Input placeholder="Product ID" style={{ width: '100%' }} />
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                                        <FormItem
                                            label="API Token"
                                        >
                                            {getFieldDecorator(`Token`, {
                                                initialValue:this.state.settingData!=null && this.state.settingData.token!=null?this.state.settingData.token:'',
                                                rules: [{
                                                    required: true,
                                                    message: "Please enter Token",
                                                }
                                                ]
                                            })(
                                                <Input placeholder="Token" style={{ width: '100%' }} />
                                            )}
                                        </FormItem>
                                    </Col>   
                                </Row>
                                <Row gutter={16}>
                                
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                                        <FormItem
                                            label="Account Id"
                                        >
                                            {getFieldDecorator(`aid`, {
                                                initialValue:this.state.settingData!=null && this.state.settingData.accountId!=null?this.state.settingData.accountId:'',
                                                rules: [{
                                                    required: true,
                                                    message: "Please enter Account Id",
                                                }
                                                ]
                                            })(
                                                <Input placeholder="Account Id" style={{ width: '100%' }} />
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                                        <FormItem>
                                            <Button type="primary" htmlType="submit">
                                                Submit
                                            </Button>
                                 <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%",marginLeft:"10px" }}  >Cancel</Button>
                                        </FormItem>
                                        </Col>
                                </Row>
                            </Form>
                        </TabPane>
                        <TabPane tab="Email" key="2" >
                            <Email/>
                        </TabPane>
                        {/* <TabPane tab="Text messages" key="3" >
                            <TextMessage/>
                        </TabPane> */}
                    </Tabs>
                </div>
            </Wrapper>
        )
    }
}
const WrappedTimeRelatedForm = Form.create({ name: 'time_related_controls' })(setting);
const WrappedTimeComp = compose(
    graphql(createSiteSetting,{
        name:'createSiteSetting'
    }))(WrappedTimeRelatedForm)

export default withApollo(WrappedTimeComp);
