import gql from 'graphql-tag';
export default gql`
mutation updateTemplete(
  $siteId: String!
	$uid: String!
	$displayName: String
	$subject: String
	$image: S3ObjectInput
	$content: String
  $createdAt: Int
  ){
    updateTemplete(input: {
      siteId: $siteId
    uid: $uid
    displayName: $displayName
    subject: $subject
    image: $image
    content: $content
    createdAt: $createdAt
    }){
      id
    siteId
    displayName
    subject
    createdAt
    }
  }
  # {
  #   "id": "5e7b4d0fe895d30007bcc4b0",
  #   "partionKey": "email",
  #   "templeteName": "newtem111",
  #   "subject": "dvb",
  # 	"content": "xb"
  # }`