import React from 'react'
import { Route, BrowserRouter as Router } from "react-router-dom";
import Setting from './component/setting'
import UploadList from './component/uploadFile'
import customer from './component/Customer'
import CreateCustomer from './component/createCustomer'
import EditCustomer from './component/editCustomer'
import CustomerEmail from './component/emailComp'
import CustomerWhatsApp from './component/whatsappComp'
import FileFunction from './component/fileFunction'
import CreateEmailTemp from './component/createEmailTemp'
import CreateWhatsappTemp from './component/CreatewhatsappTemp'
import WhatsappTempList from './component/whatsappTempList'
// import AddNewUser from './component/addUser.js'

const Routers =(props)=>(
    
    <Router >
        <React.Fragment>
            {/* <Route exact path="/" component={UploadList}/> */}
            <Route exact path="/setting" component={Setting}/>
            <Route exact path="/" component={customer}/>
            <Route exact path="/create_customer" component={CreateCustomer}/>
            <Route exact path="/edit_customer" component={EditCustomer}/>
            <Route exact path="/customeremail" component={CustomerEmail}/>
            <Route exact path="/customerwhatsapp" component={CustomerWhatsApp}/>
            <Route exact path="/file" component={FileFunction}/>
            <Route exact path="/CreateEmailTemp" component={CreateEmailTemp}/>
            <Route exact path="/CreateWhatsappTemp" component={CreateWhatsappTemp}/>
            <Route exact path="/whatsappTempList" component={WhatsappTempList}/>
            {/* <Route exact path="/addUser" component={AddNewUser}/> */}

            </React.Fragment>
    </Router>
);
export default Routers