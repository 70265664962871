import React, { Component } from 'react';
import {Button, Col, Form, Input, Row, Select, message, Spin} from 'antd'
import { Query, graphql, compose, withApollo } from 'react-apollo';
import FroalaEditor from './froalaEditor'
import Wrapper from '../Wrapper'
import UploadList from './uploadFile'
import sendEmailCampaignToUser from '../mutation/sendEmailCampaignToUser'
import getEmailTempletes from '../query/getEmailTempletes'
import createTemplete from '../mutation/createTemplete'
import getUserlistByCreatedAt from '../query/getUserlistByCreatedAt'
// import getFroalaSignature from '../query/getFroalaSignature'

const FormItem = Form.Item;

class CustomerEmail extends Component{
    constructor(props){
        super(props)
        this.state={
            modelNew: '',
            showComp:this.props.location && this.props.location.state && this.props.location.state.newlinkTable==true?true:false,
            subject:'',
            customerData:[],
            loading:false,
            enquiryData:[],
            tempInput:'',
            saveTemp:false,
            tempOptions:[],
            emailData:[],
            disabledInput:false,
            tempData1:[],
            error:false,
            keys:[],
            enqKey:[],
            check:false,
            selectVal:"",
            sdata:[],
            inputArr:[],
            key:null,
            dataSource:[],
            customerwhatsappEmail:'email',
            loading1:true,

        }
    }

   
    handleModelChange = modelNew => {
        this.setState({ modelNew: modelNew });
        // this.props.updateState(this.state.modelNew);
    };

    cUserList=(data)=>{
        let arr=[]
          if(data!=null){
            console.log("a",data)
            data.map((i,j)=>{
            console.log("a",i)
              let obj={
                          Name: i.name,
                          Mobile: i.mob,
                          Email: i.email,
                          customerSince: i.customerSince,
                          whatsAppNumber: i.whatsAppNumber,
                          city: i.city,
                          Country: i.country,
                          language: i.language,
                          customerType: i.customerType,
                          ProductType: i.ProductType, 
                          id:i.id,
                          siteId:i.siteId
              }
              arr.push(obj)
            })
          }
          console.log("arr",arr)
          this.setState({
            dataSource:arr,
            loading1:false
          })
      }

    componentDidMount(){
        var sortType = localStorage.getItem('siteId')

        // this.props.client.query({
        //     query: getUserlistByCreatedAt,
        //     fetchPolicy: 'network-only',
        //     variables: {siteId: sortType
        //     },
        // })
        //     .then(({ data }) => {
        //     //   console.log(".......",data)
        //         this.cUserList(data.getUserlistByCreatedAt);
        //     })
        //     .catch(err => {
        //         console.log(`Error ${JSON.stringify(err)}`)
        //     })
        

            // this.props.client.query({
            //     query: getEmailTempletes,
            //     fetchPolicy: 'network-only',
            //     variables: { inputParam: sortType
            //     },
            // })
            //     .then(({ data }) => {
            //     //   console.log(".......",data)
            //       this.saveTemplateList(data.getEmailTempletes)
            //     })
            //     .catch(err => {
            //         console.log(`Error ${JSON.stringify(err)}`)
            //     })
        


    }

    saveTemplateList=(data)=>{
        let arr=[]
        if(data!=null){
            data.map((i,j)=>{
                let obj={
                    value:i.templeteName,
                    label:i.templeteName,
                    content:i.content,
                    subject:i.subject
                }
                arr.push(obj)
            })
        }
        // console.log("data...........",arr)
        this.setState({
            tempData1:arr
        })

    }

    handleClick = ()=>{
        this.setState({showComp:true})
    }

    enterLoading = ()=>{
        this.setState({loading:true})
    }


    handleEmail = () =>{
        // console.log("in email",this.state.sdata)
        this.props.form.validateFields((err,values)=>{
          
            let userData= this.state.sdata
            // let enqData = this.state.enquiryData
                // return false
            var input=null
                var arrr=[]
            if(!err && userData.length != 0){
                for(let i=0 ;i<=userData.length-1; i++){
                    // this.enterLoading()
                    // this.props.client.mutate({
                    //     mutation: sendEmailCampaignToUser,
                    //     variables:{
                    //         email:userData[i].email,
                    //         subject:this.state.subject,
                    //         htmlText:`<p>Hello ${userData[i].fName},</p>`,
                    //     }
                    // }).then(({data})=>{
                    //     console.log("mail send",data)
                    //     // if(data.sendCampaignEmailToUser){
                    //     //     message.success("Mail has been sent successfully",this.message);
                    //     //     this.setState({loading:false})
                    //     // }
                    // }).catch(res=>{
                    //     console.log('Error',res)
                    // })
                    input={
                        mail:userData[i].Email,
                        subject:this.state.subject,
                        htmlText:`<p>Dear Mr. ${userData[i].Name},</p>${this.state.modelNew}`,
                    }
                    arrr.push(input);

                }
                console.log("input",arrr)
                this.enterLoading()
                            this.props.client.mutate({
                                mutation: sendEmailCampaignToUser,
                                variables: {
                                  input: arrr
                                    }
                               
                            }).then((data) => {
                                message.success('Mail has been sent successfully');
                                console.log("success",data)
                                this.setState({
                                    loading:false
                                })
                               
                                })
                
                   .catch(res=>{
                        console.log('Error',res)
                    })
            }else{
                console.log("else")
            }
        })
    }
        //     if(enqData.length != 0){
        //         for(let i=0 ;i<=enqData.length-1; i++){
        //              this.props.campaignEmail({
        //                 variables:{
        //                     email:enqData[i].email,
        //                     subject:this.state.subject,
        //                     htmlText:`<p>Hello ${enqData[i].name},</p>${this.state.modelNew}`,
        //                 }
        //             }).then(({data})=>{
        //                 if(data.sendCampaignEmailToUser){
        //                     message.success("Mail has been sent successfully",this.message);
        //                 }
        //             }).catch(res=>{
        //                 console.log('Error',res)
        //             })
        //         }
        //     }
        //     // return false
        // })
   

    handleCustomerData = (key,data)=>{
        this.setState({keys:key,sdata:data})
        console.log("selectered data",data,key)
    }

    handleSettingData = (data)=>{
        console.log("setting data wt",data)
        this.setState({settingdata:data.getSiteSetting})
    }

    handleEnquiryData = (key,data) =>{
        this.setState({enqKey:key,enquiryData:data})
    }

    previous = () =>{
        this.setState({showComp:false})
    }

    handleChange = (e)=>{
        console.log("e......",e.target.value)
        this.setState({subject:e.target.value})
    }

    handleSave = ()=>{
        this.setState({saveTemp:true})
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (!err) {

                console.log('Received values of form: ', values);
                // return
                this.enterLoading()
                try {
                    this.props.client.mutate({
                        mutation: createTemplete,
                        variables: {
                            partionKey:'email',
	                        templeteName:values.templateName,
	                        subject:this.state.subject,
	                        content:this.state.modelNew
                        }
                    }).then(({ data }) => {
                        console.log(data)
                        if (data.createTemplete != null) {
                            message.success(<span style={{fontSize:'20px'}}>Templete Created Successfully</span>);
                            this.setState({
                                loading: false,
                            })
                        }
                    })
                .catch(err => {
                console.log(err)
                 this.setState({
                  loading: false,
                 })
            });
                }
                catch (err) {
                console.log(err)
                    this.setState({
                        loading: false,
                    })
                }
            }

            else{
                console.log(err)
            }
        })
    }


    // handleTemp = (e)=>{
    //     this.setState({tempName:e.target.value})
    // }

    // saveTemplate = ()=>{
    //     this.props.form.validateFields((err,values)=>{
    //             if((this.state.check || this.state.selectVal == "" ) && this.state.tempOptions.includes(values.templateName)){
    //             this.props.form.setFields({
    //                 templateName:{
    //                     value:values.templateName,
    //                     errors:[new Error("Template name already exists.")]
    //                 }
    //             })
    //             return true
    //             }else{
    //                 this.props.form.setFields({
    //                     templateName:{
    //                         value:values.templateName,
    //                         errors:''
    //                     }   
    //                 })
    //             }
    //     // return false
    //             if(!err){
    //                 this.enterLoading()
    //                 this.props.campaignEmailTemplate({
    //                     variables:{
    //                         tempName:values.templateName,
    //                         tempSubject:this.state.subject,
    //                         text:this.state.modelNew,
    //                     }
    //                 }).then(({data})=>{
    //                     if(data.createEmailCampaignTemplate.id){
    //                         message.success("Template Saved");
    //                         this.getData()
    //                         this.setState({loading:false,error:false})
    //                     }
    //                 }).catch(res=>{
    //                     console.log('Error',res)
    //                 })
    //             }
    //     })
    // }

    // getData = async() =>{
    //     await this.props.client.query({
    //         query:getEmailTemplate,
    //         fetchPolicy:"network-only"
    //     }).then(({data})=>{
    //         let tempName = data.getEmailCampaignTemplates.map((val)=>val.tempName)
    //         this.setState({tempOptions:tempName,emailData:data.getEmailCampaignTemplates})
    //     })
    // }

    selectOption = (val)=>{
        console.log("val.....",val)
        this.setState({
            saveTemplateB:val
        })

        if(val=='none'){
            this.setState({
                subject:'',
                modelNew:''
            })
        }
        else{
            this.state.tempData1.length!=0 && this.state.tempData1.map((i,j)=>{
                        if(val==i.value){
                            console.log("if")
                            this.setState({
                                subject:i.subject,
                                modelNew:i.content
                            })
                        }
            })
        }
        // let tempData =[]
        // let fkey = this.state.key
        // if(val == 'none'){
        //     this.setState({subject:'',modelNew:'',saveTemp:false,tempInput:'',disabledInput:false,check:true})
        // }else{
        //     this.state.emailData.forEach((v)=>{
        //         if(v.tempName == val){
        //             tempData.push(v)
        //         }
        //     })
        //     this.setState({selectVal:val,check:false,subject:tempData[0].tempSubject,modelNew:tempData[0].text,saveTemp:true,tempInput:tempData[0].tempName,disabledInput:true})
        //     this.props.form.setFieldsValue({templateName:tempData[0].tempName})
        // }
    }



    render(){
    //   console.log("props data......kkk",this.props)
      var config = {
        key:process.env.froalaKey,
        height:240,
        imageUploadToS3:this.state.key,
        fileUploadToS3:this.state.key,
        toolbarButtons: {
            'moreText': {
              'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
            },
            'moreParagraph': {
              'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
            },
            'moreRich': {
              'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
            },
            'moreMisc': {
              'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help']
            }
          }
    };
    // var key = this.state.froal
        const { getFieldDecorator, getFieldValue } = this.props.form;
        if(this.state.showComp){
            return (
                <Wrapper header={"File List"} >
                <UploadList  keys={this.state.keys}  
                showComp={this.state.showComp} 
                loading={this.state.loading} 
                handleEmail={this.handleEmail} 
                previous={this.previous} 
                handleCustomerData={this.handleCustomerData} 
                handleSettingData={this.handleSettingData}
                enquiryData={this.handleEnquiryData}
                dataSource={this.state.dataSource}
                customerwhatsappEmail={this.state.customerwhatsappEmail}
                renderComp='email'
                />
                </Wrapper>
            )
        }else{
            return(
                <Form onSubmit={this.handleSubmit}>
                    <Wrapper header={"Email Camp"}>
                    <Row>
                        <Col span={8} >
                            <FormItem label="Template Name">
                            <Select 
                            placeholder="Select Template"
                            style={{ width: '70%' }}
                            onChange={this.selectOption}
                            >
                            {this.state.tempData1.length!=0 &&this.state.tempData1.map((c)=>{
                                return(
                                    <Select.Option key={c.value} value={c.value}>{c.label}</Select.Option>
                                )
                            })}
                            {/* <Select.Option value='none' key='opt2'>New</Select.Option> */}
                            </Select>
                            </FormItem>
                        </Col>
                        <Col span={1} offset={14}>
                            <Button style={{  marginTop:"40px", background:"#389e0d", color:"#fff"}} disabled={this.state.subject == "" || this.state.modelNew == "" ? true : false} onClick={this.handleClick}>Next</Button>
                        </Col>
                    </Row>
                    <Row>
                    {this.state.saveTemplateB=='none' && 
                     <Col span={8}>
                        <FormItem label="Enter Template Name">
                        {getFieldDecorator('templateName', {
                          validateTrigger: ['onChange', 'onBlur'],
                        //   initialValue:this.state.tempInput,
                           rules: [{
                               required: true,
                               message: "Please input Template Name ",
                           }],
                        })(
                            <Input  placeholder="Template Name"  style={{ width: '100%' }} />
                         )} 
                        </FormItem>
                    </Col>
                        }
                    </Row>
                    <Row>
                        <Col span={14} >
                            <FormItem label="Subject">
                                <Input  placeholder="Subject"  value={this.state.subject} onChange={this.handleChange} style={{ width: '100%' }} />
                            </FormItem>
                        </Col>
                       
                    </Row>
                    <Row>
                    <Col span={20}>
                   {/* { this.state.key != null ? */}
                        <FormItem label="Content:">
                        {getFieldDecorator('description', {
                          validateTrigger: ['onChange', 'onBlur']
                          // initialValue:"",
                          //  rules: [{
                          //     // whitespace:true
                          //      required: true,
                          //      message: "Please input Page Content ",
                          //  }],
                        })(
                            
                          <FroalaEditor
                             heightMin= "400px"
                            //  heightMax= "600px"
                            config={config}
                            onModelChange={this.handleModelChange}
                            model={this.state.modelNew}
                          />
                         
                        )}
                      </FormItem>
                   {/* : ""} */}
                      </Col>
                      </Row>
                    <Row>
                    
                      
                        {/* <Col span={8}>
                            <Button htmlType={'submit'} loading={this.state.loading} style={{ marginLeft:"8px", marginTop:"40px", background:"#389e0d", color:"#fff"}} disabled={this.state.saveTemplateB!='' && this.state.saveTemplateB=='none'? false : true} onClick={this.handleSave}>Save Template</Button>
                        </Col> */}
                    </Row>
                    </Wrapper>
                </Form>
            )
        }
    }
}
const wrapCustomerEmail = Form.create()(CustomerEmail)
const wrapEmail = compose(
    graphql(sendEmailCampaignToUser,{
        name:'sendEmailCampaignToUser'
    }))(wrapCustomerEmail)
//     graphql(CampaignEmailTemplate,{
//         name:'campaignEmailTemplate'
//     }),
// )(wrapCustomerEmail)
export default withApollo(wrapEmail)