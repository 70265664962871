import gql from 'graphql-tag';
export default gql`
mutation createSiteSetting(
  $sortType: String
	$siteId: String
	$whatsAppNumber: String
	$token: String
	$accountId: String
	$productId: String
){
  createSiteSetting(input: {
    sortType: $sortType
    siteId: $siteId
    whatsAppNumber: $whatsAppNumber
    token: $token
    accountId: $accountId
    productId: $productId
  }){
    sortType
    siteId
    whatsAppNumber
    token
    accountId
    productId
    createdAt
  }
}`
// {
//   "sortType": "8668378093",
//   "siteId": "setting",
//   "whatsAppNumber": "9623992734",
//   "token": "htch",
//   "accountId": "1420",
//   "productId": "jfgmh"
// }