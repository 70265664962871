import React from 'react'
import { Progress } from 'antd';
import { graphql, compose, withApollo, Query } from 'react-apollo';
import getTempUserlist from '../query/getTempUserlist'
import getSiteSetting from '../query/getSiteSetting'
import getWhatsAppSendingStatus from '../query/getWhatsAppSendingStatus'

class ProgrssModal extends React.Component{
    constructor(props){

        super(props)
        this.state={
            loading:false,
            count: this.props.data,
            percent:0,
            pendingData:0
        }
    }
    enterLoading(){
        this.setState({
          loading:true
        })
    }
    getStatus=(siteId)=>{
      return new Promise((rev,rej)=>{
        this.enterLoading()
        this.props.client.query({
          query: getWhatsAppSendingStatus,
          fetchPolicy: 'network-only',
          variables: { siteId: siteId
          }
      })
          .then(({ data }) => {
            rev(true)
            console.log("Temp User Statau::",data.getWhatsAppSendingStatus.status)

            this.setState({
              currentStatus: data.getWhatsAppSendingStatus.status,
              loading:false
            })
          })
          .catch(err => {
              console.log(`Error ${JSON.stringify(err)}`)
          })
        })
      }
      getCount=(siteId)=>{
        return new Promise((rev,rej)=>{
          this.enterLoading()
        this.props.client.query({
            query: getTempUserlist,
            fetchPolicy: 'network-only',
            variables: { siteId: siteId
            }
        })
            .then(({ data }) => {
              console.log("Temp User Data::",data.getTempUserlist.length)
              this.setState({
                pendingData: data.getTempUserlist.length,
                loading:false
                // loaderP: data.getTempUserlist.lenght == 0 ? false : true
              })
              rev(true)

            })
            .catch(err => {
                console.log(`Error ${JSON.stringify(err)}`)
            })
          })
      }
      calculationF=(i)=>{
        console.log("calc",i)
        return new Promise((res,rej)=>{
          let countSize = (100/this.state.count)
          let pp = this.state.percent
          pp = countSize + pp
          // let fpp = countSize*i
          console.log("PPPP",pp,this.state.percent)
          // setTimeout(()=>{
          this.setState({
            percent: pp
          },()=>{
          res(true)

          })

        // },9000)
      })
      }
     async componentDidMount(){
        var siteId = localStorage.getItem('siteId')
      let df=  await this.getStatus(siteId)
      let js =  await this.getCount(siteId)
      if(js== true){
        this.mainFunction()
      }
      }
     delay = ms => new Promise(res => setTimeout(res, ms));
    async mainFunction(){
        var siteId = localStorage.getItem('siteId')
        for(let i=0;i<=this.state.pendingData;i++){
          console.log("in for i",i,this.state.count)
            // if(this.state.pendingData != 0){
                // setTimeout(() => {
                   await this.getStatus(siteId)
                  let cc = await this.getCount(siteId)
                   
                 await this.calculationF(i)
                  await this.delay(9000)
                     
                  // }, 9000);
            //   }else{
            //     console.log("connection closed")
            // }

        }
          console.log("out from for loop")
    }

    render(){
        console.log("count::",this.state.count)
        return(
            <Progress type="circle" percent={this.state.percent.toFixed(0)} style={{ marginLeft: "90px"}} />
        )
    }
}
export default withApollo(ProgrssModal)