import React, { Component } from 'react';
import {Button, Col, Form, Input, Row, Select,Upload, message,Icon,Popconfirm,Table,Spin} from 'antd'
import { withRouter, Link } from 'react-router-dom';
import { Query, graphql, compose, withApollo } from 'react-apollo';
import FroalaEditor from './froalaEditor'
import moment from 'moment'
import Wrapper from '../Wrapper'
import UploadList from './uploadFile'
import sendEmailCampaignToUser from '../mutation/sendEmailCampaignToUser'
import getTempleteByCreatedAt from '../query/getTempleteByCreatedAt'
import createTemplete from '../mutation/createTemplete'
import getWhatsAppTemplet from '../query/getWhatsAppTemplet'
import getUserlistByCreatedAt from '../query/getUserlistByCreatedAt'
import updateTemplete from '../mutation/updateTemplete'
import deleteTemplete from '../mutation/deleteTemplate'
import slugify from "slugify";
// import getFroalaSignature from '../query/getFroalaSignature'

const FormItem = Form.Item;
const { TextArea } = Input;
const bucket ='btoc-ecom-prod'
const s3_Region = 'ap-south-1'

class whatsappTempList extends Component{
    constructor(props){
        super(props)
        this.state={
            img1:[],
            removedimage1:false,
            img:{},
            removedimage:false,
            modelNew: '',
            showComp:false,
            subject:'',
            siteId: '',
            uid: '',
            createdAt: '',
            customerData:[],
            loading:false,
            enquiryData:[],
            tempInput:'',
            saveTemp:false,
            tempOptions:[],
            emailData:[],
            disabledInput:false,
            tempData1:[],
            error:false,
            keys:[],
            enqKey:[],
            check:false,
            selectVal:"",
            sdata:[],
            inputArr:[],
            key:null,
            dataSource:[],
            customerwhatsappEmail:'',
            saveButton:false,
            updateButoon:false,
            updateimg:false
        }
    }

   
    handleModelChange = modelNew => {
        this.setState({ modelNew: modelNew });
        // this.props.updateState(this.state.modelNew);
    };


    componentDidMount(){
      
        var sortType = localStorage.getItem('siteId')  
        this.getTemplates()
            
    }

    getTemplates=()=>{
         var sortType = localStorage.getItem('siteId')       
                this.props.client.query({
                    query: getTempleteByCreatedAt,
                    fetchPolicy: 'no-cache',
                    variables: { siteId: `wTemplte::${sortType}`
                    },
                })
                    .then(({ data }) => {
                    //   console.log(".......",data)
                      this.saveTemplateList(data.getTempleteByCreatedAt.items)
                    })
                    .catch(err => {
                        console.log(`Error ${JSON.stringify(err)}`,err)
                    })
    }

    saveTemplateList=(data)=>{
        let arr=[]
        if(data!=null){
            data.map((i,j)=>{
                let obj={
                    value:i.displayName,
                    label:i.displayName,
                    content:i.content,
                    subject:i.subject,
                    id:i.id,
                    siteId: i.siteId,
                    uid: i.uid,
                    // partionKey:i.partionKey,
                    image:i.image,
                    createdAt: i.createdAt
                }
                arr.push(obj)
            })
        }
        // console.log("data...........",arr)
        this.setState({
            tempData1:arr
        })

    }

    handleClick = ()=>{
        this.setState({showComp:true})
    }

    enterLoading = ()=>{
        this.setState({loading:true})
    }


    handleEmail = () =>{
        console.log("in email",this.state.sdata)
        this.props.form.validateFields((err,values)=>{
          
            let userData= this.state.sdata
            // let enqData = this.state.enquiryData
                // return false
            var input=null
                var arrr=[]
            if(!err && userData.length != 0){
                for(let i=0 ;i<=userData.length-1; i++){
                    // this.enterLoading()
                    // this.props.client.mutate({
                    //     mutation: sendEmailCampaignToUser,
                    //     variables:{
                    //         email:userData[i].email,
                    //         subject:this.state.subject,
                    //         htmlText:`<p>Hello ${userData[i].fName},</p>`,
                    //     }
                    // }).then(({data})=>{
                    //     console.log("mail send",data)
                    //     // if(data.sendCampaignEmailToUser){
                    //     //     message.success("Mail has been sent successfully",this.message);
                    //     //     this.setState({loading:false})
                    //     // }
                    // }).catch(res=>{
                    //     console.log('Error',res)
                    // })
                    input={
                        mail:userData[i].Email,
                        subject:this.state.subject,
                        htmlText:`<p>Hello ${userData[i].Name},</p>${this.state.modelNew}`,
                    }
                    arrr.push(input);

                }
                // console.log("input",arrr)
                this.enterLoading()
                            this.props.client.mutate({
                                mutation: sendEmailCampaignToUser,
                                variables: {
                                  input: arrr
                                    }
                               
                            }).then((data) => {
                                message.success('Mail has been sent successfully');
                                // console.log("success",data)
                                this.setState({
                                    loading:false
                                })
                               
                                })
                
                   .catch(res=>{
                        console.log('Error',res)
                    })
            }else{
                console.log("else")
            }
        })
    }
   

    handleCustomerData = (key,data)=>{
        this.setState({keys:key,sdata:data})
        // console.log("selectered data",data,key)
    }

    handleEnquiryData = (key,data) =>{
        this.setState({enqKey:key,enquiryData:data})
    }

    previous = () =>{
        this.setState({showComp:false})
    }

    handleChange = (e)=>{
        // console.log("e......",e.target.value)
        this.setState({subject:e.target.value})
    }

    handleSave = ()=>{
        this.setState({saveTemp:true})
    }

    handleSubmit = (e) => {
        let img1={}
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {

            if (!err) {

                // console.log('Received values of form: ', values);
                // return
                this.enterLoading()
                console.log(Object.keys(this.state.img).length)

                if (Object.keys(this.state.img).length != 0 ) {
                    let slugIname = slugify(this.state.img.originFileObj.name, {
                        replacement: '-',
                        lower: true,
                      });

                    img1 = {
                      bucket: bucket,
                      region: s3_Region,
                      localUri: this.state.img.originFileObj,
                      key: `comcenter/whatsApp/${slugIname}`,
                      mimeType: "image/png"
                    };
                //   console.log('img',img1)
                  }
                  else{
                //   console.log('img......')

                  }
                //   return
      

                try {
                    var sortType = `wTemplte::${localStorage.getItem('siteId')}`      
                    // var uid =  values.templateName
                    let uid = slugify(values.templateName, {
                        replacement: '-',
                        lower: true,
                      });
                    // console.log("SortType",sortType,uid);
                    // return
                    this.props.client.mutate({
                        mutation: createTemplete,
                        variables: {
                            // partionKey:sortType,
                            siteId: sortType,
                            uid: uid,
	                        displayName:values.templateName,
	                        subject:this.state.subject,
                            content:this.state.modelNew,
                            image:Object.keys(this.state.img).length != 0?img1:undefined,
                        }
                    }).then(({ data }) => {
                        // console.log(data)
                        if (data.createTemplete != null) {

                            message.success(<span style={{fontSize:'20px'}}>Templete Created Successfully</span>);
                            this.props.history.push('/CreateWhatsappTemp');
                            // this.props.Form.resetFields();
                            this.setState({
                                loading: false,
                            },()=>{this.getTemplates()})
                        }
                    })
                .catch(err => {
                console.log(err)
                 this.setState({
                  loading: false,
                 })
            });
                }
                catch (err) {
                console.log(err)
                    this.setState({
                        loading: false,
                    })
                }
            }

            else{
                console.log(err)
            }
        })
    }


    

    selectOption = (val)=>{
        // console.log("val.....",val)
       

        this.setState({
            saveTemplateB:val
        })

        if(val=='none'){
            this.setState({
                subject:'',
                modelNew:'',
                id:'',
                partionKey:'',
                saveButton:true,
                updateButoon:false,
                updateimg:false,
                img1:[]

            })
        }
        else{
            this.state.tempData1.length!=0 && this.state.tempData1.map((i,j)=>{
                        if(val==i.value){
                            // console.log("if",i)

                            let img1 = i.image != null ? [{
                                key: i.image.key,
                                uid: i.image.key,
                                bucket: i.image.bucket,
                                region: i.image.region,
                                status: 'done',
                                url: `https://${i.image.bucket}.s3.${i.image.region}.amazonaws.com/${i.image.key}`
                            }]:[]
                            // console.log("img1",img1)
                  

                            this.setState({
                                img1:img1,
                                subject:i.subject,
                                modelNew:i.content,
                                id:i.id,
                                partionKey:i.partionKey,           
                            siteId: i.siteId,
                            uid: i.uid,
                                createdAt: i.createdAt,
                                updateButoon:true,
                                saveButton:false,
                                updateimg:true
                            })
                        }
            })
        }
       
    }
    contentChange=(e)=>{
        // console.log("e.target.value......",e.target.value)
        this.setState({
            modelNew:e.target.value
        })
    }




    enterLoading=()=>{
        this.setState({
            loading:true
        })
    }

    handleupdate=()=>{
        this.enterLoading()
        let img={}

        if(this.state.img1.length>0 && !this.state.img1[0].hasOwnProperty('key')){
            img = {
              bucket: bucket,
              region: s3_Region,
              localUri: this.state.img1[0].originFileObj,
              key: `comcenter/whatsApp/${this.state.img1[0].originFileObj.name}`,
              mimeType: "image/png"
            }
            // console.log('if',img)
           }
           else{
               if(this.state.img1.length!=0){
            img = {
              bucket: bucket,
              region: s3_Region,
              key:this.state.img1[0].key
            };
            // console.log('else',img)
        }
        else{
            img =null
            // console.log('else else',img)

        }

           }
        //    console.log("UpdateValues::",this.state.siteId,this.state.uid,this.state.saveTemplateB,this.state.createdAt,this.state.id,this.state.modelNew,img)
// return
        try {
            this.props.client.mutate({
                mutation: updateTemplete,
                variables: {
                    siteId: this.state.siteId,
                    uid: this.state.uid,
                    displayName: this.state.saveTemplateB,
                    createdAt: this.state.createdAt,
                    id:this.state.id,
                    // partionKey:this.state.partionKey,
                    templeteName:this.state.saveTemplateB,
                    subject:this.state.subject,
                    content:this.state.modelNew,
                    image:img
                }
            }).then(({ data }) => {
                // console.log(data)
                if (data.updateTemplete != null) {
                    message.success(<span style={{fontSize:'20px'}}>Template Updated Successfully </span>);
                    // this.props.form.resetFields();
                    // this.props.history.push('/CreateWhatsappTemp');

                    this.setState({
                        loading: false,
                    },()=>{this.getTemplates()})
                }
            })
                .catch(err => {
                    console.log(err)
                    this.setState({
                        loading: false,
                    })
                });
        }
        catch (err) {
            console.log(err)
            this.setState({
                loading: false,
            })
        }
    }

    handleDelete=(data)=>{
        
        // console.log("...",data)
        // this.enterLoading()
        
        // return false
        try {
            this.props.client.mutate({
                mutation: deleteTemplete,
                variables:{
                    siteId: data.siteId,
                    uid: data.uid,
                  }
            }).then(({ data }) => {
                // console.log(data)
                if (data.deleteTemplete != null) {
                    message.success(<span style={{fontSize:'20px'}}>Template Deleted Successfully </span>);
                    // this.props.history.push('/CreateWhatsappTemp');
                    this.setState({
                        loading: false,
                    },()=>{this.getTemplates()})
                }
            }).catch(err => {
                    console.log(err)
                    this.setState({
                        loading: false,
                    })
                });
        }
        catch (err) {
            console.log(err)
            this.setState({
                loading: false,
            })
        }
    }



    render(){
    //   console.log("props data......kkk",this.state.img1)
      

      const columns = [
    
          {
              title: 'Template Name',
              dataIndex:'value',
              key: 'value',
              width: "20%",
          },
          {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => a.createdAt - b.createdAt,
            render:(data)=>{
                var dateString = moment.unix(data).format("DD/MM/YYYY");
                return(
                  <span>
                     {data != null && <div>{dateString}</div>} 
                     {data == null && <div>-</div>}
                  </span>
                )
              },
            width: "10%",
         },
          {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            width: "40%",
            // ...this.getColumnSearchProps('Email'),
        },
          {
              title: 'Image',
              dataIndex: 'image',
              key: 'image',
              render:(data)=>{
                // console.log("Data..",data)
                return(
                    <>
                    {data != null ?
                     <img src={`https://s3.${data.region}.amazonaws.com/${data.bucket}/${data.key}`} 
                     style={{width:150,height:150,cursor:"pointer"}} />
                    :   "-"} 
                     </>
                    
                )
              },
          },
          //https://s3.ap-south-1.amazonaws.com/btoc-ecom-prod/comcenter/whatsApp/03.jpg
       
    {
          title: 'Action',
          key: 'action',
          width: "10%",
          render: (data) => {
            // console.log("data",data)
              return (
                  <div>
                      <span>

                      </span>
                      <span>
                          <Link to={{
                              pathname: '/CreateWhatsappTemp',
                              state: {
                                  data: [data],
                                  newlink:'first'
                              }
                          }}>

                              <Button icon="edit" type="primary" style={{ marginLeft: "5px", ursor: "pointer", }} />
                          </Link>
                      </span>
                      <span>
                          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(data)}>
                              <Button icon="delete" type="danger" style={{ marginLeft: "5px", ursor: "pointer", }} />
                          </Popconfirm>
                      </span>
                  </div>
              )
          }

      },
        
      ];


     
    // var key = this.state.froal
        const { getFieldDecorator, getFieldValue } = this.props.form;
            return(
                <Wrapper header={"WhatsApp Template List"}>
                <div>
                {/* <Link to={{ pathname: '/CreateWhatsappTemp',
                                // state: {
                                //     data: data,
                                //     newlink:'first'
                                // }
                            }}> */}
                 <span>
                          <Link to={{
                              pathname: '/CreateWhatsappTemp',
                              state: {
                                  data: null,
                                  newlink:'first'
                              }
                          }}>

                              <Button type="primary" style={{ marginLeft: "5px", ursor: "pointer",margin:"1%" }} > Create Template
                              </Button>
                          </Link>
                      </span>
               
                {/* <Link to={{
                     pathname: '/CreateWhatsappTemp',
                      state: {
                                    data: null,
                                    newlink:'first'
                                }
                }}>
                    <Button type="primary" 
                    style={{background:"#389e0d", color:"#fff",float:"right",margin:"10px"}} 
                    >
                    Create Template
                    </Button>
                    </Link> */}
                     {!this.state.loading ?
                        <Table
                            dataSource={this.state.tempData1}
                            columns={columns}
                            // scroll={{ y: 240 }}
                            scroll={{ x: 1450 }}
                            pagination={{ pageSize: 100 }}
                        // bordered={true}
                        />
                        : <Spin style={{ height: 'max', width: '800px' }} />}


                </div>
            </Wrapper>
            )
        
    }
}
const wrapwhatsappTempList = Form.create()(whatsappTempList)
const wrapTempList = compose(
    graphql(sendEmailCampaignToUser,{
        name:'sendEmailCampaignToUser'
    }))(wrapwhatsappTempList)
export default withApollo(wrapTempList)