import React, { Component } from 'react'
import Wrapper from '../Wrapper'
import moment from 'moment'
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { Auth } from "aws-amplify";
import { withRouter, Link } from 'react-router-dom';
import { graphql, compose, withApollo, Query } from 'react-apollo';
import getUserlistByCreatedAt from '../query/getUserlistByCreatedAt'
import deleteUser from '../mutation/deleteUserlist'
import updateUser from '../mutation/updateUser'
import { Table, Button, Breadcrumb, Row,Input, Col, Form, Icon, Upload, Popconfirm, Switch, Spin, Modal, message , Checkbox,Pagination} from 'antd'

// defaultPageSize: 10,
// showSizeChanger: true,
// pageSizeOptions: ['10', '20', '50','100']

let limits=100
let skips = 0

class Customer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            loading: false,
            visible: false,
            ViewBuyerData: {},
            img: {},
            removedimage: false,
            searchText: '',
            searchedColumn: '',
            siteId: "",
            selectedRecordsMob: [],
            selectedRowKeys: this.props.keys,
            templateDetails: [],
            templateModal: false,
            nextToken: '',
            custType1: [],
            prodType1: [],
            Language1: [],
            custType: [],
            prodType: [],
            Language: [],
            // pagination: {},
            pagination: { defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30','50','100']},
            // pagination: {
            //   current: 1,
            //   pageSize: 10
            // },
        }
        this.getData()
    }
    enterLoading(){
      this.setState({
        Loading:true
      })
  }
    getData = async()=>{
      await  Auth.currentUserInfo()
       .then((data) => {
        //  console.log("user data",data)
         localStorage.setItem('userName',data.username)
         localStorage.setItem('siteId',data.attributes['custom:siteId'])
        //  this.getuser()
        //  localStorage.setItem('role',data.attributes['custom:refId'])
          //  this.setState({
          //   siteId:data.username,
          //  })
       })
       .catch(err => console.log(err));
     }


     componentDidMount(){
      
      this.getuser('',50)

  }

    handleDelete = (d) => {
      // console.log("delect log",d.siteId,d.siteId,d)
      // return
      this.enterLoading()
      this.props.client.mutate({
        mutation: deleteUser,
        variables:{
          siteId: d.siteId,
          uid: d.uid,
        }
      }).then(({data})=>{
          message.success("User Deleted Successfully")
          // console.log("daata",data)
        if(data.deleteUser){
          let arr = this.state.dataSource;
          let i = arr.findIndex(x => x.id == d.id)
          // console.log("ii",i);
          arr.splice(i,1)
          this.setState({
              dataSource:arr,
              loading: false
          },()=>{this.getuser('',50)})
          // message.success("User deleted successfully")
          
        }
    })
    }

    handleUpdate = (values) => {
      
              console.log('Received values of form: ', values);
              let temp = [] , obj={}
                // console.log('Received values of form: ', values,this.state.data);
                if(values.tempData != null ){
                  values.tempData.map((j)=>{
                        // console.log("JJJJ",j);
                        obj={
                            sentAt: j.sentAt,
                            templateId: j.templateId,
                            templateName: j.templateName, 
                        }
                        // delete j.__typename
                        temp.push(obj);
                    })
                    
                    // console.log("Temp...:",temp);
                }
              // return
              this.enterLoading()
              try {
                  this.props.client.mutate({
                      mutation: updateUser,
                      variables: {
                          id:values.id,
                          siteId:values.siteId,
                          uid: values.uid,
                          name:values.name,
                          mob:values.mob,
                          email:values.email,
                          customerSince:values.date,
                          whatsAppNumber:values.whatsAppNumber,
                          city:values.city,
                          country:values.country,
                          language:values.language,
                          customerType:values.customerType,
                          ProductType:values.ProductType,
                          tempData: values.tempData,
                          url: values.url,
                          BusinessName: values.BusinessName,
                          enabled: !values.enabled,
                          tempData: values.temp
                      }
                  }).then(({ data }) => {
                      // console.log(data)
                      if (data.updateUser != null) {
                          message.success(<span style={{fontSize:'20px'}}>User Updated Successfully </span>, this.messageClose);

                          this.setState({
                              // loading: false,
                              dataSource: []
                          },()=>{this.getuser('',50)})
                      }
                  })
                      .catch(err => {
                          // console.log(err)
                          this.setState({
                              loading: false,
                          })
                      });
              }
              catch (err) {
                  // console.log(err)
                  this.setState({
                      loading: false,
                  })
              }
          
  }

    cUserList=(data)=>{
      let arr=[], custData = [] , prodData = [], language = [];

      // custData = this.state.custType1 ;
      // prodData = this.state.prodType1 ;
      // language = this.state.Language1;

      // custData1 = this.state.custType1 ;
      // prodData1 = this.state.prodType1 ;
      // language1 = this.state.Language1;
        try{
        if(data!=null){
          // console.log("a",data)
          data.map((i,j)=>{
          // console.log("a",i)
            let obj={
              Name: i.name,
              Mobile: i.mob,
              Email: i.email,
              customerSince: i.customerSince,
              whatsAppNumber: i.whatsAppNumber,
              city: i.city,
              Country: i.country,
              language: i.language,
              customerType: i.customerType,
              ProductType: i.ProductType,
              id: i.id,
              uid: i.uid,
              siteId: i.siteId,
              Url: i.url,
              BusinessName: i.BusinessName,
              enabled: i.enabled,
              tempData: i.tempData!=null ? i.tempData : null,
              tempName: i.tempData && i.tempData[0]  ? i.tempData[0].templateName : '',
              lastSent: i.tempData && i.tempData[0] ? i.tempData[0].sentAt : null,
            }
            arr.push(obj)

            if(!custData.includes(i.customerType)){
              // console.log("In If")
              if(i.customerType != null){
                custData.push(i.customerType);
              }
                // custData.push({text: i.customerType, value: i.customerType})
            }
            if(!prodData.includes(i.ProductType)){
              // console.log("In If")
              if(i.ProductType != null){
                prodData.push(i.ProductType)
              }
              // prodData.push({text: i.ProductType, value: i.ProductType,})
            }
            if(!language.includes(i.language)){
              // console.log("In If")
              if(i.language != null){
              language.push(i.language)
              }
              // language.push({text:i.language,value:i.language})
            }
          
          })
        }
      }catch(err){
        console.log("ERROR:::>>>",err);
      }
        // console.log("Final Array",custData,prodData,language);
        let temp1 = [], temp2 = [], temp3= []; 
       custData.map((j)=>{
          //  console.log("JJJ",j) 
           let obj={
             text: j,
             value: j
           }
           temp1.push(obj)
        })

         prodData.map((j)=>{
          // console.log("JJJ",j) 
          let obj={
            text: j,
            value: j
          }
          temp2.push(obj)
       })

       language.map((j)=>{
        // console.log("JJJ",j) 
        let obj={
          text: j,
          value: j
        }
        temp3.push(obj)
     })
        // console.log("arr",arr,temp1,temp2,temp3);
        // console.log("arr",arr);
        this.setState({
          dataSource: this.state.dataSource.concat(arr),
          loading:false,
          Language: temp3,
          prodType: temp2,
          custType: temp1,
        })
    }

    // cUserList1=(data)=>{
    //   let arr=[], custData = [] , prodData = [], language = [];

    //   custData = this.state.custType1 ;
    //   prodData = this.state.prodType1 ;
    //   language = this.state.Language1 ;
      
    //     if(data!=null){
    //       // console.log("a",data)
    //       data.map((i,j)=>{
    //       // console.log("a",i)
    //         let obj={
    //           Name: i.name,
    //           Mobile: i.mob,
    //           Email: i.email,
    //           customerSince: i.customerSince,
    //           whatsAppNumber: i.whatsAppNumber,
    //           city: i.city,
    //           Country: i.country,
    //           language: i.language,
    //           customerType: i.customerType,
    //           ProductType: i.ProductType,
    //           id: i.id,
    //           uid: i.uid,
    //           siteId: i.siteId,
    //           Url: i.url,
    //           BusinessName: i.BusinessName,
    //           enabled: i.enabled,
    //           tempData: i.tempData,
    //           tempName: i.tempData ? i.tempData[0].templateName : '',
    //           lastSent: i.tempData ? i.tempData[0].sentAt : null,
    //         }
    //         arr.push(obj)

    //         if(!custData.includes(i.customerType)){
    //           // console.log("In If")
    //           custData.push(i.customerType);
    //           // custData.push({text: i.customerType, value: i.customerType})
    //         }
    //         if(!prodData.includes(i.ProductType)){
    //           // console.log("In If")
    //           prodData.push(i.ProductType)
    //           // prodData.push({text: i.ProductType, value: i.ProductType,})
    //         }
    //         if(!language.includes(i.language)){
    //           // console.log("In If")
    //           language.push(i.language)
    //           // language.push({text:i.language,value:i.language})
    //         }
    //       })
    //     }

    //     let temp1 = [], temp2 = [], temp3= []; 
    //    custData.map((j)=>{
    //       //  console.log("JJJ",j) 
    //        let obj={
    //          text: j,
    //          value: j
    //        }
    //        temp1.push(obj)
    //     })

    //      prodData.map((j)=>{
    //       // console.log("JJJ",j) 
    //       let obj={
    //         text: j,
    //         value: j
    //       }
    //       temp2.push(obj)
    //    })

    //    language.map((j)=>{
    //     // console.log("JJJ",j) 
    //     let obj={
    //       text: j,
    //       value: j
    //     }
    //     temp3.push(obj)
    //  })

    //     // console.log("arr1",arr,temp1,temp2,temp3)

    //     this.setState({
    //       dataSource: this.state.dataSource.concat(arr),
    //       loading:false,
    //       Language: temp3,
    //       prodType: temp2,
    //       custType: temp1,
    //     })
    // }

   
getuser=(nt,limits)=>{
  var sortType = localStorage.getItem('siteId')
  let i = 0
  // this.enterLoading()
  this.setState({
    loading:true
  })

  this.props.client.query({
    query: getUserlistByCreatedAt,
    fetchPolicy: 'no-cache',
    variables: { siteId: sortType,nextToken: nt,limit: limits
    },
})
    .then(({ data }) => {
      // console.log(".......",data)
        this.cUserList(data.getUserlistByCreatedAt.items);
        let nextToken1 = data.getUserlistByCreatedAt.nextToken
        this.setState({
          nextToken: nextToken1,
          // dataSource: this.state.dataSource.concat(data.getUserlistByCreatedAt.items),
          // loading:false,
        })
      //  console.log("Token In State::",nextToken1);
       if(nextToken1 != null && nextToken1 != undefined && nextToken1 != ''){
        this.getuser(nextToken1,50)
       }
    })
    .catch(err => {
        // console.log(`Error ${JSON.stringify(err)}`)
    })

}

   
// getUserData=() =>{
//   console.log("nextToken",this.state.nextToken);
 
//   var sortType = localStorage.getItem('siteId')
//   console.log("nextToken",this.state.nextToken);

//   this.props.client.query({
//     query: getUserlistByCreatedAt,
//     fetchPolicy: 'no-cache',
//     variables: { siteId: sortType,nextToken: this.state.nextToken,limit: 50
//     },
// })
//     .then(({ data }) => {
//       // console.log(".......Pag Fetch",data.getUserlistByCreatedAt.items.length + this.state.dataSource.length);
//       console.log(".......Pag Fetch",data.getUserlistByCreatedAt.nextToken);
//       // const pagination = { ...this.state.pagination };
      
//       // pagination.total=data.getUserlistByCreatedAt.items.length + this.state.dataSource.length;
//         // console.log("pag total::",pagination.total)
//         this.cUserList1(data.getUserlistByCreatedAt.items);
//         this.setState({
//           nextToken: data.getUserlistByCreatedAt.nextToken,
//           // pagination,
//           // loading:true
//         })
//     })
//     .catch(err => {
//         // console.log(`Error ${JSON.stringify(err)}`)
//     })
  
// }


    getColumnSearchProps = dataIndex => ({

      
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          
          <div style={{ padding: 8 }}>
            {/* {console.log(":::",dataIndex)} */}
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          ),
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };

      onSelect = (data) => {
        // console.log("DATA:::",data);
        // return
        let selectedMob = this.state.selectedRecordsMob
        // let obj = { url };
        let index = selectedMob.findIndex((item) => item.uid == data.uid);
    
        // console.log("index", index,selectedMob);
        // obj.key = imgArr.length;
        if (index == -1) {
          // console.log("in if");
          selectedMob.push(data);
          //console.log("In Condition", imgArr);
        } else {
          // console.log("in else");
          //remove from array
          selectedMob.splice(index, 1);
        }
        // console.log("Selected..",selectedMob)
        this.setState({
          selectedRecordsMob: selectedMob,
        });
      };
  
      onSelectAll = () => {
  
        // this.setState({
        //   selectedRecordsMob: []
        // })
        // console.log("Imn SA..::",this.state.tableData);
        let selectedMob = this.state.dataSource;
  
        // console.log("Imn SA..::",selectedMob);
        // let obj = { url };
       if(this.state.selectedRecordsMob.length == this.state.dataSource.length){
        //  console.log("In IF :::")
        selectedMob = this.state.dataSource;
         this.setState({
          selectedRecordsMob: [],
        });
       }else{
        //  console.log("In Else..")
        this.setState({
          selectedRecordsMob: selectedMob
        })
       }
        // console.log("Selected..",selectedMob)
       
      };
  
      deleteBulk=()=>{
  
        
        const deleteData = this.state.selectedRecordsMob;
        // console.log("In Delete Bulk..",deleteData)
        // return
        for(let i = 0 ; i < deleteData.length;i++){
          // console.log("in for loop ",deleteData[i].slugUpn);
  
          // return
          try{
            this.props.client.mutate({
              mutation: deleteUser,
              variables:{
                siteId: deleteData[i].siteId,
                uid: deleteData[i].uid,
              }
            }).then(({data})=>{
                // message.success("User Deleted Successfully")
                // console.log("daata",data)
              if(data.deleteUser){
                this.deleteData( data.deleteUser.uid)
                // let arr = this.state.dataSource;
                // let i = arr.findIndex(x => x.uid == deleteData[i].uid)
                // // console.log("ii",i);
                // arr.splice(i,1)
                this.setState({
                    // dataSource:arr,
                    loading: false
                }
                // ,()=>{this.getuser()}
                )
                // message.success("User deleted successfully")
                
              }
          })
            }catch(err){
              console.log("In Err",err)
            }
             
           }
           message.success("Users Deleted Successfully")
           this.setState({selectedRecordsMob: []})
      }

      deleteData = (slugUpn) =>{
        let data = this.state.dataSource
        let i = data.findIndex((val)=>val.slugUpn == slugUpn )
         data.splice(i,1)
        this.setState({dataSource: data})
    }

    onSelectChange = (selectedRowKeys,data) => {
      // console.log('selectedRowKeys changed: ', selectedRowKeys,data);
      this.setState({ selectedRowKeys });
          this.props.handleCustomerData(selectedRowKeys,data)
        // this.props.handleSettingData(this.state.settingData)
    };

    onDetails=(data)=>{

      // console.log("::::",data);
      let arr = [], obj={}

      data.tempData && data.tempData.map((p)=>{
        arr.push(p)
        // console.log("....",p);
      })

      this.setState({
          templateDetails : arr,
          templateModal: true,
      })
    
    }

    handleTableChange = (pagination, filters, sorter) => {
      // skip = skip + limit 
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      pager.total=this.state.dataSource.length
      
    //  console.log('pager',pager.current,pager)
    //  this.setState({
    //    data: dataStatusNew
    //  })
    let temp = pager.total%10;
    // console.log(">>>>",pager.current,pager.total/10);

      if(pager.current == (pager.total/10)){

        // console.log("In If",pager.current,pager.total/10)
        // skips = skips + limits
        this.getUserData({
          results: pagination.pageSize,
          page: pagination.current,
        });
      }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {  selectedRowKeys } = this.state;
        const rowSelection = {
          selectedRowKeys,
          onChange: this.onSelectChange,
        };
        // const hasSelected = selectedRowKeys.length > 0;
        // console.log("NextToken::",this.state.nextToken);
        // console.log("prodData:: ",this.state.prodType);
        
        const columns = [
          {
            title: ()=>{
              return(
              <Checkbox  
              checked={this.state.selectedRecordsMob.length > 0 && this.state.selectedRecordsMob.length == this.state.dataSource.length}
                onChange={() => {this.onSelectAll()}}
                            style={{
                              marginLeft: "1%",
                              marginTop: "1%",
                              //background: "goldenRod",
                            }}
                          >
    
                          </Checkbox>
              )},
            // dataIndex: 'mainImage',
            width: '2%',
            render: (record)=>{
              // console.log("RECORD..::",record)
                return(
                  <>
                   <Checkbox
                            checked={
                              this.state.selectedRecordsMob.findIndex(
                                (item) =>
                                  item.uid == record.uid
                              ) != -1
                            }
                            onChange={() => {
                              this.onSelect(record);
                            }}
                            style={{
                              marginLeft: "1%",
                              marginTop: "1%",
                              //background: "goldenRod",
                            }}
                          ></Checkbox>
                  </>
                )
            }
        }, 
          {
            // title: 'Action',
            title: ()=>{
              return(
                <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Action</h3>
              )},
            key: 'action',
            width: "6%",
            render: (data) => {
              // console.log("data",data)
                return (
                    <div>
                        <span>

                        </span>
                        <span>
                            <Link to={{
                                pathname: '/edit_customer',
                                state: {
                                    data: data,
                                    newlink:'first'
                                }
                            }}>

                                <Button icon="edit" type="primary" size="small" style={{ marginLeft: "5px", ursor: "pointer", }} />
                            </Link>
                        </span>
                        <span>
                            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(data)}>
                                <Button icon="delete" type="danger" size="small" style={{ marginLeft: "5px", ursor: "pointer", }} />
                            </Popconfirm>
                        </span>
                    </div>
                )
            }

        },
        {
          // title: 'Status',
          title: ()=>{
            return(
              <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Status</h3>
            )},
            width: "4%",
          // dataIndex: 'operation',
          // width: '15%',
          render: (text, record) => {
            // console.log("::",record.enabled)
           let fd = record.enabled
             return(
            <Switch size="small"
            style={{ marginRight: '10px'}}
              
              onChange={() =>
                this.handleUpdate(record)
              }
              checked={record.enabled}
            />
          )
          }
        },
        {
          // title: 'Last Massage SentAt',
          title: ()=>{
            return(
              <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Last Message Date</h3>
            )},
          dataIndex: 'lastSent',
          render: (date) =>{
            var dateString = moment.unix(date).format("DD/MM/YYYY hh:mm a");
            return(
              <span>
                 {date != null && <div style={{fontSize:"11px",fontWeight:"normal"}}>{dateString}</div>} 
                 {date == null && <div>-</div>}
              </span>
            )
          }
          
        },{
          // title: 'Last Sent Template',
          title: ()=>{
            return(
              <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Last Message Template</h3>
            )},
          dataIndex: 'tempName',
          render: (attachedCat,record) => {
            // console.log(">>>:::",attachedCat,record);
            var words = attachedCat.split('-');
            for (var i = 0; i < words.length; i++) {
              var word = words[i];
              words[i] = word.charAt(0).toUpperCase() + word.slice(1);
            }
          return(
            <span style={{fontSize:"11px",fontWeight:"normal",cursor: "pointer"}} onClick={()=>{this.onDetails(record)}}>{words.join(' ')}</span>
          )
        
      }
        },
            {
                // title: 'Name',
                title: ()=>{
                  return(
                    <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Name</h3>
                  )},
                dataIndex:'Name',
                key: 'name',
                ...this.getColumnSearchProps('Name'),
            },
            {
              // title: 'Business Name',
              title: ()=>{
                return(
                  <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Business Name</h3>
                )},
              dataIndex: 'BusinessName',
              key: 'BusinessName',
              width: 180,
              ...this.getColumnSearchProps('BusinessName')
           },
            {
              // title: 'Email',
              title: ()=>{
                return(
                  <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Email</h3>
                )},
              dataIndex: 'Email',
              key: 'email',
              width: 175,
              ...this.getColumnSearchProps('Email'),
          },
            {
                // title: 'Mobile',
                title: ()=>{
                  return(
                    <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Mobile</h3>
                  )},
                dataIndex: 'Mobile',
                key: 'mob',
                width: 95,
                render:(data)=>{
                  // console.log("Data..",data)
                  return(
                    <h3  style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
                  )
                },
            },
            {
              // title: 'Customer Since',
              title: ()=>{
                return(
                  <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Customer Since</h3>
                )},
              dataIndex: 'customerSince',
              key: 'customerSince',
              render:(data)=>{
                // console.log("Data..",data)
                return(
                  <h3 style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
                )
              },
              // ...this.getColumnSearchProps('customerSince'),

          },
          {
              // title: 'Whats App Number',
              title: ()=>{
                return(
                  <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Whats App Number</h3>
                )},
              dataIndex: 'whatsAppNumber',
              key: 'whatsAppNumber',
              width: 95,
              render:(data)=>{
                // console.log("Data..",data)
                return(
                  <h3 style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
                )
              },
              ...this.getColumnSearchProps('whatsAppNumber'),
          },{
              // title: 'City',
              title: ()=>{
                return(
                  <h3  style={{fontSize:"13px",fontWeight:"normal"}}>City</h3>
                )},
              dataIndex: 'city',
              key: 'city',
              ...this.getColumnSearchProps('city')
          },{
              // title: 'Country',
              title: ()=>{
                return(
                  <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Country</h3>
                )},
              dataIndex: 'Country',
              key: 'Country',
              render:(data)=>{
                // console.log("Data..",data)
                return(
                  <h3 style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
                )
              },
              ...this.getColumnSearchProps('Country'),
          },{
              // title: 'Language',
              title: ()=>{
                return(
                  <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Language</h3>
                )},
              dataIndex: 'language',
              key: 'Language',
              filters: this.state.Language,
              onFilter: (value, record) => record.language.indexOf(value) === 0,
              // ...this.getColumnSearchProps('language')
              
          },{
            // title: 'Customer Type',
            title: ()=>{
              return(
                <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Customer Type</h3>
              )},
            dataIndex: 'customerType',
            key: 'customerType',
            filters: this.state.custType,
            onFilter: (value, record) => record.customerType.indexOf(value) === 0,
            // ...this.getColumnSearchProps('customerType')
        },{
              // title: 'Product Type',
              title: ()=>{
                // console.log("Iii::",record)
                return(
                  <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Product Type</h3>
                )},
              dataIndex: 'ProductType',
              key: 'ProductType',
              filters: this.state.prodType,
              // ...this.getColumnSearchProps('ProductType')
              onFilter: (value, record) => record.ProductType.indexOf(value) === 0,
          },
          
        {
          // title: 'Url',
          title: ()=>{
            return(
              <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Url</h3>
            )},
          dataIndex: 'Url',
          key: 'Url',
          render:(data)=>{
            // console.log("Data..",data)
            return(
              <h3 style={{fontSize:"11px",fontWeight:"normal"}}>{data}</h3>
            )
          },
          // ...this.getColumnSearchProps('Url')
      },
          
        ];

        const viewTemplates = [
          {
            // title: 'Last Massage SentAt',
            title: ()=>{
              return(
                <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Last Message Date</h3>
              )},
            // dataIndex: 'lastSent',
            render: (record) =>{
              // console.log("Date >>>:::",data);
              // var dateString = moment.unix(date).format("DD/MM/YYYY hh:mm a");
              return(
                <span style={{fontSize:"11px",fontWeight:"normal",cursor: "pointer"}}>{record.sentAt ? record.sentAt : "-"}</span>
                // <span>
                //    {date != null && <div style={{fontSize:"11px",fontWeight:"normal"}}>{dateString}</div>} 
                //    {date == null && <div>-</div>}
                // </span>
              )
            }
            
          },{
            // title: 'Last Sent Template',
            title: ()=>{
              return(
                <h3  style={{fontSize:"13px",fontWeight:"normal"}}>Last Message Template</h3>
              )},
            // dataIndex: 'tempName',
            render: (record) => {
              // console.log("Name >>>:::",record);
              // var words = attachedCat.split('-');
              // for (var i = 0; i < words.length; i++) {
              //   var word = words[i];
              //   words[i] = word.charAt(0).toUpperCase() + word.slice(1);
              // }
            return(
              <span style={{fontSize:"11px",fontWeight:"normal",cursor: "pointer"}}>{record.templateName}</span>
            )
          
        }
          },
        ]

        // console.log("The dataSource:::",this.state.dataSource);

        return (
            <Wrapper header={"Customer List"}>
                <div>
                {/* <Link to={{
                     pathname: '/create_customer',
                }}>
                    <Button type="primary" style={{marginLeft:'90%'}}>
                        Add Customer
                    </Button>
                    </Link> */}
                    {/* <div>
                     <Button type="primary" style={{cursor: "pointer", margin:"0.5%",marginLeft:"92%" }}
                          // onClick={()=>{this.setState({shareModal: true})}}
                          // onClick={()=>{this.deleteBulk()}}
                          // create_customer
                         >
                            Add Customer
                             </Button>
                             </div> */}
                          
                          <Link to={{
                              pathname: '/create_customer',
                              state: {
                                  data: null,
                                  newlink:'first'
                              }
                          }}>

                              <Button type="primary" style={{ marginLeft: "5px",margin:"1%" }} > 
                              Create New User
                              </Button>
                          </Link>

                          {/* <Button type="primary" style={{ marginLeft: "5px",margin:"1%" }} onClick={()=>{this.getUserData()}}> 
                               Next Token
                              </Button> */}
                    <Spin spinning={this.state.loading}>

                    {this.state.selectedRecordsMob.length > 0 ?
                      <Row> 
                        <Col>
                        <Popconfirm title="Sure to delete?" onConfirm={()=>{this.deleteBulk()}}>
                        <Button type="default" style={{ float: "right", cursor: "pointer", background:"#ff4d4f", color:"#fff",margin:"1%" }}
                          // onClick={()=>{this.setState({shareModal: true})}}
                          // onClick={()=>{this.deleteBulk()}}
                         >
                            Bulk Delete
                             </Button>
                             </Popconfirm>
                        </Col>
                        <Col>
                       <h4>{this.state.selectedRecordsMob.length} Selected</h4>
                       </Col>
                       </Row>
                      : null
                      }

                     {!this.state.loading ?
                     <>
                        <Table
                            dataSource={this.state.dataSource}
                            columns={columns}
                            // rowSelection={rowSelection} 
                            // scroll={{ y: 240 }}
                            // scroll={{ x: 1450 }}
                            scroll={{ x: 2000 }}
                            // pagination={{ pageSize: 100 }}
                            // pagination={false}
                            // pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30','50','100']}}
                            pagination={this.state.pagination}
                            onChange={this.handleTableChange}
                            // onChange={this.serviceTypeChange}
                            // onChange={()=>{this.getUserData()}}
                        // bordered={true}
                        />
                        {/* <Pagination
                        showSizeChanger
                        // onShowSizeChange={onShowSizeChange}
                        onChange={()=>{this.getUserData()}}
                        defaultCurrent={1}
                        total={this.state.dataSource.length}
                      /> */}
                      </>
                        : <Spin style={{ height: 'max', width: '800px' }} />}

                   </Spin>

        <Modal
        title="Comments"
        centered
          visible={this.state.templateModal}
          onOk={()=>{this.setState({templateModal: false,templateDetails:[]})}}
          onCancel={()=>{this.setState({templateModal: false,templateDetails:[]})}}
          footer={null}
          width={800}
        >
              <Table 
              columns={viewTemplates} bordered={true}
              // rowClassName={(record, index) => index % 2 === 0 ? "table-row-light" : "table-row-dark"}
            //  expandable={{expandedRowRender: record => <Row><Col><p style={{ margin: 0 }}>Name</p><p style={{ margin: 0,fontWeight: 'bold' }}>{record.name}</p></Col><Col offset={5}><p style={{ margin: 0, }}>Code</p><p style={{ margin: 0,fontWeight: 'bold' }}>{record.code}</p></Col><Col offset={5}><p style={{ margin: 0, }}>Phone</p><p style={{ margin: 0,fontWeight: 'bold' }}>{record.phone}</p></Col></Row>,rowExpandable: record => record.name !== 'Not Expandable',}} 
             dataSource={this.state.templateDetails} 
             pagination={false}
             style={{margin:'2%'}} 
            //  className='product' 
             />

        </Modal>
                </div>
            </Wrapper>
        )
    }
}
// export default uploadFile
const WrappedTimeRelatedForm = Form.create({ name: 'time_related_controls' })(Customer);

export default withApollo(WrappedTimeRelatedForm);

