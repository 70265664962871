import gql from 'graphql-tag';
export default gql`
query getWhatsAppTemplet($inputParam: String!){
  getWhatsAppTemplet(inputParam: $inputParam){
     id
     partionKey
     templeteName
     subject
     content
     image{
      bucket
      region
      key
    }
   }
 }
 `
 