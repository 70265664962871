import React, { Component } from 'react';
import {Button, Col, Form, Input, Row, Select, message,Upload,Icon,Modal} from 'antd'
import { Query, graphql, compose, withApollo } from 'react-apollo';
import axios from 'axios';
import https from 'https'
import Wrapper from '../Wrapper'
import UploadList from './uploadFile'
import ProgrssModal from './progressModal'
import sendWhatsAppCampaignToUser from '../mutation/sendWhatsAppCampaignToUser'
import createTempUserlist from '../mutation/createTempUserlist'
import updateUser from '../mutation/updateUser'
import getWhatsAppTemplet from '../query/getWhatsAppTemplet'
import createTemplete from '../mutation/createTemplete'
import getUserlistByCreatedAt from '../query/getUserlistByCreatedAt'
import getTempleteByCreatedAt from '../query/getTempleteByCreatedAt'
// import getFroalaSignature from '../query/getFroalaSignature'
const { TextArea } = Input;
const FormItem = Form.Item;

class CustomerWhatsApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      img: [],
      removedimage: false,
      modelNew: "",
      saveTemplateB: "",
      saveTemplateBName: "",
      showComp:
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.newlinkTable == true
          ? true
          : false,
      subject: "",
      customerData: [],
      loading: false,
      enquiryData: [],
      tempInput: "",
      saveTemp: false,
      tempOptions: [],
      emailData: [],
      disabledInput: false,
      tempData1: [],
      error: false,
      keys: [],
      enqKey: [],
      check: false,
      selectVal: "",
      sdata: [],
      inputArr: [],
      key: null,
      dataSource: [],
      customerwhatsappEmail: "app",
      settingdata: {},
      sendSucces: false,
      visible: false
    };
  }

  handleModelChange = (modelNew) => {
    this.setState({ modelNew: modelNew });
    // this.props.updateState(this.state.modelNew);
  };

  cUserList = (data) => {
    let arr = [];
    if (data != null) {
      // console.log("a",data)
      data.map((i, j) => {
        // console.log("a",i)
        let obj = {
          key: j,
          Name: i.name,
          Mobile: i.mob,
          Email: i.email,
          customerSince: i.customerSince,
          whatsAppNumber: i.whatsAppNumber,
          city: i.city,
          Country: i.country,
          language: i.language,
          customerType: i.customerType,
          ProductType: i.ProductType,
          id: i.id,
          siteId: i.siteId,
        };
        arr.push(obj);
      });
    }
    // console.log("arr", arr);
    this.setState({
      dataSource: arr,
    });
  };

  componentDidMount() {
    var sortType = localStorage.getItem("siteId");
    this.props.client
      .query({
        query: getTempleteByCreatedAt,
        fetchPolicy: "network-only",
        variables: { siteId: `wTemplte::${sortType}` },
      })
      .then(({ data }) => {
        // console.log(".......", data);
        this.saveTemplateList(data.getTempleteByCreatedAt.items);
      })
      .catch((err) => {
        console.log(`Error ${JSON.stringify(err)}`);
      });
  }

  saveTemplateList = (data) => {
    let arr = [];
    if (data != null) {
      data.map((i, j) => {
        let obj = {
          value: i.uid,
          label: i.displayName,
          content: i.content,
          image: i.image,
        };
        arr.push(obj);
      });
    }
    // console.log("data...........", arr);
    this.setState({
      tempData1: arr,
    });
  };

  handleClick = () => {
    this.setState({ showComp: true });
  };

  enterLoading = () => {
    this.setState({ loading: true });
  };

  sendDataTempTableMutate = (data) => {
    // console.log("data in temp", data.tempData.length);
    
  
    
    let tempArr = [] ,obj ={}, tempvar
   
    // return
    if(data.tempData != null && data.tempData.length != 0){

      data.tempData.map((p)=>{
        // console.log("In Map::",p);
        if(p.templateId != this.state.saveTemplateB){
          // console.log("In Map IF::",p);
          tempArr.push(p)
        }
        
      })
      obj={
          templateName:this.state.saveTemplateBName,
          templateId: this.state.saveTemplateB
        }
        tempArr.unshift(obj)
      // tempvar = data.tempData.find(q => q.templateId == this.props.tempId);
      // if(tempvar == undefined){
      //   // console.log("...",p,this.props.tempId)
      //   data.tempData.map((j)=>{
      //     tempArr.push(j)
      //   })
        
      // }
      //   obj={
      //     templateName:this.state.saveTemplateB,
      //     templateId: this.state.saveTemplateB
      //   }
      //   tempArr.unshift(obj)

    }else{

     obj={
      templateName:this.state.saveTemplateBName,
      templateId: this.state.saveTemplateB
    }
    tempArr.push(obj)
    }
    
    let temp = data.tempData && data.tempData.length >0 ? data.tempData.map((val)=>{delete val.__typename }) : obj
    // console.log("TempArray ::: ",tempArr);
    // return
    // return false
    
    return new Promise((resolve, err) => {
      this.props.client
        .mutate({
          mutation: updateUser,
          variables: {
            siteId:data.siteId,
            uid: data.uid,
            name: data.name,
            mob: data.mob,
            email: data.email,
            customerSince: data.customerSince,
            whatsAppNumber: data.whatsAppNumber,
            city: data.city,
            country: data.country,
            language: data.language,
            customerType: data.customerType,
            ProductType: data.ProductType,
            sendingStatus: "SENDING",
            enabled: data.enabled,
            tempData: tempArr,
            url: data.url,
            BusinessName: data.BusinessName,
          },
        })
        .then((resp) => {
          // console.log("success tem user", resp);

          resolve(true);
        })
        .catch(err=>{
            // console.log("In mutate cathc", err)
            resolve(false)
        })
      // this.props.history.push('/')
      // let rep = await this.restApi(sortType)
    });
  };
  // let resp = await this.restApi(sortType)

  //                         this.setState({
  //                             loading: false,
  //                             longLoading: false
  //                              })
  // }
  handleOk = () => {
    this.setState({
      visible: false,
    });
  }
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  }
  showModal = () => {
    // console.log("show modal")
    this.setState({
      visible: true,
      loading: false
    });
  };
  mainfucwhatsApp = async () => {
    // this.enterLoading()
    var siteId = localStorage.getItem("siteId");
    // console.log("template Data ::", this.state.saveTemplateB);
    // console.log("siteId ::", this.state.sdata);
    // return 

    // let arrr = this.handleEmail();
    let arrr = this.state.sdata

    if (arrr.length > 0) {
      for (var i = 0; i <= arrr.length - 1; i++) {
        let res = await this.sendDataTempTableMutate(arrr[i]);
        // console.log("mutate resp ::", res)
      }
      // console.log("Afetr mutate for ")
    //   return
      let rep = await this.restApi(siteId);
      // let rep = true

      if (rep == true) {
        message.success("Sending Whatsapp Started Successfully");
        this.showModal()
        // this.setState({
        //   loading: false
        // })
      } else {
        // message.success("Sending Whatsapp Started Successfully");
        console.log("Errror restApi");
      }
    } else {
        console.log("No user selected")
    }
  };

  handleEmail = () => {
    // console.log("in email", this.state.img);
    // return new Promise((resolve,reject)=>{
    let userData = this.state.sdata;
    var imglimk = this.state.img[0];
    var input = null;
    var arrr = [];
    for (let i = 0; i <= userData.length - 1; i++) {
      input = {
        mob: userData[i].whatsAppNumber,
        name: userData[i].name,
        text: `Dear ${userData[i].name},
  ${this.state.modelNew}`,
        imageLink:imglimk && imglimk.url ? imglimk.url : null,
        url: `https://api.maytapi.com/api/${this.state.settingdata.productId}/${this.state.settingdata.accountId}/sendMessage`,
        token: this.state.settingdata.token,
      };
      arrr.push(input);
    }
    // console.log("input", arrr);
    return arrr;
    // resolve(arrr)
    // this.sendDataTempTable(arrr)
    // reject(false)

    // })
  };
  restApi = (siteId) => {
    console.log("rest call", siteId);

    return new Promise((resolve, reject) => {
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });
      
      axios
        .get(`https://restapi.mysnaptrade.com/startProcess/${siteId}`)
        .then(function (response) {
          // console.log("Success:: ", response.data);
          resolve(true);
        })
        .catch(function (error) {
          // console.log("Error:: ", error);
          resolve(false);
        });
    });
  };

  handleCustomerData = (key, data) => {
    this.setState({ keys: key, sdata: data });
    // console.log("selectered data", data);
  };
  handleSettingData = (data) => {
    // console.log("setting data wt", data);
    this.setState({ settingdata: data.getSiteSetting });
  };
  handleEnquiryData = (key, data) => {
    this.setState({ enqKey: key, enquiryData: data });
  };

  previous = () => {
    this.setState({ showComp: false });
  };

  handleChange = (e) => {
    this.setState({ subject: e.target.value });
  };

  handleSave = () => {
    this.setState({ saveTemp: true });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log("Received values of form: ", values);
        // return
        this.enterLoading();
        try {
          this.props.client
            .mutate({
              mutation: createTemplete,
              variables: {
                partionKey: "whatsApp",
                templeteName: values.templateName,
                subject: undefined,
                content: this.state.modelNew,
              },
            })
            .then(({ data }) => {
              console.log(data);
              if (data.createTemplete != null) {
                message.success(
                  <span style={{ fontSize: "20px" }}>
                    Templete Created Successfully
                  </span>
                );
                this.setState({
                  loading: false,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                loading: false,
              });
            });
        } catch (err) {
          console.log(err);
          this.setState({
            loading: false,
          });
        }
      } else {
        console.log(err);
      }
    });
  };

  // handleTemp = (e)=>{
  //     this.setState({tempName:e.target.value})
  // }

  // saveTemplate = ()=>{
  //     this.props.form.validateFields((err,values)=>{
  //             if((this.state.check || this.state.selectVal == "" ) && this.state.tempOptions.includes(values.templateName)){
  //             this.props.form.setFields({
  //                 templateName:{
  //                     value:values.templateName,
  //                     errors:[new Error("Template name already exists.")]
  //                 }
  //             })
  //             return true
  //             }else{
  //                 this.props.form.setFields({
  //                     templateName:{
  //                         value:values.templateName,
  //                         errors:''
  //                     }
  //                 })
  //             }
  //     // return false
  //             if(!err){
  //                 this.enterLoading()
  //                 this.props.campaignEmailTemplate({
  //                     variables:{
  //                         tempName:values.templateName,
  //                         tempSubject:this.state.subject,
  //                         text:this.state.modelNew,
  //                     }
  //                 }).then(({data})=>{
  //                     if(data.createEmailCampaignTemplate.id){
  //                         message.success("Template Saved");
  //                         this.getData()
  //                         this.setState({loading:false,error:false})
  //                     }
  //                 }).catch(res=>{
  //                     console.log('Error',res)
  //                 })
  //             }
  //     })
  // }

  // getData = async() =>{
  //     await this.props.client.query({
  //         query:getEmailTemplate,
  //         fetchPolicy:"network-only"
  //     }).then(({data})=>{
  //         let tempName = data.getEmailCampaignTemplates.map((val)=>val.tempName)
  //         this.setState({tempOptions:tempName,emailData:data.getEmailCampaignTemplates})
  //     })
  // }

  selectOption = (val,lab) => {
    // console.log("val.....", val,lab.props.children);

    // return
    this.setState({
      saveTemplateB: val,
      saveTemplateBName: lab.props.children,
    });

    if (val == "none") {
      this.setState({
        subject: "",
        modelNew: "",
      });
    } else {
      this.state.tempData1.length != 0 &&
        this.state.tempData1.map((i, j) => {
          if (val == i.value) {
            // console.log("if", i);
            let img1 =
              i.image != null
                ? [
                    {
                      key: i.image.key,
                      uid: i.image.key,
                      bucket: i.image.bucket,
                      region: i.image.region,
                      status: "done",
                      url: `https://${i.image.bucket}.s3.${i.image.region}.amazonaws.com/${i.image.key}`,
                    },
                  ]
                : [];
            // console.log("img1", img1);

            this.setState({
              subject: i.subject,
              modelNew: i.content,
              img: img1,
              saveTemplateB: val,
             saveTemplateBName: lab.props.children,
            });
          }
        });
    }
    // let tempData =[]
    // let fkey = this.state.key
    // if(val == 'none'){
    //     this.setState({subject:'',modelNew:'',saveTemp:false,tempInput:'',disabledInput:false,check:true})
    // }else{
    //     this.state.emailData.forEach((v)=>{
    //         if(v.tempName == val){
    //             tempData.push(v)
    //         }
    //     })
    //     this.setState({selectVal:val,check:false,subject:tempData[0].tempSubject,modelNew:tempData[0].text,saveTemp:true,tempInput:tempData[0].tempName,disabledInput:true})
    //     this.props.form.setFieldsValue({templateName:tempData[0].tempName})
    // }
  };

  contentChange = (e) => {
    // console.log("e.target.value......", e.target.value);
    this.setState({
      modelNew: e.target.value,
    });
  };

  handleimage = ({ fileList }) => {
    // console.log("fileList:",fileList.length)
    if (fileList.length > 0) {
      this.setState({
        img: fileList,
        removedimage: false,
      });
    }
  };

  handleRemoveImg = ({ fileList }) => {
    // console.log('remove')
    this.setState({
      img: [],
      removedimage: true,
    });
  };

  render() {
    const { img } = this.state;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    // console.log("image link", this.state.img[0]);
    var config = {
      key: process.env.froalaKey,
      height: 240,
      imageUploadToS3: this.state.key,
      fileUploadToS3: this.state.key,
      toolbarButtons: {
        moreText: {
          buttons: [
            "bold",
            "italic",
            "underline",
            "strikeThrough",
            "subscript",
            "superscript",
            "fontFamily",
            "fontSize",
            "textColor",
            "backgroundColor",
            "inlineClass",
            "inlineStyle",
            "clearFormatting",
          ],
        },
        moreParagraph: {
          buttons: [
            "alignLeft",
            "alignCenter",
            "formatOLSimple",
            "alignRight",
            "alignJustify",
            "formatOL",
            "formatUL",
            "paragraphFormat",
            "paragraphStyle",
            "lineHeight",
            "outdent",
            "indent",
            "quote",
          ],
        },
        moreRich: {
          buttons: [
            "insertLink",
            "insertImage",
            "insertVideo",
            "insertTable",
            "emoticons",
            "fontAwesome",
            "specialCharacters",
            "embedly",
            "insertFile",
            "insertHR",
          ],
        },
        moreMisc: {
          buttons: [
            "undo",
            "redo",
            "fullscreen",
            "print",
            "getPDF",
            "spellChecker",
            "selectAll",
            "html",
            "help",
          ],
        },
      },
    };
    // var key = this.state.froal
    const { getFieldDecorator, getFieldValue } = this.props.form;
    if (this.state.showComp) {
      return (
        <Wrapper header={"File List"}>
          <UploadList
            tempId={this.state.saveTemplateB}
            keys={this.state.keys}
            showComp={this.state.showComp}
            loading={this.state.loading}
            handleEmail={this.mainfucwhatsApp}
            previous={this.previous}
            handleCustomerData={this.handleCustomerData}
            handleSettingData={this.handleSettingData}
            enquiryData={this.handleEnquiryData}
            // dataSource={this.state.dataSource}
            customerwhatsappEmail={this.state.customerwhatsappEmail}
            renderComp="app"
          />
          {/* <Modal
              title="Whatsapp Sending Process"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              width={"25%"}
              footer={false}
            >
        <ProgrssModal data={this.state.keys.length}/>
      </Modal> */}
        </Wrapper>
      );
    } else {
      return (
        <Form onSubmit={this.handleSubmit}>
          <Wrapper header={"Whatsapp Camp"}>
            <Row>
              <Col span={8}>
                <FormItem label="Template Name">
                {/* {getFieldDecorator("description", {
                    validateTrigger: ["onChange", "onBlur"],
                    // initialValue: this.state.saveTemplateB,
                    //  rules: [{
                    //     // whitespace:true
                    //      required: true,
                    //      message: "Please input Page Content ",
                    //  }],
                  })( */}
                  <Select
                    placeholder="Select Template"
                    style={{ width: "70%" }}
                    onChange={(value,label)=>this.selectOption(value,label)}
                    // onChange={(value,label)=>console.log("Value,Label",label)}
                  >
                    {this.state.tempData1.length != 0 &&
                      this.state.tempData1.map((c) => {
                        // console.log("In Map",c);
                        return (
                          <Select.Option key={c.value} value={c.value}>
                            {c.label}
                          </Select.Option>
                        );
                      })}
                    {/* <Select.Option value='none' key='opt2'>New</Select.Option> */}
                  </Select>
                  {/* )} */}
                </FormItem>
              </Col>
              <Col span={1} offset={14}>
                <Button
                  style={{
                    marginTop: "40px",
                    background: "#389e0d",
                    color: "#fff",
                  }}
                  disabled={this.state.modelNew == "" ? true : false}
                  onClick={this.handleClick}
                >
                  Next
                </Button>
              </Col>
            </Row>
            <Row>
              {this.state.saveTemplateB == "none" && (
                <Col span={8}>
                  <FormItem label="Enter Template Name">
                    {getFieldDecorator("templateName", {
                      validateTrigger: ["onChange", "onBlur"],
                      //   initialValue:this.state.tempInput,
                      rules: [
                        {
                          required: true,
                          message: "Please input Template Name ",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Template Name"
                        style={{ width: "100%" }}
                      />
                    )}
                  </FormItem>
                </Col>
              )}
            </Row>

            <Row>
              <Form.Item label="Image">
                {getFieldDecorator("img", {
                  initialValue: this.state.img,
                  rules: [
                    {
                      required: false,
                      message: "Please Upload Image",
                    },
                  ],
                })(
                  <Upload
                    listType="picture-card"
                    fileList={img}
                    onChange={this.handleimage}
                    onRemove={this.handleRemoveImg}
                  >
                    {this.state.img.length >= 1 ? null : uploadButton}
                  </Upload>
                )}
              </Form.Item>
            </Row>

            {/* <Row>
                        <Col span={14} >
                            <FormItem label="Subject">
                                <Input  placeholder="Subject"  value={this.state.subject} onChange={this.handleChange} style={{ width: '100%' }} />
                            </FormItem>
                        </Col>
                       
                    </Row> */}
            <Row>
              <Col span={20}>
                {/* { this.state.key != null ? */}
                <FormItem label="Content:">
                  {getFieldDecorator("description", {
                    validateTrigger: ["onChange", "onBlur"],
                    initialValue: this.state.modelNew,
                    //  rules: [{
                    //     // whitespace:true
                    //      required: true,
                    //      message: "Please input Page Content ",
                    //  }],
                  })(
                    //   <FroalaEditor
                    //      heightMin= "400px"
                    //     //  heightMax= "600px"
                    //     config={config}
                    //     onModelChange={this.handleModelChange}
                    //     model={this.state.modelNew}
                    //   />

                    <TextArea
                      style={{ width: "70%" }}
                      rows={20}
                      placeholder="Content"
                      onChange={this.contentChange}
                    />
                  )}
                </FormItem>
                {/* : ""} */}
              </Col>
            </Row>
            <Row>
              {/* <Col span={8}>
                            <Button htmlType={'submit'} loading={this.state.loading} style={{ marginLeft:"8px", marginTop:"40px", background:"#389e0d", color:"#fff"}} disabled={this.state.saveTemplateB!='' && this.state.saveTemplateB=='none'? false : true} onClick={this.handleSave}>Save Template</Button>
                        </Col> */}
            </Row>
            
          </Wrapper>
        </Form>
       
      );
    }
  }
}
const wrapCustomerWhatsApp = Form.create()(CustomerWhatsApp)
const wrapWhatsApp = compose(
    graphql(sendWhatsAppCampaignToUser,{
        name:'sendWhatsAppCampaignToUser'
    }))(wrapCustomerWhatsApp)
//     graphql(CampaignEmailTemplate,{
//         name:'campaignEmailTemplate'
//     }),
// )(wrapCustomerWhatsApp)
export default withApollo(wrapWhatsApp)