import React, { Component } from 'react'
import Wrapper from '../Wrapper'
import { withRouter, Link } from 'react-router-dom';
import ReactFileReader from 'react-file-reader';
import PapaParse from 'papaparse';
import createUserlist from '../mutation/createUserlist'
import updateUser from '../mutation/updateUser'
import getUserlistByCreatedAt from '../query/getUserlistByCreatedAt'
import Highlighter from 'react-highlight-words';
import { graphql, compose, withApollo, Query } from 'react-apollo';
import { Table, Button, Card, Row, Col, Form, Icon, Upload, Popconfirm, Input, Switch, Select, Spin, Modal, message,Divider } from 'antd'
const FormItem = Form.Item;
const Option = Select.Option;

class fileFunction extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            dataSource1: [],
            loading: false,
            visible: false,
            ViewBuyerData: {},
            uploadresult: [],
            uploadedFileName: '',
            fileExtensionError: false,
            csvFileError: false,
            uploading: false,
            emptyCSV: '',
            download1: false,
            longLoading: false,
            listname: '',
            showUserList: false,
            userListData: [],
            selectData: '',
            showtable: false,
            
            nextToken: '',
            bulkUpdateErr: false,
            uploadresult1: [],
            uploadedFileName1: '',
            fileError1: false,
            fileExtensionError1: false,
        }
    }

    componentDidMount(){
      
        this.getuser('',50)
  
    }

    getuser=(nt,limits)=>{
        var sortType = localStorage.getItem('siteId')
        let i = 0
        // this.enterLoading()
        this.setState({
          loading:true
        })
      
        this.props.client.query({
          query: getUserlistByCreatedAt,
          fetchPolicy: 'no-cache',
          variables: { siteId: sortType,nextToken: nt,limit: limits
          },
      })
          .then(({ data }) => {
            // console.log(".......",data)
              this.cUserList(data.getUserlistByCreatedAt.items);
              let nextToken1 = data.getUserlistByCreatedAt.nextToken
              this.setState({
                nextToken: nextToken1,
                // dataSource: this.state.dataSource.concat(data.getUserlistByCreatedAt.items),
                // loading:false,
              })
            //  console.log("Token In State::",nextToken1);
             if(nextToken1 != null && nextToken1 != undefined && nextToken1 != ''){
              this.getuser(nextToken1,50)
             }
          })
          .catch(err => {
              // console.log(`Error ${JSON.stringify(err)}`)
          })
      
      }

    cUserList=(data)=>{
        let arr=[]
          if(data!=null){
            // console.log("a",data)
            try{
            data.map((i,j)=>{
            // console.log("a",i)
              let obj={
                        //   key:j+1,
                          Id: i.uid,
                          Name: i.name,
                          Mobile: i.mob,
                          Email: i.email,
                          customerSince: i.customerSince,
                          whatsAppNumber: i.whatsAppNumber,
                          City: i.city,
                          Country: i.country,
                          Language: i.language,
                          customerType:i.customerType,
                          ProductType: i.ProductType,
                          Url: i.url,
                          BusinessName: i.BusinessName,
                          Status : i.enabled == true ? "Active" : "Inactive"
              }
              arr.push(obj)
            })
          }catch(err){
            // console.log(">>>",err)
        }
        //   console.log("arr",arr)
          this.setState({
            userListData: this.state.userListData.concat(arr),
            // tableData: this.state.tableData.concat(arr),
            spinLoading:false
          })
        }
      }


      cUserList1=(data)=>{
        let arr=[]
          if(data!=null){
            // console.log("a",data)
            data.map((i,j)=>{
            // console.log("a",i)
              let obj={
                        //   key:j+1,
                          Name: i.name,
                          Mobile: i.mob,
                          Email: i.email,
                          customerSince: i.customerSince,
                          whatsAppNumber: i.whatsAppNumber,
                          City: i.city,
                          Country: i.country,
                          Language: i.language,
                          customerType:i.customerType,
                          ProductType: i.ProductType,
                          Url: i.url,
                          BusinessName: i.BusinessName,
                          Status : i.enabled == true ? "Active" : "Inactive"
              }
              arr.push(obj)
            })
          }
          // console.log("arr",arr)
          this.setState({
            userListData: this.state.userListData.concat(arr),
            tableData:arr,
            spinLoading:false
          })
      }

      getUserData=(params = {}) =>{
  
        var sortType = localStorage.getItem('siteId')
        // console.log("nextToken",this.state.nextToken);
      
        this.props.client.query({
          query: getUserlistByCreatedAt,
          fetchPolicy: 'no-cache',
          variables: { siteId: sortType,nextToken: this.state.nextToken,limit: 50
          },
        })
          .then(({ data }) => {
            // console.log(".......Pag Fetch",data.getUserlistByCreatedAt.items.length + this.state.dataSource.length);
            // console.log(".......Pag Fetch",data);
            // const pagination = { ...this.state.pagination };
            
            // pagination.total=data.getUserlistByCreatedAt.items.length + this.state.dataSource.length;
              // console.log("pag total::",pagination.total)
              this.cUserList1(data.getUserlistByCreatedAt.items);
              this.setState({
                nextToken: data.getUserlistByCreatedAt.nextToken,
                // pagination,
                // loading:true
              })
          })
          .catch(err => {
              // console.log(`Error ${JSON.stringify(err)}`)
          })
      
      }

    downloadTemplate1 = () => {
        var data = {
            fields: ["Id","Name", "Mobile", "Email","customerSince","whatsAppNumber","City","Country","Language","customerType","ProductType","Url","BusinessName","Status"],
            data: this.state.userListData.length > 0 ? this.state.userListData : []
        }
        var csv = PapaParse.unparse(data);
        // console.log("ajay",csv);
        // return
        this.setState({
            emptyCSV: csv,
            download1: true
        }, () => {

            var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            var csvURL = null;

            csvURL = window.URL.createObjectURL(csvData);

            var tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', 'UserList.csv');
            tempLink.click();
            // document.getElementById('down1').click()
        })

    }

    downloadTemplate = () => {
        var data = {
            fields: ["Name", "Mobile", "Email","customerSince","whatsAppNumber","City","Country","Language","customerType","ProductType","Url","BusinessName","Status"],
            data: []
        }
        var csv = PapaParse.unparse(data);
        // console.log("ajay",csv);
        this.setState({
            emptyCSV: csv,
            download1: true
        }, () => {

            var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            var csvURL = null;

            csvURL = window.URL.createObjectURL(csvData);

            var tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', 'Blank Template.csv');
            tempLink.click();
            // document.getElementById('down1').click()
        })

    }

    enterLoading() {
        this.setState({
            longLoading: true
        })
    }

    saveCustomerData = () => {
        let a=[]
        // console.log("this.state.dataSource",this.state.dataSource)
        // return
            this.enterLoading()
            // let arrr = []
            // this.state.dataSource != null && this.state.dataSource.map((i, j) => {
            //     let obj = {
            //         name: i.Name,
            //         mob: i.Mobile,
            //         email: i.Email,
            //         customerSince: i.customerSince,
	        //         whatsAppNumber: i.whatsAppNumber,
	        //         city: i.City,
	        //         country: i.Country,
	        //         language: i.Language,
	        //         customerType: i.CustomerType,
	        //         ProductType: i.ProductType
            //     }
            //     arrr.push(obj)
            // })
            // console.log("i", arrr, this.state.listname)
            // return;
            this.state.dataSource != null && this.state.dataSource.map((i, j) => {
                // console.log("i....",i)
                // return
            try {
        var sortType = localStorage.getItem('siteId')       
                this.props.client.mutate({
                    mutation: createUserlist,
                    variables: {
                        siteId: sortType,
                        name: i.Name,
                        mob: i.Mobile,
                        email: i.Email,
                        customerSince: i.customerSince,
                        whatsAppNumber: i.whatsAppNumber,
                        city: i.City,
                        country: i.Country,
                        language: i.Language,
                        customerType: i.customerType,
                        ProductType: i.ProductType,
                        sendingStatus: "NEW",
                        enabled: i.Status == "Active" ? true : false ,
                        url: i.Url,
                        BusinessName: i.BusinessName,
                        // Status: i.enabled == "Active" ? true : false
                    }
                }).then(({ data }) => {
                    // console.log("CreateUserlist",data)
                    a.push(data.createUserlist)
                    if (a.length == this.state.dataSource.length) {
                        // console.log("arr", a, a.length, this.state.dataSource, this.state.dataSource.length)
                        message.success(<span style={{ fontSize: '20px' }}>User Created Successfully</span>);
                        this.setState({
                            longLoading: false,
                            uploadedFileName:''
                        })
                    }
                    if (data.createUserlist != null) {
                        
                        this.setState({
                            loading: false,
                            // longLoading: false
                        })
                    }
                })
                    .catch(err => {
                        console.log(err)
                        this.setState({
                            loading: false,
                            longLoading: false
                        })
                    });
            }
            catch (err) {
                console.log(err)
                this.setState({
                    loading: false,
                    longLoading: false
                })
            }
        })
        // console.log("arr",a,a.length,this.state.dataSource,this.state.dataSource.length)

        // if(this.state.dataSource!=null && a==true){
        //     message.success(<span style={{ fontSize: '20px' }}>User Created Successfully</span>);
        //     this.setState({
        //         longLoading: false
        //     })
        // }
    }

    saveCustomerData1 = () => {
        let a=[], array=[], obj={} ,tempArr=[], tempobj={}
        // console.log("this.state.dataSource",this.state.dataSource1)
            
            // console.log("In Submit",this.state.dataSource);

            for(let i = 0 ; i < this.state.dataSource.length ; i++){
                // console.log("In For ::",this.state.dataSource[i])

                this.state.dataSource[i].tempData != null && this.state.dataSource[i].tempData.map((s)=>{
                    // console.log("DFrfv",s)
                    tempobj={
                      sentAt: s.sentAt,
                      templateId: s.templateId,
                      templateName: s.templateName,  

                    }
                    tempArr.push(tempobj)
                })

                this.state.dataSource1.map((p)=>{
                    // console.log("pppp",p)
                    if(this.state.dataSource[i].uid == p.Id && this.state.dataSource[i].Name == p.Name){
                        // console.log("In If TRUE ::")
                        obj={
                        siteId: this.state.dataSource[i].siteId,
                        uid: this.state.dataSource[i].uid,
                        id: this.state.dataSource[i].id,
                        tempData: tempArr.length > 0 ? tempArr : null,
                        name: p.Name,
                        mob: p.Mobile,
                        email: p.Email,
                        customerSince: p.customerSince,
                        whatsAppNumber: p.whatsAppNumber,
                        city: p.City,
                        country: p.Country,
                        language: p.Language,
                        customerType: p.customerType,
                        ProductType: p.ProductType,
                        sendingStatus: this.state.dataSource[i].sendingStatus,
                        enabled: p.Status == "Active" ? true : false ,
                        url: p.Url,
                        BusinessName: p.BusinessName,
                        
                        }
                        array.push(obj)
                    }else{
                        // console.log("In ELSE TRUE ::")
                        // this.setState({
                        //     bulkUpdateErr: true
                        // })
                    }
                })

            }
            // console.log("Final Array :::....",array.length,this.state.dataSource.length);

            //  return
            if(array.length <= this.state.dataSource.length){

                // console.log("Final If True");
            this.enterLoading()
           
            array != null && array.map((i, j) => {
                // console.log("i....",i)
               
            try {
        var sortType = localStorage.getItem('siteId')       
        this.props.client.mutate({
            mutation: updateUser,
            variables: {
                id: i.id,
                siteId: i.siteId,
                uid: i.uid,
                name: i.name,
                mob: i.mob,
                email: i.email,
                customerSince: i.customerSince,
                whatsAppNumber: i.whatsAppNumber,
                city: i.city,
                country: i.country,
                language: i.language,
                customerType: i.customerType,
                ProductType: i.ProductType,
                tempData: i.tempData,
                url: i.url,
                BusinessName: i.BusinessName,
                enabled: i.enabled,
            }
        }).then(({ data }) => {
                    // console.log("updateUserlist",data)
                    // a.push(data.updateUser)
                    
                    if (data.updateUser != null) {
                        // message.success(<span style={{ fontSize: '20px' }}>updated Successfully</span>);
                        a++;
                        this.setState({
                            loading: false,
                            uploadedFileName1: ''
                            // longLoading: false
                        })
                    }
                })
                    .catch(err => {
                        console.log(err)
                        this.setState({
                            loading: false,
                            longLoading: false
                        })
                    });
            }
            catch (err) {
                console.log(err)
                this.setState({
                    loading: false,
                    longLoading: false
                })
            }
        })
        // console.log("arr", a, array.length)
        if (a == array.length) {
                        // console.log("arr", a.length, array.length)
                        message.success(<span style={{ fontSize: '20px' }}>updated Successfully</span>);
                        this.setState({
                            longLoading: false
                        })
        }
    }else{
         this.setState({
         bulkUpdateErr: false
        })
     }
    }

    handleSubmit = () => {
        var self = this;
        // console.log('handleSubmit ::', self)
        if (this.state.uploadresult.length < 1) {
            this.setState({ fileError: true })
            return;
        }
        this.setState({
            uploading: false
        })

        this.parseFile(self, this.state.uploadresult)
    }
    getdata = (data) => {
        var element = data.data
        // var sortarr = element.splice(-1,1)
        // console.log("data in get::", data.data)

        this.setState({
            dataSource: data.data,
            saveData: true,
            // showtable: true
        },()=>{this.saveCustomerData()})
    }
    parseFile = (self, data) => {
        // console.log('In parseFile ::',data)

        var obj = PapaParse.parse(data,
            {
                delimiter: "",	// auto-detect
                newline: "",	// auto-detect
                quoteChar: '"',
                escapeChar: '"',
                header: true,
                transformHeader: undefined,
                dynamicTyping: false,
                preview: 0,
                encoding: "",
                worker: false,
                comments: false,
                step: undefined,
                complete: undefined,
                error: undefined,
                download: false,
                downloadRequestHeaders: undefined,
                downloadRequestBody: undefined,
                skipEmptyLines: false,
                chunk: undefined,
                fastMode: undefined,
                beforeFirstChunk: undefined,
                withCredentials: undefined,
                transform: undefined,
                // delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
                complete: function ({ data, err, meta }) {
                    if (err) {
                        // console.log("Parsing err:", err);
                    } else {
                        // console.log("Parsing complete:", data, self);
                        //   this.setState({
                        //       dataSource: data
                        //   })
                        //   self(data)
                    }


                }

            })
        this.getdata(obj)
    }

    handleSubmit1 = () => {
        var self = this;
        // console.log('handleSubmit ::', self)
        if (this.state.uploadresult1.length < 1) {
            this.setState({ fileError1: true })
            return;
        }
        this.setState({
            uploading: false,
            bulkUpdateErr: false
        })

        this.parseFile1(self, this.state.uploadresult1)
    }

    getdata1 = (data) => {
        var element = data.data
        // var sortarr = element.splice(-1,1)
        // console.log("data in get1::", data.data)

        this.setState({
            dataSource1: data.data,
            saveData: true,
            // showtable: true
        },()=>{this.saveCustomerData1()})
    }
    parseFile1 = (self, data) => {
        // console.log('In parseFile ::',data)

        var obj = PapaParse.parse(data,
            {
                delimiter: "",	// auto-detect
                newline: "",	// auto-detect
                quoteChar: '"',
                escapeChar: '"',
                header: true,
                transformHeader: undefined,
                dynamicTyping: false,
                preview: 0,
                encoding: "",
                worker: false,
                comments: false,
                step: undefined,
                complete: undefined,
                error: undefined,
                download: false,
                downloadRequestHeaders: undefined,
                downloadRequestBody: undefined,
                skipEmptyLines: false,
                chunk: undefined,
                fastMode: undefined,
                beforeFirstChunk: undefined,
                withCredentials: undefined,
                transform: undefined,
                // delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
                complete: function ({ data, err, meta }) {
                    if (err) {
                        // console.log("Parsing err:", err);
                    } else {
                        // console.log("Parsing complete:", data, self);
                        //   this.setState({
                        //       dataSource: data
                        //   })
                        //   self(data)
                    }


                }

            })
        this.getdata1(obj)
    }

    handleDelete = (val) => {
        //    console.log("arrrr1",val)
            let arr = this.state.dataSource;
             let i = arr.findIndex(x => x.Mobile == val.Mobile)
                arr.splice(i,1)
                this.setState({
                dataSource:arr
                })
                message.success("Deleted Successfully")
              }

    handleFiles = files => {
        // console.log('In handleFiles ::',files)
        var self = this;
        var name = files[0].name;
        var ext = name.split('.').pop();
        {
            this.setState({
                fileExtensionError: false
            }, () => {
                var reader = new FileReader();
                reader.onload = function (e) {
                    self.setState({
                        uploadresult: reader.result,
                        uploadedFileName: name,
                        fileError: false
                    })
                }
                reader.readAsText(files[0]);
            })
        }
    }

    handleFiles1 = files => {
        // console.log('In handleFiles ::',files)
        var self = this;
        var name = files[0].name;
        var ext = name.split('.').pop();
        {
            this.setState({
                fileExtensionError1: false
            }, () => {
                var reader = new FileReader();
                reader.onload = function (e) {
                    self.setState({
                        uploadresult1: reader.result,
                        uploadedFileName1: name,
                        fileError1: false
                    })
                }
                reader.readAsText(files[0]);
            })
        }
    }

    onChangeHandle = (selectedRowKeys, selectedRows) => {
        if (selectedRows.lenght < 0) {
            this.setState({
                select: true
            })
        } else {
            this.setState({
                select: true
            })
        }
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows,
            select: true
        })

        this.props.handleCustomerData(selectedRowKeys, selectedRows)

    }

    listnameFun = (e) => {
        console.log(e.target.value)
        this.setState({
            listname: e.target.value,
            listname1: false
        })
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters
        }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search here`}
              value={selectedKeys[0]}
              onChange={e =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
          if (dataIndex == 'upn') {
            return record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          } else {
            let a = dataIndex.split('.')[1].toString();
            return record.masterVariationDump[a]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        },
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        )
      });
    
      handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0], onSearch: true,search:true });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({
          searchText: '',
          nextToken: '',
          productList: [],
          loading: false,
          hasMore: true,
          search:false
        });
      };
    


    render() {
        const { getFieldDecorator } = this.props.form;
        // console.log("UserList",this.state.userListData);

        const columns = [
            {
                title: 'Name',
                dataIndex: 'Name',
                key: 'Name',
                // ...this.getColumnSearchProps('Name'),
            },

            {
                title: 'Mobile',
                dataIndex: 'Mobile',
                key: 'mob',
                // ...this.getColumnSearchProps('mob'),
            },{
                title: 'Email',
                dataIndex: 'Email',
                key: 'Email',
                // ...this.getColumnSearchProps('Email'),
            },{
                title: 'Customer Since',
                dataIndex: 'customerSince',
                key: 'customerSince',
            },
            {
                title: 'Whats App Number',
                dataIndex: 'whatsAppNumber',
                key: 'whatsAppNumber',
                // ...this.getColumnSearchProps('whatsAppNumber'),
            },{
                title: 'City',
                dataIndex: 'City',
                key: 'city',
                // ...this.getColumnSearchProps('city'),
            },{
                title: 'Country',
                dataIndex: 'Country',
                key: 'Country',
                // ...this.getColumnSearchProps('Country'),
            },{
                title: 'Language',
                dataIndex: 'Language',
                key: 'Language',
                // ...this.getColumnSearchProps('Language'),
            },{
                title: 'Product Type',
                dataIndex: 'ProductType',
                key: 'ProductType',
                // ...this.getColumnSearchProps('ProductType'),
            },
            {
                title: 'Url',
                dataIndex: 'Url',
                key: 'ProductType',
                // ...this.getColumnSearchProps('ProductType'),
            },
            {
                title: 'Business Name',
                dataIndex: 'BusinessName',
                key: 'BusinessName',
                // ...this.getColumnSearchProps('ProductType'),
            },
            {
                title: 'Status',
                dataIndex: 'Status',
                key: 'Status',
                // ...this.getColumnSearchProps('ProductType'),
            },
            {
                title: 'Action',
                key: 'action',
                width: 150,
                render: (data) => {
                    return (
                        <div>
                            <span>
                            </span>
                            {/* <span>
                                <Link to={{
                                    pathname: '/setting',
                                    state: {
                                        data: data
                                    }
                                }}>

                                    <Button icon="edit" type="primary" style={{ marginLeft: "5px", ursor: "pointer", }} />
                                </Link>
                            </span> */}
                            <span>
                                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(data)}>
                                    <Button icon="delete" type="danger" style={{ marginLeft: "5px", ursor: "pointer", }} />
                                </Popconfirm>
                            </span>
                        </div>
                    )
                }

            }
        ];

        return (
            <Wrapper header={"Bulk Operation"}>
                <div>
                    <h4 style={{ fontWeight: "bold",fontSize:"22px" }}>ADD NEW USER </h4>
                <div style={{ display: 'flex', height:'48%',marginTop:"4%" }}>
                
                        <div style={{ display: 'flex', width:'90%', justifyContent: 'space-between' }}>
                            <div style={{marginLeft:"10%"}}>
                            <Card style={{ minHeight: '160px', textAlign: 'left', background: '#f7fcfa' }}>
                                <span style={{ float: 'left' }}>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            marginRight: '1em',
                                            marginBottom: '1em',
                                            width: '220px',
                                            padding: '5px',
                                            borderRadius: '12px',
                                            backgroundColor: '#1890ff',
                                            color: '#fff',
                                            textAlign: 'center',
                                            // borderRadius: '25px',
                                        }}
                                    >
                                        Download Blank Template to Add New Users
                                    </span>
                                    <Icon type="cloud-download"
                                        onClick={this.downloadTemplate}
                                        style={{ fontSize: '30px', color: '#08c', marginTop: '15px' }} theme="outlined"
                                    />
                                </span>
                                {this.state.download1 && <a id="down1" download='products-batch-file.csv' href={`data: 'text/csv',${encodeURI(this.state.emptyCSV)}`}></a>}
                            </Card>
                            </div>

                            <div>
                            <Card style={{ minHeight: '160px', textAlign: 'center', background: '#f7fcfa' }}>
                                <h4>Upload Contact File </h4>
                                {/* <h4 style={{ color: 'red' }}>Please upload .csv files only</h4> */}
                                <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'}>

                                    {this.state.uploadedFileName == '' &&
                                        <Button type="primary" style={{ width: '200px' }} >Select File</Button>

                                    }
                                    {this.state.uploadedFileName &&
                                        <h4 style={{ color: 'green' }}>Uploaded file: {this.state.uploadedFileName}</h4>
                                    }

                                    {this.state.fileExtensionError &&
                                        <h4 style={{ color: 'red' }}>Please upload .csv files only</h4>
                                    }

                                </ReactFileReader>
                            </Card>
                            
                            {this.state.uploadedFileName != '' &&

                                <Button type="primary"
                                    onClick={this.handleSubmit}
                                    style={{ marginTop: '1em', width: '200px' }}
                                    // disabled={this.state.fileExtensionError}
                                    loading={this.state.uploading}
                                >
                                    <Icon type="cloud-upload" style={{ fontSize: '20px', color: '#fff', fontWeight: 700 }} theme="outlined" />
                                    Upload File
                </Button>
                            }


                            {this.state.csvFileError &&
                                <h4 style={{ color: 'red' }}>There is an error submitting your csv file, please check your file again</h4>
                            }
                            {this.state.fileError &&
                                <h4 style={{ color: 'red' }}>Please upload .csv file</h4>
                            }
                            </div>

                            {/* <div>
                            <Card style={{ minHeight: '160px', textAlign: 'right', background: '#f7fcfa' }}>
                                <span style={{ float: 'left' }}>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            marginRight: '1em',
                                            marginBottom: '1em',
                                            width: '190px',
                                            padding: '5px',
                                            borderRadius: '12px',
                                            backgroundColor: '#1890ff',
                                            color: '#fff',
                                            textAlign: 'center',
                                            // borderRadius: '25px',
                                        }}
                                    >
                                        Download Exhisting UserList to Update
                                    </span>
                                    <Icon type="cloud-download"
                                        onClick={this.downloadTemplate1}
                                        style={{ fontSize: '30px', color: '#08c', marginTop: '15px' }} theme="outlined"
                                    />
                                </span>
                                {this.state.download1 && <a id="down1" download='products-batch-file.csv' href={`data: 'text/csv',${encodeURI(this.state.emptyCSV)}`}></a>}
                            </Card>
                            </div> */}

                            </div>
                        {/* {this.state.saveData && <div>
                            
                                <Button loading={this.state.longLoading} onClick={this.saveCustomerData} type="default" style={{marginTop:'40px', background:"#389e0d", color:"#fff",marginLeft:'92%' }} >
                                    save
                                </Button>
                        </div>} */}
                </div>

                <Divider style={{marginTop:"2%",marginBottom:"2%"}}/>

                <h4 style={{ fontWeight: "bold",fontSize:"22px" }}>UPDATE EXISTING USER </h4>
                <div style={{ display: 'flex', height:'50%', justifyContent: 'space-between',marginTop:"5%" }}>
                <div style={{marginLeft:"10%"}}>
                            <Card style={{ minHeight: '160px', textAlign: 'right', background: '#f7fcfa' }}>
                                <span style={{ float: 'left' }}>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            marginRight: '1em',
                                            marginBottom: '1em',
                                            width: '220px',
                                            padding: '5px',
                                            borderRadius: '12px',
                                            backgroundColor: '#1890ff',
                                            color: '#fff',
                                            textAlign: 'center',
                                            // borderRadius: '25px',
                                        }}
                                    >
                                        Download Exhisting UserList to Update
                                    </span>
                                    <Icon type="cloud-download"
                                        onClick={this.downloadTemplate1}
                                        style={{ fontSize: '30px', color: '#08c', marginTop: '15px' }} theme="outlined"
                                    />
                                </span>
                                {this.state.download1 && <a id="down1" download='products-batch-file.csv' href={`data: 'text/csv',${encodeURI(this.state.emptyCSV)}`}></a>}
                             </Card> 
                            </div>

                            <div style={{marginRight:"10%"}}>
                            <Card style={{ minHeight: '160px', textAlign: 'center', background: '#f7fcfa' }}>
                                <h4>Upload Updated File </h4>
                                {/* <h4 style={{ color: 'red' }}>Please upload .csv files only</h4> */}
                                <ReactFileReader handleFiles={this.handleFiles1} fileTypes={'.csv'}>

                                    {this.state.uploadedFileName1 == '' &&
                                        <Button type="primary" style={{ width: '200px' }} >Select File</Button>

                                    }
                                    {this.state.uploadedFileName1 &&
                                        <h4 style={{ color: 'green' }}>Uploaded file: {this.state.uploadedFileName1}</h4>
                                    }

                                    {this.state.fileExtensionError1 &&
                                        <h4 style={{ color: 'red' }}>Please upload .csv files only</h4>
                                    }

                                </ReactFileReader>
                            </Card>
                            
                            {this.state.uploadedFileName1 != '' &&

                                <Button type="primary"
                                    onClick={this.handleSubmit1}
                                    style={{ marginTop: '1em', width: '200px' }}
                                    // disabled={this.state.fileExtensionError}
                                    loading={this.state.uploading}
                                >
                                    <Icon type="cloud-upload" style={{ fontSize: '20px', color: '#fff', fontWeight: 700 }} theme="outlined" />
                                    Upload File
                </Button>
                            }

                            {this.state.bulkUpdateErr &&
                                <h4 style={{ color: 'red',marginTop:"1%" }}>There is an error in your csv file, please download the Exhisting User file & update again</h4>
                            }

                            {this.state.csvFileError &&
                                <h4 style={{ color: 'red' }}>There is an error submitting your csv file, please check your file again</h4>
                            }
                            {this.state.fileError &&
                                <h4 style={{ color: 'red' }}>Please upload .csv file</h4>
                            }
                            </div>

                            </div>
                            {/* {this.state.saveData && <div>
                           
                                <Button loading={this.state.longLoading} onClick={this.saveCustomerData} type="default" style={{marginTop:'40px', background:"#389e0d", color:"#fff",marginLeft:'92%' }} >
                                    save
                                </Button>
                        </div>} */}
                    {/* <Row style={{ marginTop: '10px' }}>
                        {!this.state.loading ?
                            <>
                                {this.state.showtable &&
                                    <Table
                                        dataSource={this.state.dataSource}
                                        columns={columns}
                                    // pagination={false}
                                    // bordered={true}
                                    />
                                }</>
                            : <Spin style={{ height: 'max', width: '800px' }} />
                        }
                    </Row> */}


                </div>
            </Wrapper>
        )
    }
}
const WrappedTimeRelatedForm = Form.create({ name: 'time_related_controls' })(fileFunction);

export default withApollo(WrappedTimeRelatedForm)

