import React from 'react';
import { Button, Col, Icon, Layout, Menu, Tooltip, Row } from 'antd';
import { Auth } from 'aws-amplify';
import { Link, withRouter } from 'react-router-dom';
import './App.css';
//remove import css if using webpack
import 'antd/dist/antd.css';
import 'antd-mobile/dist/antd-mobile.css';

// custom css less files should come after default css import files
import './assets/less/main.less'
import './assets/css/main.css'

const { Header, Sider, Footer, Content } = Layout;
const { SubMenu } = Menu;

class Wrapper extends React.Component {
  rootSubmenuKeys = ['sub1', 'sub2', 'sub3'];
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      activemenuKey: '',
      defaultOpen: '',
      openKeys: []

    };
  }

  static showMenuSelected(url) {
    const pathArr = url.split('/').filter(pathVal => pathVal != '');
    const pathName = pathArr[0];
    let activeKey = '0';
    let defaultOpen = '';

    switch (pathName) {
      case undefined:
        activeKey = '1';
        defaultOpen = 'sub1';
        break;

      case 'customer':
        activeKey = '3';
        defaultOpen = 'sub1';
        break;
      case 'buyerlist':
        activeKey = '1';
        break;

        case 'customerwhatsapp':
        activeKey = '8';
        defaultOpen = 'sub3';
        break;
      case 'customeremail':
        activeKey = '9';
        defaultOpen = 'sub3';
        break;
        case 'whatsappTempList':
          activeKey = '10';
          defaultOpen = 'sub2';
          break;
        case 'CreateEmailTemp':
          activeKey = '11';
          defaultOpen = 'sub2';
          break;
  

      case 'create_customer':
        activeKey = '3';
        break;

      case 'edit_customer':
        activeKey = '3';
        defaultOpen = 'sub1';
        break;

      case 'file':
        activeKey = '4';
        defaultOpen = 'sub1';
        break;

      case 'setting':
        activeKey = '2';
        break;


      default:
        activeKey = '1';
    }
    return {
      activeKey,
      defaultOpen
    };
  }
  static getDerivedStateFromProps(nextProps, nextState) {
    const getActiveMenuId = Wrapper.showMenuSelected(nextProps.match.url);
    return {
      activemenuKey: getActiveMenuId.activeKey,
      defaultOpen: getActiveMenuId.defaultOpen
    };

  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleClick = (e) => {
    // console.log('click ', e);
    // console.log('key', e.keyPath[1])

  }
  handleSignOut = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("siteId");
    // localStorage.removeItem("terms");
    Auth.signOut()
      .then(() => {
        window.location = window.location.origin;
        // window.location.reload(true)
      })
      .catch(err => console.log(err));
  };
  onCollapse = collapsed => {
    this.setState({ collapsed });
  };
  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  render() {
    // console.log(this.props.children)
    const { children } = this.props;
    const { activemenuKey } = this.state;
    return (

      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          // onBreakpoint={(broken) => { console.log(broken); }}
          // onCollapse={(collapsed, type) => { console.log(collapsed, type); }}
        >
          <div
            className="logo"
            style={{
              height: '37px',
              margin: '16px'
            }}
          >
            <img
              src={require("./assets/images/recaho2.png")}
              style={{ width: '100%', height: '40px' }}
            />
          </div>
          <Menu
           theme="dark"
            mode="inline"
             selectedKeys={[activemenuKey]}
             defaultOpenKeys={[this.state.defaultOpen]}
             >
            <SubMenu
              key="sub1"
              title={
                <span>
                  <Icon type="code-sandbox" />
                {/* <Link to="/"> */}
                  <span>Manage Customer</span>
                  {/* </Link> */}
                </span>
              }
            >
              <Menu.Item key="4">
                <Link to="/file">
                  <Icon type="code-sandbox" />
                  <span className="nav-text">Bulk Operation</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="1">
                <Link to="/">
                  <Icon type="user" />
                  <span className="nav-text">User list</span>
                </Link>
              </Menu.Item>
            </SubMenu>


            <SubMenu
              key="sub2"
              title={
                <span>
                  <Icon type="code-sandbox" />
                {/* <Link to="/CreateWhatsappTemp"> */}
                  <span>Templates</span>
                  {/* </Link> */}
                </span>
              }
            >
              <Menu.Item key="10">
                <Link to="/whatsappTempList">
                  <Icon type="code-sandbox" />
                  <span className="nav-text">Whats App</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="11">
                <Link to="/CreateEmailTemp">
                  <Icon type="code-sandbox" />
                  <span className="nav-text">Email</span>
                </Link>
              </Menu.Item>

            </SubMenu>
            <SubMenu
              key="sub3"
              title={
                <span>
                  <Icon type="code-sandbox" />
                {/* <Link to="/customerwhatsapp"> */}
                  <span>Campaign </span>
                  {/* </Link> */}
                </span>
              }
            >
              <Menu.Item key="8">
                <Link to="/customerwhatsapp">
                  <Icon type="code-sandbox" />
                  <span className="nav-text">Whats App</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="9">
                <Link to="/customeremail">
                  <Icon type="code-sandbox" />
                  <span className="nav-text">Email</span>
                </Link>
              </Menu.Item>

            </SubMenu>

            {/* <Menu.Item key="1">
              <Link to="/">
                <Icon type="code-sandbox" />
                <span className="nav-text">Manage Campaign</span>
              </Link>
            </Menu.Item> */}
            {/* <Menu.Item key="4">
                      <Link to="/file">
                        <Icon type="code-sandbox" />
                        <span className="nav-text">Bulk Opration</span>
                        </Link>
                      </Menu.Item> */}
            <Menu.Item key="2">
              <Link to="/setting">
                <Icon type="setting" />
                <span className="nav-text">Settings</span>
              </Link>
            </Menu.Item>


          </Menu>
        </Sider>
        <Layout style={{ height: "100vh" }}>
          <Header style={{ background: '#fff', padding: 0 }} >
            <Row>
              <Col span={20}>

                <h2 style={{ marginLeft: '20px' }}>{this.props.header}</h2>
              </Col>
              <Col>
                <div style={{ cursor: "pointer", padding: "10px", float: "right" }} onClick={this.handleSignOut}>
                  <Tooltip placement="left" title="Logout">
                    <Icon type="logout" style={{ fontSize: '30px', color: '#08c' }} />
                  </Tooltip>
                </div>
              </Col>
            </Row>
          </Header>
          <Content style={{
            margin: '10px 10px 0', padding: 10,
            background: '#fff',
            overflow: 'auto'
          }}>
            <div style={{ padding: 10, background: '#fff', minHeight: 560 }}>
              {this.props.children}
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}
export default withRouter(Wrapper);
